import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Select from '@mui/material/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ProfitabilityDetailsTable from 'components/admin/ProfitabilityDetailsTable'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
//import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'
import componentStylesForms from 'assets/theme/components/forms.js'
import componentStylesButtons from 'assets/theme/components/button.js'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

//const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)
const useStylesForms = makeStyles(componentStylesForms)
const useStylesButtons = makeStyles(componentStylesButtons)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function WeekModal(props) {
	const classes = { ...useStylesDialog(), ...useStylesForms(), ...useStylesButtons() }
  	//const theme = useTheme()
  	const api = new AdminAPI()

    let friday = moment().isoWeekday(5).format('YYYY-MM-DD')

    const [country, setCountry] = useState('IE')
    const [date, setDate] = useState(friday)
    const [details, setDetails] = useState(null)
    const [leads, setLeads] = useState(0)
    const [revenue, setRevenue] = useState(0)
    const [cost, setCost] = useState(0)
    const [notes, setNotes] = useState('')
    const [alert, setAlert] = useState(false)
    
    async function addWeek() {
        console.log('add week...')
        if (details) {
            try {
                let formattedDate = moment(date).format('YYYY-MM-DD')
                console.log('formatted date: ', formattedDate)
                
                let week = await api.addProfitabilityWeek(country, formattedDate, details, leads, revenue, cost, notes)
                console.log('week: ', week)

                props.refreshWeeks()
                props.showSuccessAlert('Week added!')
                props.close()
            } catch(e) {
                onError(e)
            }
        }
        else {
            NotificationManager.error('Please complete details for this week')
        }
    }

    function updateDetails(details) {
        console.log('update details: ', details)
        let leads = 0
        let cost = 0
        let revenue = 0
        for (const [product, values] of Object.entries(details)) {
            leads += values.volume
            cost += (values.facebook + values.google + values.tiktok + values.reddit + values.bing)
            revenue += values.revenue
        }
        cost = parseFloat(cost.toFixed(2))
        revenue = parseFloat(revenue.toFixed(2))
        setDetails(details)
        setLeads(leads)
        setCost(cost)
        setRevenue(revenue)
    }

	return (
        <Dialog
            open={props.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => props.close()}
            maxWidth='lg'
        >
            {alert}
            <div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Add Week
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
            <DialogContent>
                <FormGroup>
                    <FormLabel>Country</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value={'IE'}>IE</MenuItem>
                            <MenuItem value={'US'}>US</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
				<FormGroup>
                    <FormLabel>Date (Friday of week in question)</FormLabel>
                    <OutlinedInput
                        fullWidth
                        type='date'
                        value={date}
                        defaultValue={date}
                        onChange={(e) => {
                            console.log(e.target.value)
                            setDate(e.target.value)
                        }}
                        //disabled
                    />
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Details</FormLabel>
                    <ProfitabilityDetailsTable 
                        details={details} 
                        updateDetails={(details) => updateDetails(details)}
                    />
                </FormGroup>
                {/*
                <FormGroup>
                  	<FormLabel>Expenditure (€)</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={expenditure}
                    	//onChange={(e) => setSubtotal(e.target.value)}
                        disabled
                  	/>
                </FormGroup>      
                <FormGroup>
                  	<FormLabel>Income (€)</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={income}
                    	//onChange={(e) => setIncome(e.target.value)}
                        disabled
                  	/>
                </FormGroup>
                */}
                <FormGroup>
                  	<FormLabel>Notes</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={notes}
                    	onChange={(e) => setNotes(e.target.value)}
                        multiline
                        minRows={2}
                  	/>
                </FormGroup>
			</DialogContent>
            <DialogActions>
                <Box width='100%' display='flex' justifyContent='space-between'>
                    <Button onClick={() => props.close()} color='primary'>
                        Close
                    </Button>
                    <Box>
                        <Box display='inline-block' marginRight='0.5rem'>
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={() => addWeek()}
                            >
                                Add
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
	)
}