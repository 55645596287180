import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
import ReturnRequestsTable from 'components/admin/ReturnRequestsTable'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function Replacements() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const api = new AdminAPI()

    const [requests, setRequests] = useState([])
    const [showRequests, setShowRequests] = useState([])
    const [companyOptions, setCompanyOptions] = useState({})
    const [company, setCompany] = useState('all')
    const [requestStatus, setRequestStatus] = useState('pending')
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        getReturnRequests()
    }, [])

    async function getReturnRequests() {
        try {
            const requests = await api.getReturnRequests()
            console.log('requests: ', requests)

            let companyOptions = {}

            requests.sort((a, b) => {
                if (Object.keys(companyOptions).indexOf(a.companyId) < 0) {
                    companyOptions[a.companyId] = a.companyName
                }
                return b.createdAt - a.createdAt
            })
            console.log('company options: ', companyOptions)

            setRequests(requests)
            setShowRequests(requests.filter((request) => request.status === 'pending'))
            setCompanyOptions(companyOptions)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    // function updateRequest(request) {
    //     const index = requests.findIndex(oldRequest => (oldRequest.phone === request.phone))
    //     console.log('request index: ', index)

    //     let newRequests = [...requests]
    //     newRequests[index] = request
    //     console.log(newRequests)
    //     setRequests(newRequests.filter((request) => request.status === 'pending'))
    // }

    // function deleteRequest(request) {
    //     let newRequests = requests.filter(item => (request.phone !== item.phone))
    //     console.log(newRequests)
    //     setRequests(newRequests)
    // }

    function updateCompany(company) {
        console.log(company)
        setCompany(company)
        let newRequests = []
        if (company !== 'all') {
            newRequests = requests.filter((request) => request.companyId === company && request.status === requestStatus)
        }
        else {
            newRequests = requests.filter((request) => request.status === requestStatus)
        }
        setShowRequests(newRequests)
    }

    function updateStatus(requestStatus) {
        console.log(requestStatus)
        setRequestStatus(requestStatus)
        let newRequests = []
        if (company !== 'all') {
            newRequests = requests.filter((request) => request.status === requestStatus && request.companyId === company)
        }
        else {
            newRequests = requests.filter((request) => request.status === requestStatus)
        }
        setShowRequests(newRequests)
    }

    async function acceptRequestMultiple(requests) {
        console.log('accept requests multiple')
        console.log(requests)
        try {
            let updated = await api.acceptReturnRequestMultiple(requests)
            console.log('requests: ', updated)

            NotificationManager.success('Requests accepted')
            getReturnRequests()
        } catch(e) {
            onError(e)
        }
    }

    function showSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='-6rem'
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid
                                container
                                component={Box}
                                alignItems='center'
                                justifyContent='flex-start'
                                style={{marginBottom: '2rem'}}
                                direction='row'
                            >
                                <Grid item xs={12} md={2}>
                                    <FormGroup style={{marginBottom: '1rem'}}>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                //IconComponent={KeyboardArrowDown}
                                                value={company}
                                                onChange={(e) => updateCompany(e.target.value)}
                                            >
                                                <MenuItem value={'all'}>All</MenuItem>
                                                {Object.keys(companyOptions).map((option) => {
                                                    return (<MenuItem value={option}>{companyOptions[option]}</MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <FormGroup style={{marginBottom: '1rem'}}>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                //IconComponent={KeyboardArrowDown}
                                                value={requestStatus}
                                                onChange={(e) => updateStatus(e.target.value)}
                                            >
                                                <MenuItem value={'pending'}>Pending</MenuItem>
                                                <MenuItem value={'accepted'}>Accepted</MenuItem>
                                                <MenuItem value={'rejected'}>Rejected</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <ReturnRequestsTable
                                requests={showRequests}
                                updateRequest={() => getReturnRequests()}
                                deleteRequest={() => getReturnRequests()}
                                acceptRequestMultiple={(requests) => acceptRequestMultiple(requests)}
                                showSuccessAlert={(text) => showSuccessAlert(text)}
                                requestStatus={requestStatus}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default Replacements