import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
//import Checkbox from '@material-ui/core/Checkbox'
import Container from '@material-ui/core/Container'
import FilledInput from '@material-ui/core/FilledInput'
import FormControl from '@material-ui/core/FormControl'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Typography from '@material-ui/core/Typography'
//import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import GuestAPI from 'config/api/GuestAPI'
import { onError } from 'config/lib/errorLib'
//import { useHistory } from 'react-router-dom'
import Loader from 'components/general/Loader'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import config from 'config/api/Config'
import PaymentForm from 'components/general/PaymentForm'

import logo from 'resources/images/logo-blue-500.png'

// core components
import AuthHeader from 'components/argon/Headers/AuthHeader.js'
import componentStyles from 'assets/theme/views/auth/login.js'
import componentStylesButtons from 'assets/theme/components/button.js'

const useStyles = makeStyles(componentStyles)
const useStylesButtons = makeStyles(componentStylesButtons)

const stripePromise = loadStripe(config.stripeKey)

function Topup() {
    const classes = { ...useStyles(), ...useStylesButtons() }
    const theme = useTheme()
    //const history = useHistory()
    const api = new GuestAPI

    const [companyId, setCompanyId] = useState('')
    const [total, setTotal] = useState('')
    const [stage, setStage] = useState('details')
    const [clientSecret, setClientSecret] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        checkUrlParams()
    }, [])

    async function checkUrlParams() {
        const code = new URLSearchParams(window.location.search).get('code')
        console.log('code: ', code)

        const total = new URLSearchParams(window.location.search).get('total')
        console.log('total: ', total)

        const paymentIntentId = new URLSearchParams(window.location.search).get('payment_intent')
        let paymentIntent = ''

        if (paymentIntentId) {
            try {
                paymentIntent = await api.getPaymentIntent(paymentIntentId)
                console.log('payment intent: ', paymentIntent)
                console.log('payment intent status: ', paymentIntent.status)
                if (paymentIntent.status === 'succeeded') {
                    setStage('succeeded')
                    let response = await api.createPayment(paymentIntent.metadata.companyId, paymentIntent.amount)
                    console.log('response: ', response)
                }
                else {
                    setStage('failed')
                }
            } catch(e) {
                console.log(e)
            }
        }

        if (code) {
            setCompanyId(code)
        }
        if (total) {
            setTotal(total)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

		if (stage === 'details') {
            if (companyId.length > 0 && total.length > 0)
			goToPayment()
		}
		else {
			confirmPayment()
		}
    }

    async function goToPayment() {
        setLoading(true)
        try {   
            const paymentIntent = await api.createPaymentIntent(companyId, total)
            console.log('payment intent: ', paymentIntent)

            setClientSecret(paymentIntent.client_secret)
            setStage('payment')
        } catch(e) {
            onError(e)
        }
        setLoading(false)
    }

    async function confirmPayment() {
        console.log('confirm payment')
    }
  
    return (
        <>  
            <Box 
                display='flex'
                justifyContent='center'
                alignItems='center'
                style={{paddingTop: '5rem'}}
            >
                <img className='login-logo' src={logo} alt='brokerdiary-logo' />
            </Box>
            
            {stage === 'details' &&
                <AuthHeader
                    title='Account Top Up'
                    description='Review your company and top up amount below'
                />
            }

            {stage === 'payment' &&
                <AuthHeader
                    title='Complete Payment'
                    description={'Enter your card details below'}
                />
            }

            {stage === 'succeeded' &&
                <AuthHeader
                    title='Payment Successful'
                    description={'Your account has been topped up'}
                />
            }

            {stage === 'failed' &&
                <AuthHeader
                    title='Payment Failed'
                    description={'Your account has not been topped up, please try again'}
                />
            }
          
            <Container
                component={Box}
                maxWidth='xl'
                marginTop='-12rem'
                paddingBottom='3rem'
                position='relative'
                zIndex='101'
            >
                {stage === 'details' &&
                    <form onSubmit={handleSubmit}>
                        <Box component={Grid} container justifyContent='center'>
                            <Grid item xs={12} lg={5} md={7}>
                                <Card classes={{ root: classes.cardRoot }} style={{backgroundColor: '#fff'}}>
                                    <CardContent classes={{ root: classes.cardContent }}>
                                        <Box
                                            color={theme.palette.gray[600]}
                                            textAlign='center'
                                            marginBottom='1rem'
                                            marginTop='.5rem'
                                            fontSize='1rem'
                                        >
                                            <Box fontSize='80%' fontWeight='400' component='small'>
                                                Enter your company code and top up amount below
                                            </Box>
                                        </Box>
                                
                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                            marginBottom='1rem!important'
                                        >
                                            <FilledInput
                                                autoComplete='off'
                                                type='text'
                                                placeholder='Company Code'
                                                value={companyId.toUpperCase()}
                                                onChange={(e) => setCompanyId(e.target.value)}
                                                disabled
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                            marginBottom='1rem!important'
                                        >
                                            <FilledInput
                                                autoComplete='off'
                                                type='text'
                                                placeholder='Amount'
                                                value={'€' + parseFloat(total).toFixed(2)}
                                                onChange={(e) => setTotal(e.target.value)}
                                                disabled
                                            />
                                        </FormControl>
                                        <Box
                                            textAlign='center'
                                            marginTop='1.5rem'
                                            marginBottom='1.5rem'
                                        >
                                            <Button
                                                variant='contained'
                                                classes={{ root: classes.buttonContainedInfo }}
                                                //onClick={() => signIn()}
                                                type='submit'
                                                disabled={loading}
                                            >
                                                {!loading ? (
                                                    <>Continue</>
                                                ) : (
                                                    <Loader height={12} />
                                                )}
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Box>
                    </form>
                }
                {stage === 'payment' &&
                    <Box component={Grid} container justifyContent='center'>
                        <Grid item xs={12} lg={6} md={8}>
                            <Card classes={{ root: classes.cardRoot }} style={{backgroundColor: '#fff'}}>
                                <CardContent classes={{ root: classes.cardContent }}>
                                    <Elements stripe={stripePromise} options={{clientSecret}}>
                                        <PaymentForm
                                            companyId={companyId}
                                            clientSecret={clientSecret}
                                        />
                                    </Elements>
                                    
                                    {/*
                                    <Box
                                        textAlign='center'
                                        marginTop='1.5rem'
                                        marginBottom='1.5rem'
                                    >
                                        <Button
                                            variant='contained'
                                            classes={{ root: classes.buttonContainedInfo }}
                                            //onClick={() => confirmAccount()}
                                            type='submit'
                                            disabled={loading}
                                        >
                                            {!loading ? (
                                                <>Pay Now</>
                                            ) : (
                                                <Loader height={12} />
                                            )}
                                        </Button>
                                    </Box>
                                    */}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Box>
                }
                {stage === 'succeeded' &&
                    <Box component={Grid} container justifyContent='center'>
                        <Grid item xs={12} lg={6} md={8}>
                            <Card classes={{ root: classes.cardRoot }} style={{backgroundColor: '#fff'}}>
                                <CardContent classes={{ root: classes.cardContent }}>
                                    <Box textAlign='center'>
                                        <CheckCircle className='register-icon' />
                                    </Box>

                                    <Typography
                                        component='h2'
                                        variant='h2'
                                        className={classes.typographyH2}
                                        style={{textAlign: 'center', marginTop: '1.5rem'}}
                                    >
                                        Payment Completed
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Box>
                }
                {stage === 'failed' &&
                    <Box component={Grid} container justifyContent='center'>
                        <Grid item xs={12} lg={6} md={8}>
                            <Card classes={{ root: classes.cardRoot }} style={{backgroundColor: '#fff'}}>
                                <CardContent classes={{ root: classes.cardContent }}>
                                    <Box
                                        textAlign='center'
                                        marginTop='1.5rem'
                                        marginBottom='1.5rem'
                                    >
                                        <Button
                                            variant='contained'
                                            classes={{ root: classes.buttonContainedInfo }}
                                            onClick={() => window.location.replace('/topup')}
                                            disabled={loading}
                                        >
                                            Try again
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Box>
                }
            </Container>
        </>
    )
}

export default Topup