import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import Button from '@material-ui/core/Button'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'

function Notes(props) {
    const api = new AdminAPI()

    const [note, setNote] = useState(props.lead.notes.length > 0 ? props.lead.notes[0].note : '')
    const [showContent, setShowContent] = useState((props.site === 'sf' || props.site === 'ee') ? true : false)

    async function updateNote() {
        try {
            let result = await api.updateLeadNote(props.lead.date, props.lead.leadId, note)

            props.updateLead(result)
            NotificationManager.success('Notes updated')
            setShowContent(false)
        } catch(e) {
            onError(e)
        }
    }
    
    return (
        <Card>
            <CardHeader
                subheader={
                    <Grid
                        container
                        component={Box}
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Grid item xs='auto'>
                            <Box
                                component={Typography}
                                variant='h3'
                                marginBottom='0!important'
                            >
                                Notes
                            </Box>
                        </Grid>
                        <Grid item xs='auto'>
                            <Box>
                                {showContent ? (
                                    <KeyboardArrowUp />
                                ) : (
                                    <KeyboardArrowDown />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                }
                onClick={() => setShowContent(!showContent)} 
                style={{cursor: 'pointer'}}
            >
            </CardHeader>
            
            {showContent &&
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} lg={12}>
                            <FormGroup>
                                <FormLabel>Note</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        multiline
                                        minRows={5}
                                        value={note}
                                        onChange={(e) => setNote(e.target.value)}
                                        disabled={props.site === 'sf' || props.site === 'ee'}
                                    />
                                </FormControl>
                            </FormGroup>

                        </Grid>

                        <Grid item xs={12} lg={12}>        
                            <Grid container justifyContent='space-between'>
                                <Grid item>
                                    {/*
                                    <Button
                                        onClick={() => setShowQuotesModal(true)}
                                        color='primary'
                                        disabled
                                    >
                                        Generate Quotes
                                    </Button>
                                    */}
                                </Grid>
                                {(props.site !== 'sf' && props.site !== 'ee') &&
                                    <Grid item>
                                        <Button
                                            component={Box}
                                            color='primary'
                                            variant='contained'
                                            size='medium'
                                            //disabled={checkRequiredFields()}
                                            onClick={() => updateNote()}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            }
        </Card>
    )
}

export default Notes