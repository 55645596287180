import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import CampaignsTable from 'components/admin/CampaignsTable'
import DeliveriesTable from 'components/admin/DeliveriesTable'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function Campaigns() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const api = new AdminAPI()

    const [showStatus, setShowStatus] = useState('active')
    const [campaigns, setCampaigns] = useState([])
    const [deliveries, setDeliveries] = useState([])
    const [showDeliveries, setShowDeliveries] = useState([])
    const [companies, setCompanies] = useState([])
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        getCampaigns()
    }, [])

    async function getCampaigns() {
        try {
            let campaigns = await api.getCampaigns()
            console.log('campaigns: ', campaigns)
            campaigns.sort((a, b) => {
                return b.target - a.target
            })
            let activeCampaigns = campaigns.filter((campaign) => campaign.active)

            const deliveries = await api.getDeliveries()
            console.log('deliveries: ', deliveries)
            const activeDeliveries = deliveries.filter((delivery) => (delivery.active))

            const companies = await api.getCompanies()
            console.log('companies: ', companies)
            const activeCompanies = companies.filter((company) => (company.active))
            activeCompanies.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })

            let campaignTotals = {
                'Life Insurance': {
                    total: 0,
                    leads: 0
                },
                'Income Protection': {
                    total: 0,
                    leads: 0
                },
                'Pensions': {
                    total: 0,
                    leads: 0
                },
                'Mortgage Protection': {
                    total: 0,
                    leads: 0
                },
                'Health Insurance': {
                    total: 0,
                    leads: 0
                },
            }
            for (let delivery of deliveries) {
                if (delivery.active && delivery.companyId !== 'qfa') {
                    campaignTotals[delivery.campaignName].total += (delivery.leadPrice * delivery.allocation)
                    campaignTotals[delivery.campaignName].leads += delivery.allocation
                }
            }
            console.log(campaignTotals)
            activeCampaigns = activeCampaigns.map(campaign => ({...campaign, currentTotal: campaignTotals[campaign.name]}))
            console.log(activeCampaigns)

            setCampaigns(activeCampaigns)
            setDeliveries(deliveries)
            setShowDeliveries(activeDeliveries)
            setCompanies(activeCompanies)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function updateCampaign(campaign) {
        const index = campaigns.findIndex(oldCampaign => (oldCampaign.campaignId === campaign.campaignId))
        console.log('campaign index: ', index)

        let newCampaigns = [...campaigns]
        newCampaigns[index] = campaign
        console.log(newCampaigns)
        let campaignTotals = {
            'Life Insurance': 0,
            'Income Protection': 0,
            'Pensions': 0,
            'Mortgage Protection': 0,
            'Health Insurance': 0
        }

        for (let delivery of deliveries) {
            if (delivery.active && delivery.companyId !== 'qfa') {
                campaignTotals[delivery.campaignName] += (delivery.leadPrice * delivery.allocation)
            }
        }
        newCampaigns = newCampaigns.map(campaign => ({...campaign, currentTotal: campaignTotals[campaign.name]}))
        console.log(newCampaigns)
        setCampaigns(newCampaigns)
    }

    function updateDelivery(delivery) {
        const index = deliveries.findIndex(oldDelivery => ((oldDelivery.companyId === delivery.companyId) && (oldDelivery.product === delivery.product)))
        console.log('delivery index: ', index)

        let newDeliveries = [...deliveries]
        newDeliveries[index] = delivery
        console.log(newDeliveries)
        let checkStatus = showStatus === 'active' ? true : false
        let showDeliveries = newDeliveries.filter((delivery) => (delivery.active === checkStatus))
        console.log(showDeliveries)
        setDeliveries(newDeliveries)
        setShowDeliveries(showDeliveries)
    }

    function updateShowStatus(showStatus) {
        let checkStatus = showStatus === 'active' ? true : false
        let showDeliveries = deliveries.filter((delivery) => (delivery.active === checkStatus))
        setShowDeliveries(showDeliveries)
        setShowStatus(showStatus)
    }

    function showSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='-6rem'
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid
                                container
                                component={Box}
                                alignItems='center'
                                justifyContent='flex-start'
                                style={{marginBottom: '2rem'}}
                                direction='row'
                            >
                                <Grid item xs={12} md={2}>
                                    <FormGroup style={{marginBottom: '0'}}>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                value={showStatus}
                                                onChange={(e) => updateShowStatus(e.target.value)}
                                            >
                                                <MenuItem value='active'>Active</MenuItem>
                                                <MenuItem value='inactive'>Inactive</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <DeliveriesTable 
                                deliveries={showDeliveries}
                                companies={companies}
                                campaigns={campaigns}
                                refreshDeliveries={() => getCampaigns()}
                                updateDelivery={(delivery) => updateDelivery(delivery)}
                                showSuccessAlert={(text) => showSuccessAlert(text)}
                                title={showStatus === 'active' ? 'Active Weekly Deliveries' : 'Inactive Weekly Deliveries'}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CampaignsTable
                                campaigns={campaigns}
                                deliveries={deliveries}
                                refreshCampaigns={() => getCampaigns()}
                                updateCampaign={(campaign) => updateCampaign(campaign)}
                                showSuccessAlert={(text) => showSuccessAlert(text)}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default Campaigns