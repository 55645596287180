import React from 'react';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close'
import PeopleIcon from '@material-ui/icons/People'
import CheckIcon from '@material-ui/icons/Check'

import CardStats from 'components/argon/Cards/Dashboard/CardStats.js'

const PensionTotals = ( props ) => {

    const totals = props.totals

    let revenue = 0

    for (let lead of props.leads) {
        let buyerIndex = props.allBuyers.findIndex((buyer) => buyer.companyId === lead.companyId)
        let returnsIndex = props.returns.findIndex((request) => (request.product === lead.product && request.phone === lead.phone))
        if (returnsIndex === -1) {
            if (buyerIndex > -1) {
                let buyer = props.allBuyers[buyerIndex]
                if (buyer.companyId !== 'bq') {
                    let price = buyer.pricing ? buyer.pricing[lead.quote.currentValue] : buyer.leadPrice
                    if (price === undefined) {
                        price = 0
                    }
                    //console.log(price)
                    revenue += price
                }
            }
        }
    }
    console.log('revenue: ', revenue)
    
    return (
        <Grid container direction='row' spacing={1} style={{marginBottom: '2rem'}}>
            <Grid item xs={12} md={4}>
                <CardStats
                    subtitle='Leads (All)'
                    title={totals.all}
                    icon={PeopleIcon}
                    color='bgPrimary'
                    // footer={
                    //     <>
                    //         <Box
                    //             component='span'
                    //             fontSize='.875rem'
                    //             color={theme.palette.success.main}
                    //             marginRight='.5rem'
                    //             display='flex'
                    //             alignItems='center'
                    //         >
                    //             <Box
                    //                 component={ArrowUpward}
                    //                 width='1.25rem!important'
                    //                 height='1.25rem!important'
                    //                 marginLeft='-.25rem'
                    //             />{' '}
                    //             10%
                    //         </Box>
                    //         <Box component='span' whiteSpace='nowrap'>
                    //             Since last month
                    //         </Box>
                    //     </>
                    // }
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <CardStats
                    subtitle='Revenue (less returns)'
                    title={'€' + revenue.toFixed(2)}
                    icon={CheckIcon}
                    color='bgSuccess'
                    // footer={
                    //     <>
                    //         <Box
                    //             component='span'
                    //             fontSize='.875rem'
                    //             color={theme.palette.success.main}
                    //             marginRight='.5rem'
                    //             display='flex'
                    //             alignItems='center'
                    //         >
                    //             <Box
                    //                 component={ArrowUpward}
                    //                 width='1.25rem!important'
                    //                 height='1.25rem!important'
                    //                 marginLeft='-.25rem'
                    //             />{' '}
                    //             20%
                    //         </Box>
                    //         <Box component='span' whiteSpace='nowrap'>
                    //             Since last month
                    //         </Box>
                    //     </>
                    // }
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <CardStats
                    subtitle='Rejected'
                    title={totals.rejected}
                    icon={CloseIcon}
                    color='bgError'
                    // footer={
                    //     <>
                    //         <Box
                    //             component='span'
                    //             fontSize='.875rem'
                    //             color={theme.palette.success.main}
                    //             marginRight='.5rem'
                    //             display='flex'
                    //             alignItems='center'
                    //         >
                    //             <Box
                    //                 component={ArrowUpward}
                    //                 width='1.25rem!important'
                    //                 height='1.25rem!important'
                    //                 marginLeft='-.25rem'
                    //             />{' '}
                    //             10%
                    //         </Box>
                    //         <Box component='span' whiteSpace='nowrap'>
                    //             Since last month
                    //         </Box>
                    //     </>
                    // }
                />
            </Grid>
        </Grid>
    )
}

export default PensionTotals
