import { Component } from 'react'
import { API } from 'aws-amplify'
//import { v4 as uuidv4 } from 'uuid'
import config from './Config'

const apiPath = config.apiGatewayEnergy.PATH

class EnergyClient extends Component {
	getCompanies() {
		return API.post(apiPath, 'companies/list', {})
	}

	addCompany(companyId, name, active, billingAccount, billingPeriod, billingMethod, billingEmail, vatRate, counties) {
        const data = {
			companyId,
			name,
			active,
			billingAccount,
			billingPeriod,
			billingMethod,
            billingEmail,
			vatRate,
			counties
		}
		console.log(data)

        return API.post(apiPath, 'companies/add', {
            body: data
        })
    }

    updateCompany(companyId, active, billingAccount, billingPeriod, billingMethod, billingEmail, vatRate, counties) {
        const data = {
			companyId,
			active,
			billingAccount,
			billingPeriod,
			billingMethod,
			billingEmail,
			vatRate,
			counties
		}
		console.log(data)

        return API.post(apiPath, 'companies/update', {
            body: data
        })
    }

	getCompanyDetails(companyId) {
		const data = {
			companyId
		}
		console.log(data)

		return API.post(apiPath, 'companies/get', {
			body: data
		})
	}

	getDeliveries() {
		return API.post(apiPath, 'deliveries/list', {})
	}
	
	getSfDeliveries() {
		return API.post(apiPath, 'deliveries/list-sf', {})
	}

	addDelivery(companyId, companyName, product, leadPrice, webhook, weight, active) {
		const data = {
			companyId,
			companyName,
			product,
			leadPrice,
			webhook,
			weight,
			active
		}
		console.log(data)

        return API.post(apiPath, 'deliveries/add', {
            body: data
        })
	}

	addSfDelivery(companyId, companyName, product, leadPrice, webhook, weight, weeklyCap, active) {
		const data = {
			companyId,
			companyName,
			product,
			leadPrice,
			webhook,
			weight,
			weeklyCap,
			active
		}
		console.log(data)

        return API.post(apiPath, 'deliveries/add-sf', {
            body: data
        })
	}

	updateDelivery(companyId, product, leadPrice, webhook, weight, active) {
		const data = {
			companyId,
			product,
			leadPrice,
			webhook,
			weight,
			active
		}
		console.log(data)

        return API.post(apiPath, 'deliveries/update', {
            body: data
        })
	}

	updateSfDelivery(companyId, product, leadPrice, webhook, weight, weeklyCap, active) {
		const data = {
			companyId,
			product,
			leadPrice,
			webhook,
			weight,
			weeklyCap,
			active
		}
		console.log(data)

        return API.post(apiPath, 'deliveries/update-sf', {
            body: data
        })
	}

	getLeads() {
		return API.post(apiPath, 'leads/list-all', {})
	}

	getLeadsPeriod(fromDate, toDate) {
		const data = {
			fromDate,
			toDate
		}
		console.log(data)
		return API.post(apiPath, 'leads/list-period', {
			body: data
		})
	}

	getSfLeads() {
		return API.post(apiPath, 'leads/list-sf', {})
	}

	getSfLeadsPeriod(fromDate, toDate) {
		const data = {
			fromDate,
			toDate
		}
		console.log(data)
		return API.post(apiPath, 'leads/list-sf-period', {
			body: data
		})
	}

	allocateLead(companyId, lead, site) {
		const data = {
			companyId,
			lead,
			site
		}
		console.log(data)

        return API.post(apiPath, 'leads/allocate', {
            body: data
        })
	}

	rejectLead(site, date, leadId) {
		const data = {
			site,
			date,
			leadId
		}
		console.log(data)

        return API.post(apiPath, 'leads/reject', {
            body: data
        })
	}
}

export default EnergyClient