import { Component } from 'react'
import { API } from 'aws-amplify'
//import { v4 as uuidv4 } from 'uuid'
import config from './Config'

const apiPath = config.apiGatewayGuest.PATH

class GuestClient extends Component {
	getInvite(email) {
		let data = {
			email
		}
		console.log(data)

		return API.post(apiPath, 'invites/get', {
			body: data
		})
	}

	getPaymentIntent(paymentIntentId) {
		const data = {
			paymentIntentId
		}
		console.log(data)

        return API.post(apiPath, 'stripe/get-payment-intent', {
            body: data
        })
	}

	createPaymentIntent(companyId, total) {
		const data = {
			companyId,
			total
		}
		console.log(data)

        return API.post(apiPath, 'stripe/create-payment-intent-on', {
            body: data
        })
	}

	createPayment(companyId, total) {
		const data = {
			companyId,
			total
		}
		console.log(data)

        return API.post(apiPath, 'payments/add', {
            body: data
        })
	}
} 

export default GuestClient