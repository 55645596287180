import React, { useState, useEffect, useContext } from 'react'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import AdminAPI from 'config/api/AdminAPI'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { onError } from 'config/lib/errorLib'
import Loader from 'components/general/Loader'
import ThisWeekTable from 'components/admin/ThisWeekTable'
import AllocationStats from 'components/broker/AllocationStats'
import { productOptions } from 'config/constants/Constants'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

function ThisWeek() {
    const api = new AdminAPI()

    const [product, setProduct] = useState('income-protection')
    const [activeCompanies, setActiveCompanies] = useState([])
    const [activeDeliveries, setActiveDeliveries] = useState([])
    const [totals, setTotals] = useState(null)
    const [companies, setCompanies] = useState([])
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)

    //const weeklyLimit = parseInt(companyDetails.weeklyLimits[product]) + parseInt(companyDetails.extraWeeklyLimits[product])

    useEffect(() => {
        getData()
    }, [])

    async function getData() {
        try {
            let companies = await api.getCompanies()
            console.log('companies: ', companies)
            let activeCompanies = companies.filter((company) => (company.active))
            activeCompanies.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
            console.log('companies: ', activeCompanies)

            const deliveries = await api.getDeliveries()
            let activeDeliveries = deliveries.filter((delivery) => (delivery.active))
            console.log('active deliveries: ', activeDeliveries)

            // const brokers = await api.getAllBrokers()
            // console.log('brokers: ', brokers)

            let fromDate = moment()
            fromDate.set('weekday', 6).subtract(1, 'week') // last saturday
            fromDate.set('hour', 0).set('minute', 0).set('second', 0)
            let toDate = moment()
            toDate.set('weekday', 5) // this friday
            toDate.set('hour', 23).set('minute', 59).set('second', 59)

            const leads = await api.getLeadsPeriod(product, fromDate.valueOf(), toDate.valueOf())
            console.log('leads: ', leads)

            let showCompanies = []

            let totals = {
                allocation: 0,
                extraAllocation: 0,
                delivered: leads.length
            }
    
            for (let delivery of activeDeliveries) {
                if (delivery.product === product) {
                    totals.allocation += parseInt(delivery.allocation)
                    totals.extraAllocation += parseInt(delivery.extraAllocation)
                    let index = activeCompanies.findIndex((company) => company.companyId === delivery.companyId)
                    if (index > -1) {
                        let company = {...activeCompanies[index]}
                        company['allocation'] = delivery.allocation
                        company['extraAllocation'] = delivery.extraAllocation
                        company['delivered'] = 0
                        showCompanies.push(company)
                    }
                }
            }
            console.log('show companies: ', showCompanies);

            for (let lead of leads) {
                let index = showCompanies.findIndex((company) => (company.companyId === lead.companyId)) 
                if (index > -1) {
                    showCompanies[index]['delivered'] += 1
                }
                else {
                    console.log('not in show companies: ', lead.companyId)
                }
            }

            setActiveCompanies(activeCompanies)
            setActiveDeliveries(activeDeliveries)
            setTotals(totals)
            setCompanies(showCompanies)
            setFromDate(fromDate)
            setToDate(toDate)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function updateProduct(product) {
        setProduct(product)
        setLoading(true)
        try {
            const leads = await api.getLeadsPeriod(product, fromDate.valueOf(), toDate.valueOf())
            console.log('leads: ', leads)

            let showCompanies = []

            let totals = {
                allocation: 0,
                extraAllocation: 0,
                delivered: leads.length
            }
    
            for (let delivery of activeDeliveries) {
                if (delivery.product === product) {
                    totals.allocation += parseInt(delivery.allocation)
                    totals.extraAllocation += parseInt(delivery.extraAllocation)
                    let index = activeCompanies.findIndex((company) => company.companyId === delivery.companyId)
                    if (index > -1) {
                        let company = {...activeCompanies[index]}
                        company['allocation'] = delivery.allocation
                        company['extraAllocation'] = delivery.extraAllocation
                        company['delivered'] = 0
                        showCompanies.push(company)
                    }
                }
            }
            console.log('show companies: ', showCompanies);

            for (let lead of leads) {
                let index = showCompanies.findIndex((company) => (company.companyId === lead.companyId)) 
                if (index > -1) {
                    showCompanies[index]['delivered'] += 1
                }
                else {
                    console.log('not in show companies: ', lead.companyId)
                }
            }

            setTotals(totals)
            setCompanies(showCompanies)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                marginTop='2rem'
                //classes={{ root: classes.containerRoot }}
            >
                {alert}
                {!loading ? (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Grid
                                container
                                component={Box}
                                alignItems='center'
                                justifyContent='flex-start'
                                style={{marginBottom: '2rem'}}
                                direction='row'
                            >
                                <Grid item xs={12} md={2}>
                                    <FormGroup style={{marginBottom: '0'}}>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                //IconComponent={KeyboardArrowDown}
                                                value={product}
                                                onChange={(e) => updateProduct(e.target.value)}
                                            >
                                                {productOptions.map((option) => {
                                                    return (<MenuItem value={option.value}>{option.label}</MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction='row' spacing={1}>
                                <Grid item xs={12}>
                                    <AllocationStats 
                                        allocation={totals.allocation}
                                        extraAllocation={totals.extraAllocation}
                                        delivered={totals.delivered}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ThisWeekTable
                                        companies={companies}
                                        //weeklyLimit={parseInt(companyDetails.weeklyLimits[product])}
                                        //requests={requests}
                                        //updateRequest={(request) => deleteRequest(request)}
                                        //deleteRequest={(request) => deleteRequest(request)}
                                        //showSuccessAlert={(text) => showSuccessAlert(text)}
                                    />
                                </Grid>
                                {/*
                                <Grid item xs={12}>
                                    <DeliveriesRemainingTable
                                        brokers={brokers}
                                        product={product}
                                        deliveries={deliveries}
                                        allocated={allocated.allocated}
                                        allocations={allocations}
                                        extraAllocations={extraAllocations}
                                        dailyCaps={dailyCaps}
                                        //weeklyLimit={parseInt(companyDetails.weeklyLimits[product])}
                                        //requests={requests}
                                        //updateRequest={(request) => deleteRequest(request)}
                                        //deleteRequest={(request) => deleteRequest(request)}
                                        //showSuccessAlert={(text) => showSuccessAlert(text)}
                                    />
                                </Grid>
                                */}
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    )
}

export default ThisWeek