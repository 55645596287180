import React from 'react'
import Chart from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import { chartOptions, parseOptions, chartExample7 } from 'config/constants/ChartVariables'
import componentStyles from 'assets/theme/components/cards/charts/card-product-comparison.js'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

const useStyles = makeStyles(componentStyles)

const themeColors = require('assets/theme/colors.js').default

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

var colors = {
    gray: themeColors.gray,
    theme: {
        default: themeColors.dark.main,
        primary: themeColors.primary.main,
        secondary: themeColors.secondary.main,
        info: themeColors.info.main,
        success: themeColors.success.main,
        danger: themeColors.error.main,
        warning: themeColors.warning.main,
    },
    black: themeColors.black.light,
    white: themeColors.white.main,
    transparent: themeColors.transparent.main,
}

// const options = {
//     scales: {
//       yAxes: [
//             {
//                 gridLines: {
//                     color: colors.gray[200],
//                     zeroLineColor: colors.gray[200],
//                 },
//                 ticks: {},
//             },
//         ],
//     },
// }

function LeadStatsBar(props) {
    const classes = useStyles()
    const theme = useTheme()
    if (window.Chart) {
        parseOptions(Chart, chartOptions())
    }

    let labels = []
    let currentMonth = moment().month()
    for (let i = 5; i > 0; i--) {
        let previousMonth = moment().subtract(i, 'months')
        //console.log(previousMonth.month());
        labels.push(months[previousMonth.month()])
    }
    labels.push(months[currentMonth])
    console.log('labels: ', labels)

    let lifeInsurace = []
    let incomeProtection = []
    let pensions = []
    let mortgageProtection = []
    let healthInsurance = []
    for (var month of labels) {
        let liTotal = 0
        let ipTotal = 0
        let penTotal = 0
        let mpTotal = 0
        let hiTotal = 0
        for (var payment of props.payments) {
            if ((moment(payment.date).isAfter(moment().startOf('month').subtract(5, 'months')) && moment(payment.date).format('MMMM') === month) && payment.allocation) {
                if (payment.allocation['life-insurance'] && payment.allocation['life-insurance'].allocation > 0) {
                    liTotal += parseInt(payment.allocation['life-insurance'].allocation * payment.allocation['life-insurance'].leadPrice)
                }
                if (payment.allocation['income-protection'] && payment.allocation['income-protection'].allocation > 0) {
                    ipTotal += parseInt(payment.allocation['income-protection'].allocation * payment.allocation['income-protection'].leadPrice)
                }
                if (payment.allocation['pensions'] && payment.allocation['pensions'].allocation > 0) {
                    penTotal += parseInt(payment.allocation['pensions'].allocation * payment.allocation['pensions'].leadPrice)
                }
                if (payment.allocation['mortgage-protection'] && payment.allocation['mortgage-protection'].allocation > 0) {
                    mpTotal += parseInt(payment.allocation['mortgage-protection'].allocation * payment.allocation['mortgage-protection'].leadPrice)
                }
                if (payment.allocation['health-insurance'] && payment.allocation['health-insurance'].allocation > 0) {
                    hiTotal += parseInt(payment.allocation['health-insurance'].allocation * payment.allocation['health-insurance'].leadPrice)
                }
            }
        }
        lifeInsurace.push(liTotal)
        incomeProtection.push(ipTotal)
        pensions.push(penTotal)
        mortgageProtection.push(mpTotal)
        healthInsurance.push(hiTotal)
    }

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Life Insurance',
                data: lifeInsurace,
                maxBarThickness: 10,
                backgroundColor: colors.theme['info']
            },
            {
                label: 'Income Protection',
                data: incomeProtection,
                maxBarThickness: 10,
                backgroundColor: colors.theme['danger']
            },
            {
                label: 'Pensions',
                data: pensions,
                maxBarThickness: 10,
                backgroundColor: colors.theme['primary']
            },
            {
                label: 'Mortgage Protection',
                data: mortgageProtection,
                maxBarThickness: 10,
                backgroundColor: colors.theme['success']
            },
            {
                label: 'Health Insurance',
                data: healthInsurance,
                maxBarThickness: 10,
                backgroundColor: colors.theme['info']
            },
        ]
    }
  
    return (
        <>
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    title={
                        <Box component='span' color={theme.palette.gray[600]}>
                            
                        </Box>
                    }
                    subheader='Total Income by Campaign'
                    classes={{ root: classes.cardHeaderRoot }}
                    titleTypographyProps={{
                        component: Box,
                        variant: 'h6',
                        letterSpacing: '2px',
                        marginBottom: '0!important',
                        classes: {
                            root: classes.textUppercase,
                        },
                    }}
                    subheaderTypographyProps={{
                        component: Box,
                        variant: 'h3',
                        marginBottom: '0!important',
                        color: 'initial',
                    }}
                ></CardHeader>
                
                <CardContent>
                    <Box position='relative' height='350px'>
                        <Bar
                            data={data}
                            options={chartExample7.options}
                            getDatasetAtEvent={(e) => console.log(e)}
                        />
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}

export default LeadStatsBar
