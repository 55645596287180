import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import BootstrapTable from 'react-bootstrap-table-next'
import Grid from '@material-ui/core/Grid'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import filterFactory, { textFilter, selectFilter, numberFilter } from 'react-bootstrap-table2-filter'
import ReturnRequestModal from 'components/admin/ReturnRequestModal'
import { getProductString } from 'config/helpers/Helpers'
import { NotificationManager } from 'react-notifications'
import componentStyles from 'assets/theme/views/admin/react-bs-table.js'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

const useStyles = makeStyles(componentStyles)

function ReturnRequestsTable(props) {
    const bstable: any = React.useRef()
    const classes = { ...useStyles() }
    const api = new AdminAPI()

    const requests = props.requests
    // brokers.sort((a, b) => {
    //     return b.remainingLeads - a.remainingLeads
    // })
    const [request, setRequest] = useState(null)
    const [showRequestModal, setShowRequestModal] = useState(false)
    const [selected, setSelected] = useState([])
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(false)

    function selectRequest(request) {
        let newSelected = [...selected]
        newSelected.push(request)
        setSelected(newSelected)
    }

    function deselectRequest(request) {
        let newSelected = [...selected]
        let index = selected.findIndex(item => item.phone === request.phone)
        newSelected.splice(index, 1)
        setSelected(newSelected)
    }

    function acceptRequestMultiple() {
        bstable.current.selectionContext.selected = []
        //console.log(bstable)
        props.acceptRequestMultiple(selected)
        setSelected([])
        setAlert(null)
    }

    function showBulkAcceptWarning() {
        let containsPension = false

        for (let request of selected) {
            if (request.product === 'pensions') {
                containsPension = true
            }
        }

        if (containsPension) {
            NotificationManager.error('Can not bulk accept pension lead returns. Please review these individually.')
        }
        else {
            setAlert(
                <ReactBSAlert
                    warning
                    style={{ display: 'block', marginTop: '-100px' }}
                    title='Are you sure?'
                    onConfirm={() => acceptRequestMultiple()}
                    onCancel={() => setAlert(false)}
                    cancelBtnCssClass='btn-secondary'
                    confirmBtnBsStyle='success'
                    cancelBtnText='Cancel'
                    confirmBtnText='Yes, Accept Requests'
                    showCancel
                    btnSize=''
                >
                    This will accept {selected.length} return requests immediately
                </ReactBSAlert>
            )
        }
    }

    const selectRow = {
        mode: 'checkbox',
        //clickToSelect: true,
        //selected: [this.state.jobOrderValue],
        //clickToExpand: true,
        selectColumnStyle: { textAlign: 'center', margin: 'auto', width: '50px' },
        onSelect: (row, isSelect, selected, e) => {
            //this.handleOnSelect(row, isSelect, selected, e);
            // console.log('selected')
            // console.log(row)
            // console.log(isSelect)
            // console.log(selected)
            if (isSelect) {
                selectRequest(row)
            }
            else {
                deselectRequest(row)
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            if (isSelect) {
                setSelected(rows)
            }
            else {
                setSelected([])
            }
        }
    }

    const columns = [
        {
            dataField: 'companyName',
            text: 'Client Name',
            //sort: true,
            //filter: textFilter(),
            formatter: (cell, row) => {
                return (<Box className='hover-text primary-text' onClick={() => openRequestModal(row)}>{row.companyName} ({row.companyId.toUpperCase()})</Box>)
                
            }
        },
        {
            dataField: 'brokerName',
            text: 'Broker',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{row.brokerName ? row.brokerName : '-'}</>
            }
        },
        {
            dataField: 'product',
            text: 'Product',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{getProductString(row.product)}</>
            }
        },
        {
            dataField: 'leadName',
            text: 'Lead Name',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{row.leadName}</>
            }
        },
        {
            dataField: 'leadEmail',
            text: 'Lead Email',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{row.email}</>
            }
        },
        {
            dataField: 'createdAt',
            text: 'Requested',
            sort: true,
            formatter: (cell, row) => {
                //return <>{moment(row.createdAt).format('DD/MM/YYYY HH:mm')}</>
                //return <>{moment().diff(row.createdAt, 'days')} days ago</>
                return <>{moment(row.createdAt).fromNow()}</>
            }
        }
        // {
        //     dataField: 'actions',
        //     text: 'Actions',
        //     //sort: true,
        //     //filter: textFilter(),
        //     formatter: (cell, row) => {
        //         return (
        //             <Button
        //                 variant='contained'
        //                 color='primary'
        //                 size='small'
        //                 onClick={() => alert('Charge now')}
        //             >
        //                 Charge Now
        //             </Button>
        //         )
        //     }
        // }
    ]

    function openRequestModal(request) {
        setRequest(request)
        setShowRequestModal(true)
    }

    function closeRequestModal() {
        setShowRequestModal(false)
        setRequest(null)
    }

    return (
        <Card>
            {alert}
            {!loading ? (
                <>
                    <CardHeader
                        subheader={
                            <>
                                <Box display='flex' style={{justifyContent: 'space-between'}}>
                                    <Box>
                                        <Box
                                            component={Typography}
                                            variant='h2'
                                            marginBottom='0!important'
                                        >
                                            <Box component='span'>Lead Return Requests</Box>
                                        </Box>
                                        <Box
                                            component='p'
                                            fontSize='.875rem'
                                            marginBottom='0'
                                            marginTop='0'
                                            lineHeight='1.7'
                                            fontWeight='300'
                                        >
                                            All pending lead return requests
                                        </Box>
                                    </Box>
                                </Box>
                            </>
                        }
                        classes={{ root: classes.cardHeaderRoot }}
                    ></CardHeader>
                    
                    <div className='table-container'>
                        <BootstrapTable 
                            ref={bstable}
                            keyField='phone' 
                            data={ requests } 
                            columns={ columns } 
                            selectRow={ selectRow }
                            filter={ filterFactory() } 
                            noDataIndication={<Box>No pending requests</Box>}
                            className='custom-table'
                        />
                    </div>

                    <Grid container>
                        {selected.length > 0 &&
                            <>
                                <Grid item xs={3} style={{margin: '0.5rem'}}>
                                    <Box>
                                        <Button
                                            component={Box}
                                            onClick={() => showBulkAcceptWarning()}
                                            color='primary'
                                            variant='contained'
                                            size='medium'
                                        >
                                            Accept {selected.length} Return Requests
                                        </Button>
                                    </Box>
                                </Grid>
                            </>
                        }
                    </Grid>

                    {showRequestModal &&
                        <ReturnRequestModal 
                            isOpen={showRequestModal}
                            close={() => closeRequestModal()}
                            request={request}
                            updateRequest={(request) => props.updateRequest(request)}
                            deleteRequest={(request) => props.deleteRequest(request)}
                            showSuccessAlert={(text) => props.showSuccessAlert(text)}
                        />
                    }
                </>
            ) : (
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                            <Loader />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Card>
    );
}

export default ReturnRequestsTable