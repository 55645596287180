import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Button from '@material-ui/core/Button'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import EnergyAPI from 'config/api/EnergyAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'

function EVChargerDetails(props) {
    //const api = new EnergyAPI()

    const quote = props.lead.quote
    
    const [installLocation, setInstallLocation] = useState(quote.installLocation || '')
    const [hasParking, setHasParking] = useState(quote.hasParking || '')
    const [hasGrantInterest, setHasGrantInterest] = useState(quote.hasGrantInterest || '')
    const [hasElectricCar, setHasElectricCar] = useState(quote.hasElectricCar || '')
    const [timeline, setTimeline] = useState(quote.timeline || '')
    const [showContent, setShowContent] = useState(props.showQuoteContent)
 
    // async function updateQuote() {
    //     try {
    //         let quote = {
    //             installLocation,
    //             hasParking,
    //             hasGrantInterest,
    //             hasElectricCar,
    //             timeline
    //         }
    //         let result = await api.updateLeadQuote(companyId, props.lead.product, props.lead.phone, quote)

    //         props.updateLead(result)
    //         NotificationManager.success('Details updated')
    //         setShowContent(false)
    //     } catch(e) {
    //         onError(e)
    //     }
    // }

    // function updatePremium(premium) {
    //     let formatted = premium.replace(/€/g, '')
    //     setPremium(formatted)
    // }
    
    return (
        <Card>
            <CardHeader
                subheader={
                    <Grid
                        container
                        component={Box}
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Grid item xs='auto'>
                            <Box
                                component={Typography}
                                variant='h3'
                                marginBottom='0!important'
                            >
                                EV Charger Details
                            </Box>
                        </Grid>
                        <Grid item xs='auto'>
                            <Box>
                                {showContent ? (
                                    <KeyboardArrowUp />
                                ) : (
                                    <KeyboardArrowDown />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                }
                onClick={() => setShowContent(!showContent)} 
                style={{cursor: 'pointer'}}
            >
            </CardHeader>
            
            {showContent &&
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Install Location</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={installLocation}
                                        onChange={(e) => setInstallLocation(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Private Parking</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={hasParking}
                                        onChange={(e) => setHasParking(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Interested in Grants</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={hasGrantInterest}
                                        onChange={(e) => setHasGrantInterest(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Electric Car</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    autoFocus
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={hasElectricCar}
                                        onChange={(e) => setHasElectricCar(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Timeline</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={timeline}
                                        onChange={(e) => setTimeline(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        
                        <Grid item xs={12} lg={12}>
                            <Grid container justifyContent='space-between'>
                                <Grid item>
                                    {/*
                                    <Button
                                        onClick={() => setShowQuotesModal(true)}
                                        color='primary'
                                        disabled
                                    >
                                        Generate Quotes
                                    </Button>
                                    */}
                                </Grid>
                                {/*!props.admin &&
                                    <Grid item>
                                        <Button
                                            component={Box}
                                            color='primary'
                                            variant='contained'
                                            size='medium'
                                            disabled={false}
                                            onClick={() => updateQuote()}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                */}
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            }
        </Card>
    )
}

export default EVChargerDetails