import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import CountySelect from 'components/general/CountySelect'
import ProductSelect from 'components/general/ProductSelect'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
import { defaultProducts } from 'config/constants/Constants'

import DetailsContext from 'config/context/DetailsContext'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function BrokerModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	const api = new BrokerAPI()
	const companyDetails = useContext(DetailsContext)
	const companyId = companyDetails.companyId
	const productsAvailable = companyDetails.productsAvailable || defaultProducts

    const details = props.broker

    const [counties, setCounties] = useState(details.counties || [])
	const [products, setProducts] = useState(details.products || ['All'])
	const [permissions, setPermissions] = useState(details.permissions || '')
	const [notificationEmails, setNotificationEmails] = useState(details.notificationEmails)
	const [reminderEmails, setReminderEmails] = useState((details.reminderEmails === true || details.reminderEmails === false) ? details.reminderEmails : true)
	const [hideInAnalytics, setHideInAnalytics] = useState((details.hideInAnalytics === true || details.hideInAnalytics === false) ? details.hideInAnalytics : false)

  	async function updateBroker() {
        console.log('update broker...')
		console.log(props.allocation)
		if (hideInAnalytics === true && props.allocation > 0) {
			NotificationManager.error('Allocation must be set to 0 in order to hide a broker')
		}
		else {
			try {
				let result = await api.updateBroker(companyId, details.brokerId, counties, permissions, notificationEmails, reminderEmails, hideInAnalytics, products)
				console.log(result)

				//props.refreshBrokers()
				props.updateBroker(result)
				NotificationManager.success('Broker updated')
			} catch(e) {
				onError(e)
			}
		}
    }

    function checkFields() {
        return false
    }

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					{details.firstName + ' ' + details.lastName}
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
			
			<DialogContent>
				<FormGroup>
                  	<FormLabel>Work Email</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='email'
                    	value={details.email}
                    	//onChange={(e) => setEmail(e.target.value)}
						disabled
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Phone</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                    	value={details.phone}
                    	//onChange={(e) => setEmail(e.target.value)}
						disabled
                  	/>
                </FormGroup>
				<FormGroup>
                  	<FormLabel>Permissions</FormLabel>
                  	<FormControl variant='outlined' fullWidth>
                    	<Select
                      		multiple={false}
                      		//IconComponent={KeyboardArrowDown}
                      		value={permissions}
                      		onChange={(e) => setPermissions(e.target.value)}
							input={<OutlinedInput />}
                    	>
                      		<MenuItem value={'broker'}>Broker</MenuItem>
                      		<MenuItem value={'admin'}>Admin</MenuItem>
                    	</Select>
                  	</FormControl>
            	</FormGroup>
				<FormGroup>
					<FormLabel>Counties</FormLabel>
					<FormControl variant='outlined' fullWidth>
						<CountySelect 
							counties={counties}
							updateCounties={(counties) => setCounties(counties)}
						/>
					</FormControl>
				</FormGroup>
				<FormGroup>
					<FormLabel>Products</FormLabel>
					<FormControl variant='outlined'>
						<ProductSelect 
							products={products}
							updateProducts={(products) => setProducts(products)}
							productsAvailable={productsAvailable}
						/>
					</FormControl>
				</FormGroup>
				<FormGroup>
                  	<FormLabel>Notification Emails (All Emails)</FormLabel>
                  	<FormControl variant='outlined' fullWidth>
                    	<Select
                      		multiple={false}
                      		//IconComponent={KeyboardArrowDown}
                      		value={notificationEmails}
                      		onChange={(e) => setNotificationEmails(e.target.value)}
							input={<OutlinedInput />}
                    	>
                      		<MenuItem value={true}>On</MenuItem>
                      		<MenuItem value={false}>Off</MenuItem>
                    	</Select>
                  	</FormControl>
            	</FormGroup>
				<FormGroup>
                  	<FormLabel>Reminder Emails (Recurring Lead Reminders Only)</FormLabel>
                  	<FormControl variant='outlined' fullWidth>
                    	<Select
                      		multiple={false}
                      		//IconComponent={KeyboardArrowDown}
                      		value={reminderEmails}
                      		onChange={(e) => setReminderEmails(e.target.value)}
							input={<OutlinedInput />}
                    	>
                      		<MenuItem value={true}>On</MenuItem>
                      		<MenuItem value={false}>Off</MenuItem>
                    	</Select>
                  	</FormControl>
            	</FormGroup>
				<FormGroup>
                  	<FormLabel>Hide in Allocations/Analytics</FormLabel>
                  	<FormControl variant='outlined' fullWidth>
                    	<Select
                      		multiple={false}
                      		//IconComponent={KeyboardArrowDown}
                      		value={hideInAnalytics}
                      		onChange={(e) => setHideInAnalytics(e.target.value)}
							input={<OutlinedInput />}
                    	>
                      		<MenuItem value={true}>On</MenuItem>
                      		<MenuItem value={false}>Off</MenuItem>
                    	</Select>
                  	</FormControl>
            	</FormGroup>
			</DialogContent>
			
			<DialogActions>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Cancel
				</Button>
			
				<Button
					component={Box}
					onClick={() => updateBroker()}
					color='primary'
					marginLeft='auto!important'
					variant='contained'
					disabled={checkFields()}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	)
}