import React, { useState } from 'react'
import Chart from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import { chartOptions, parseOptions, chartExample6 } from 'config/constants/ChartVariables'
import { pensionTypes, pensionSizes } from 'config/constants/Constants'

import componentStyles from 'assets/theme/components/cards/charts/card-affiliate-traffic.js'

const useStyles = makeStyles(componentStyles)

const typeColors = {
    'Untyped': '#B8BBBE',
    'Callback': '#99bfc1',
    'General Pension': '#80afb1',
    'Pension Property': '#679fa1',
    'Start Company Pension': '#4d8f92',
    'Start Pension': '#347f82',
    'Pension Review': '#1a6f73',
    'Pension Transfer': '#015f63'
}

function PensionTypePie(props) {
    const classes = useStyles()
    const theme = useTheme()
    if (window.Chart) {
        parseOptions(Chart, chartOptions())
    }

    const [size, setSize] = useState('All')

    function updateSize(size) {
        setSize(size)
    }

    let types = {
        'Untyped': 0
    }

    for (let type of pensionTypes) {
        types[type] = 0
    }
    //console.log('types: ', types)

    for (let lead of props.leads) {
        if (lead.quote.currentValue === size || size === 'All') {
            if (pensionTypes.indexOf(lead.quote.type) > -1) {
                types[lead.quote.type] += 1
            }
            else {
                types['Untyped'] += 1
            }
        }
    }
    //console.log('types: ', types)

    let labels = []
    let values = []
    let colors = []

    for (let type of Object.keys(types)) {
        //console.log(type)
        if (types[type] > 0) {
            labels.push(type)
            values.push(types[type])
            colors.push(typeColors[type])
        }
    }
    let updatedLabels = labels.map((label, i) => {
        return label + ' (' + ((values[i] / props.leads.length) * 100).toFixed() + '%)'
    })
    console.log('updated labels: ', updatedLabels)
    // console.log('labels: ', labels)
    // console.log('values: ', values)
    // console.log('colors', colors)

    const data = {
        labels: updatedLabels, // array of labels
        datasets: [
            {
                //label: 'Annual Premiums (€)',
                data: values, // array of values,
                //maxBarThickness: 10,
                backgroundColor: colors,
            },
        ]
    }
  
    return (
        <>
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    title={
                        <Box component='span' color={theme.palette.gray[600]}>
                            
                        </Box>
                    }
                    subheader='Lead Volume by Type'
                    classes={{ root: classes.cardHeaderRoot }}
                    titleTypographyProps={{
                        component: Box,
                        variant: 'h6',
                        letterSpacing: '2px',
                        marginBottom: '0!important',
                        classes: {
                            root: classes.textUppercase,
                        },
                    }}
                    subheaderTypographyProps={{
                        component: Box,
                        variant: 'h3',
                        marginBottom: '0!important',
                        color: 'initial',
                    }}
                ></CardHeader>
                
                <CardContent>
                    <Box width='25%'>
                        <FormGroup style={{marginBottom: '0'}}>
                            <FormControl variant='outlined'>
                                <Select
                                    multiple={false}
                                    defaultValue={null}
                                    IconComponent={KeyboardArrowDown}
                                    value={size}
                                    onChange={(e) => updateSize(e.target.value)}
                                >
                                    <MenuItem value={'All'}>All Sizes</MenuItem>
                                    {pensionSizes.map((size) => {
                                        return <MenuItem value={size}>{size}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Box>
                    <Box position='relative' height='350px'>
                        <Pie
                            data={data}
                            options={{
                                legend: {
                                    display: true,
                                    position: "bottom",
                                },
                            }}
                            //options={chartExample6.options}
                            //getDatasetAtEvent={(e) => console.log(e)}
                        />
                    </Box>
                </CardContent>

                <Box
                    fontSize='12px'
                    color={theme.palette.gray[600]}
                    marginBottom='1rem'
                    marginLeft='1rem'
                    //marginTop='2rem'
                    textAlign='left'
                    //className='online-discount'
                >
                    <Box component='small' fontSize='100%'>
                        * Volume charts reflect gross lead volume, including rejections and returns
                    </Box>
                </Box>
            </Card>
        </>
    )
}

export default PensionTypePie