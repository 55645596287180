import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import EnergyAPI from 'config/api/EnergyAPI'
import { onError } from 'config/lib/errorLib'
import { eeProductOptions } from 'config/constants/Constants'
import { NotificationManager } from 'react-notifications'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function AddDeliveryModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	//const theme = useTheme()
  	const api = new EnergyAPI()

	const deliveries = props.deliveries

    //const companies = props.companies.filter((company) => company.active)
	const companies = props.companies

    const [company, setCompany] = useState('')
    const [product, setProduct] = useState('')
    const [leadPrice, setLeadPrice] = useState(0)
    const [webhook, setWebhook] = useState('')
	const [weight, setWeight] = useState(1)
	const [weeklyCap, setWeeklyCap] = useState(0)
    const [active, setActive] = useState(true)

  	async function addDelivery() {
		let selectedCompany = companies.find(item => item.companyId === company)
		console.log('company: ', selectedCompany) 

		try {   
			let delivery = props.site === 'sf' ? await api.addSfDelivery(selectedCompany.companyId, selectedCompany.name, product, leadPrice, webhook, weight, weeklyCap, active) :  await api.addDelivery(selectedCompany.companyId, selectedCompany.name, product, leadPrice, webhook, weight, active)
			console.log('delivery: ', delivery)

			props.refreshDeliveries()
			props.showSuccessAlert('Delivery added!')
			props.close()
		} catch(e) {
			onError(e)
		}
  	}

  	function checkFields() {
  		if (company.length > 0 && product.length > 0) {
  			return false
  		}
  		else {
  			return true
  		}
  	}

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Add Delivery
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>

			<DialogContent>
                <FormGroup>
                    <FormLabel>Company</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            {companies.map((company) => {
                                return (
                                    <MenuItem value={company.companyId}>{company.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Product</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            value={product}
                            onChange={(e) => setProduct(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            {eeProductOptions.map((product) => {
                                return (
                                    <MenuItem value={product.value}>{product.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Lead Price (€)</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={leadPrice}
                    	onChange={(e) => setLeadPrice(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Webhook</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={webhook}
                    	onChange={(e) => setWebhook(e.target.value)}
                  	/>
                </FormGroup>
				<FormGroup>
                  	<FormLabel>Weight</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='number'
                        value={weight}
                    	onChange={(e) => setWeight(e.target.value)}
                  	/>
                </FormGroup>
				{props.site === 'sf' &&
					<FormGroup>
						<FormLabel>Weekly Cap</FormLabel>
						<OutlinedInput
							fullWidth
							type='number'
							value={weeklyCap}
							onChange={(e) => setWeeklyCap(e.target.value)}
						/>
					</FormGroup>
				}
                <FormGroup>
                    <FormLabel>Active</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={active}
                            onChange={(e) => setActive(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
			</DialogContent>
			
			<DialogActions>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Cancel
				</Button>
			
				<Button
					component={Box}
					onClick={() => addDelivery()}
					color='primary'
					marginLeft='auto!important'
					variant='contained'
					disabled={checkFields()}
				>
					Add
				</Button>
			</DialogActions>
		</Dialog>
	)
}