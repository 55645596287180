import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
//import QuotesModal from 'components/broker/QuotesModal'
import BrokerAPI from 'config/api/BrokerAPI'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
import { 
    genderOptions, 
    maritalStatusOptions, 
    smokingStatusOptions, 
    countyOptions, 
    countryOptions, 
    employmentStatusOptions,
    validDob,
    validEmail 
} from 'config/constants/Constants'

import DetailsContext from 'config/context/DetailsContext'

import componentStyles from 'assets/theme/views/admin/validation.js'
import componentStylesForms from 'assets/theme/components/forms.js'

const useStyles = makeStyles(componentStyles)
const useStylesForms = makeStyles(componentStylesForms)

function PersonalDetails(props) {
    const classes = { ...useStyles(), ...useStylesForms() }
    const api = props.admin ? new AdminAPI() : new BrokerAPI()
    const companyDetails = useContext(DetailsContext)
	const companyId = companyDetails.companyId || props.companyId

    const details = props.lead

    const [phone, setPhone] = useState(details.phone || '')
    const [email, setEmail] = useState(details.email || '')
    const [emailValidation, setEmailValidation] = useState(null)
    const [firstName, setFirstName] = useState(details.firstName || '')
    const [firstNameValidation, setFirstNameValidation] = useState(null)
    const [lastName, setLastName] = useState(details.lastName || '')
    const [lastNameValidation, setLastNameValidation] = useState(null)
    const [dob, setDob] = useState(details.dob || '')
    //const [dobValidation, setDobValidation] = useState(null)
    const [gender, setGender] = useState(details.gender || '')
    const [smokingStatus, setSmokingStatus] = useState(getSmokingStatus(details.smokingStatus))
    const [majorHealthIssue, setMajorHealthIssue] = useState(details.majorHealthIssue || '')
    const [maritalStatus, setMaritalStatus] = useState(details.maritalStatus || '')
    const [address, setAddress] = useState(details.address || '')
    const [city, setCity] = useState(details.city || '')
    const [county, setCounty] = useState(details.county ? details.county.trim() : '')
    const [eircode, setEircode] = useState(details.eircode || '')
    const [eircodeValidation, setEircodeValidation] = useState(null)
    const [country, setCountry] = useState(details.country || 'Ireland')
    //const [countryValidation, setCountryValidation] = useState(null)
    const [employmentStatus, setEmploymentStatus] = useState(details.employmentStatus ? details.employmentStatus : '')
    const [occupation, setOccupation] = useState(details.occupation || '')
    const [salary, setSalary] = useState(details.salary || '')

    const [showContent, setShowContent] = useState(false)

    function getSmokingStatus(smokingStatus) {
        let displaySs = ''
        if (smokingStatus && smokingStatus.toLowerCase() === 'non-smoker') {
            displaySs = 'Non Smoker'
        }
        else {
            displaySs = smokingStatus
        }
        return displaySs
    }
    
    async function updateDetails() {
        try {
            let details = {
                phone,
                email,
                firstName,
                lastName,
                dob,
                gender,
                smokingStatus,
                majorHealthIssue,
                maritalStatus,
                address,
                city,
                county,
                eircode,
                country,
                employmentStatus,
                occupation,
                salary
            }
            let result = null

            if (props.admin) {
                result = await api.updateLeadDetails(props.lead.date, props.lead.leadId, details)
            }
            else {
                //result = props.lead.product === 'general' ? await api.updateGeneralDetails(companyId, props.lead.date, props.lead.createdAt, details) : await api.updateLeadDetails(companyId, props.lead.product, props.lead.phone, details)
                result = await api.updateLeadDetails(companyId, props.lead.product, props.lead.phone, details)
            }

            props.updateLead(result)
            NotificationManager.success('Details updated')
            setShowContent(false)
        } catch(e) {
            onError(e)
        }
    }

    function checkRequiredFields() {
        if (phone.length > 0 && firstName.length > 0) {
            return false
        }
        else {
            return true
        }
    }

    function checkPhoneDisabled() {
        // if (details.product === 'general') {
        //     return false
        // }
        if (props.admin) {
            return false
        }
        else {
            return true
        }
    }

    function updateDob(val) {
        let updated = val.replace(/^(\d{2})(\d{2})/, '$1/$2/')
        setDob(updated)
    }
    
    return (
        <Card>
            <CardHeader
                subheader={
                    <Grid
                        container
                        component={Box}
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Grid item xs='auto'>
                            <Box
                                component={Typography}
                                variant='h3'
                                marginBottom='0!important'
                            >
                                Client Details
                            </Box>
                        </Grid>
                        <Grid item xs='auto'>
                            <Box>
                                {showContent ? (
                                    <KeyboardArrowUp />
                                ) : (
                                    <KeyboardArrowDown />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                }
                onClick={() => setShowContent(!showContent)} 
                style={{cursor: 'pointer'}}
            >
            </CardHeader>
            
            {showContent &&
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>First Name *</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={firstName}
                                        classes={{
                                            notchedOutline: clsx({
                                                [classes.borderWarning]:
                                                    firstNameValidation === 'invalid',
                                                [classes.borderSuccess]:
                                                    firstNameValidation === 'valid',
                                            }),
                                        }}
                                        onChange={(e) => {
                                            if (e.target.value === '') {
                                                setFirstNameValidation('invalid')
                                            } else {
                                                setFirstNameValidation('valid')
                                            }
                                            setFirstName(e.target.value)
                                        }}
                                    />
                                </FormControl>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Last Name</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={lastName}
                                        classes={{
                                            notchedOutline: clsx({
                                                [classes.borderWarning]:
                                                    lastNameValidation === 'invalid',
                                                [classes.borderSuccess]:
                                                    lastNameValidation === 'valid',
                                            }),
                                        }}
                                        onChange={(e) => {
                                            if (e.target.value === '') {
                                                setLastNameValidation('invalid')
                                            } else {
                                                setLastNameValidation('valid')
                                            }
                                            setLastName(e.target.value)
                                        }}
                                    />
                                </FormControl>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Phone {details.product !== 'general' ? '*' : ''}</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={phone}
                                        disabled={checkPhoneDisabled()}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Email</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='email'
                                        value={email}
                                        classes={{
                                            notchedOutline: clsx({
                                                [classes.borderWarning]:
                                                    emailValidation === 'invalid',
                                                [classes.borderSuccess]:
                                                    emailValidation === 'valid',
                                            }),
                                        }}
                                        onChange={(e) => {
                                            if (!validEmail.test(e.target.value)) {
                                                setEmailValidation('invalid')
                                            } else {
                                                setEmailValidation('valid')
                                            }
                                            setEmail(e.target.value)
                                        }}
                                    />
                                </FormControl>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Address</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>County</FormLabel>
                                <FormControl variant='outlined' fullWidth>
                                    <Select
                                        multiple={false}
                                        IconComponent={KeyboardArrowDown}
                                        value={county}
                                        //error={countyValidation === 'invalid'}
                                        onChange={(e) => setCounty(e.target.value)}
                                    >
                                        {countyOptions.map((option, i) => {
                                            return (<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
                                        })}
                                    </Select>
                                </FormControl>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Country</FormLabel>
                                <FormControl variant='outlined' fullWidth>
                                    <Select
                                        multiple={false}
                                        IconComponent={KeyboardArrowDown}
                                        value={country}
                                        onChange={(e) => setCountry(e.target.value)}
                                    >
                                        {countryOptions.map((option, i) => {
                                            return (<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
                                        })}
                                    </Select>
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <Grid container>
                                <Grid item xs={12} lg={6}>
                                    <FormGroup>
                                        <FormLabel>Date of Birth</FormLabel>
                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                            //marginBottom='1rem!important'
                                        >
                                            <OutlinedInput
                                                fullWidth
                                                type='text'
                                                value={dob}
                                                onChange={(e) => {updateDob(e.target.value)}}
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormGroup>
                                        <FormLabel>Gender</FormLabel>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                IconComponent={KeyboardArrowDown}
                                                value={gender}
                                                //error={genderValidation === 'invalid'}
                                                onChange={(e) => setGender(e.target.value)}
                                            >
                                                {genderOptions.map((option, i) => {
                                                    return (<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormGroup>
                                        <FormLabel>Smoking Status</FormLabel>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                IconComponent={KeyboardArrowDown}
                                                value={smokingStatus}
                                                //error={smokingStatusValidation === 'invalid'}
                                                onChange={(e) => setSmokingStatus(e.target.value)}
                                            >
                                                {smokingStatusOptions.map((option, i) => {
                                                    return (<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormGroup>
                                        <FormLabel>Major Health Issue</FormLabel>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                IconComponent={KeyboardArrowDown}
                                                value={majorHealthIssue}
                                                //error={smokingStatusValidation === 'invalid'}
                                                onChange={(e) => setMajorHealthIssue(e.target.value)}
                                            >
                                                <MenuItem value={'Yes'}>Yes</MenuItem>
                                                <MenuItem value={'No'}>No</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormGroup>
                                        <FormLabel>Marital Status</FormLabel>
                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                            //marginBottom='1rem!important'
                                        >
                                            <OutlinedInput
                                                fullWidth
                                                type='text'
                                                value={maritalStatus}
                                                onChange={(e) => setMaritalStatus(e.target.value)}
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormGroup>
                                        <FormLabel>Employment Status</FormLabel>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                IconComponent={KeyboardArrowDown}
                                                value={employmentStatus}
                                                onChange={(e) => setEmploymentStatus(e.target.value)}
                                            >
                                                {employmentStatusOptions.map((option, i) => {
                                                    return (<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormGroup>
                                        <FormLabel>Occupation</FormLabel>
                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                            //marginBottom='1rem!important'
                                        >
                                            <OutlinedInput
                                                fullWidth
                                                type='text'
                                                value={occupation}
                                                onChange={(e) => setOccupation(e.target.value)}
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormGroup>
                                        <FormLabel>Salary</FormLabel>
                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                            //marginBottom='1rem!important'
                                        >
                                            <OutlinedInput
                                                fullWidth
                                                type='text'
                                                value={salary}
                                                onChange={(e) => setSalary(e.target.value)}
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <FormLabel>Town/City</FormLabel>
                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                            //marginBottom='1rem!important'
                                        >
                                            <OutlinedInput
                                                fullWidth
                                                type='text'
                                                value={city}
                                                onChange={(e) => setCity(e.target.value)}
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <FormLabel>Eircode</FormLabel>
                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                            //marginBottom='1rem!important'
                                        >
                                            <OutlinedInput
                                                fullWidth
                                                type='text'
                                                value={eircode}
                                                classes={{
                                                    notchedOutline: clsx({
                                                        [classes.borderWarning]:
                                                            eircodeValidation === 'invalid',
                                                        [classes.borderSuccess]:
                                                            eircodeValidation === 'valid',
                                                    }),
                                                }}
                                                onChange={(e) => {
                                                    if (e.target.value === '') {
                                                        setEircodeValidation('invalid')
                                                    } else {
                                                        setEircodeValidation('valid')
                                                    }
                                                    setEircode(e.target.value)
                                                }}
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} lg={12}>        
                            <Grid container justifyContent='space-between'>
                                <Grid item>
                                    {/*
                                    <Button
                                        onClick={() => setShowQuotesModal(true)}
                                        color='primary'
                                        disabled
                                    >
                                        Generate Quotes
                                    </Button>
                                    */}
                                </Grid>
                                <Grid item>
                                    <Button
                                        component={Box}
                                        color='primary'
                                        variant='contained'
                                        size='medium'
                                        disabled={checkRequiredFields()}
                                        onClick={() => updateDetails()}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            }
        </Card>
    )
}

export default PersonalDetails