import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import SalesIcon from '@material-ui/icons/EuroSymbol'

// core components
import CardStats from 'components/argon/Cards/Dashboard/CardStats.js'

import componentStyles from 'assets/theme/components/headers/stats-header.js'

const useStyles = makeStyles(componentStyles);

const TotalStats = ( props ) => {
    const classes = useStyles();
    const theme = useTheme();

    const totals = props.totals
    
    return (
        <Grid container direction='row' spacing={1} style={{marginBottom: '2rem'}}>
            <Grid item xs={12} md={4}>
                <CardStats
                    subtitle='Income'
                    title={'€' + totals.income.toFixed(2)}
                    icon={SalesIcon}
                    color='bgPrimary'
                    // footer={
                    //     <>
                    //         <Box
                    //             component='span'
                    //             fontSize='.875rem'
                    //             color={theme.palette.success.main}
                    //             marginRight='.5rem'
                    //             display='flex'
                    //             alignItems='center'
                    //         >
                    //             <Box
                    //                 component={ArrowUpward}
                    //                 width='1.25rem!important'
                    //                 height='1.25rem!important'
                    //                 marginLeft='-.25rem'
                    //             />{' '}
                    //             10%
                    //         </Box>
                    //         <Box component='span' whiteSpace='nowrap'>
                    //             Since last month
                    //         </Box>
                    //     </>
                    // }
                />
            </Grid>
            
            <Grid item xs={12} md={4}>
                <CardStats
                    subtitle='Costs'
                    title={'€' + totals.costs.toFixed(2)}
                    icon={SalesIcon}
                    color='bgWarning'
                    // footer={
                    //     <>
                    //         <Box
                    //             component='span'
                    //             fontSize='.875rem'
                    //             color={theme.palette.success.main}
                    //             marginRight='.5rem'
                    //             display='flex'
                    //             alignItems='center'
                    //         >
                    //             <Box
                    //                 component={ArrowUpward}
                    //                 width='1.25rem!important'
                    //                 height='1.25rem!important'
                    //                 marginLeft='-.25rem'
                    //             />{' '}
                    //             20%
                    //         </Box>
                    //         <Box component='span' whiteSpace='nowrap'>
                    //             Since last month
                    //         </Box>
                    //     </>
                    // }
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <CardStats
                    subtitle='Profit'
                    title={'€' + (totals.income - totals.costs).toFixed(2)}
                    icon={SalesIcon}
                    color='bgSuccess'
                    // footer={
                    //     <>
                    //         <Box
                    //             component='span'
                    //             fontSize='.875rem'
                    //             color={theme.palette.success.main}
                    //             marginRight='.5rem'
                    //             display='flex'
                    //             alignItems='center'
                    //         >
                    //             <Box
                    //                 component={ArrowUpward}
                    //                 width='1.25rem!important'
                    //                 height='1.25rem!important'
                    //                 marginLeft='-.25rem'
                    //             />{' '}
                    //             10%
                    //         </Box>
                    //         <Box component='span' whiteSpace='nowrap'>
                    //             Since last month
                    //         </Box>
                    //     </>
                    // }
                />
            </Grid>
        </Grid>
    )
}

export default TotalStats
