import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import PhoneInput from 'components/general/PhoneInput'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'
import { defaultProducts } from 'config/constants/Constants'
import { getProductString } from 'config/helpers/Helpers'

import DetailsContext from 'config/context/DetailsContext'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function AddLeadModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	//const theme = useTheme()
  	const api = new BrokerAPI()
	const companyDetails = useContext(DetailsContext)
	const companyId = companyDetails.companyId
	const productsAvailable = companyDetails.productsAvailable || defaultProducts
	
	const details = props.details

    const [product, setProduct] = useState(props.product)
  	const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
  	const [email, setEmail] = useState('')
  	const [phone, setPhone] = useState('')

  	async function addLead() {
  		try {
			let brokerId = details.permissions === 'admin' ? null : details.brokerId
			let brokerName = details.permissions === 'admin' ? null : details.firstName + ' ' + details.lastName

            let lead = await api.addLead(companyId, product, firstName, lastName, email, phone, brokerId, brokerName)
            console.log('lead: ', lead)

  			props.refreshLeads()
  			props.showSuccessAlert('Lead added!')
  			props.close()
  		} catch(e) {
  			onError(e)
  		}
  	}

  	function checkFields() {
  		if (firstName.length > 0 && lastName.length > 0 && email.length > 0 && phone.length > 0) {
  			return false
  		}
  		else {
  			return true
  		}
  	}

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Add Lead
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
			
			<DialogContent>
                <FormGroup>
                  	<FormLabel>Product</FormLabel>
                  	<FormControl variant='outlined' fullWidth>
                    	<Select
                      		multiple={false}
                      		IconComponent={KeyboardArrowDown}
                      		value={product}
                      		onChange={(e) => setProduct(e.target.value)}
                    	>
							{Object.keys(productsAvailable).map((product) => {
								if (productsAvailable[product]) {
									return (<MenuItem value={product}>{getProductString(product)}</MenuItem>)
								}
							})}
                    	</Select>
                  	</FormControl>
            	</FormGroup>
				<FormGroup>
                  	<FormLabel>First Name</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                    	placeholder='John'
                    	onChange={(e) => setFirstName(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Last Name</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                    	placeholder='Smith'
                    	onChange={(e) => setLastName(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Email</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='email'
                    	placeholder='john@example.com'
                    	onChange={(e) => setEmail(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Phone</FormLabel>
                  	<PhoneInput 
						phone={phone}
						updatePhone={(phone) => setPhone(phone)}  
                        autoFocus={false}
					/>
                </FormGroup>
			</DialogContent>
			
			<DialogActions>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Cancel
				</Button>
			
				<Button
					component={Box}
					onClick={() => addLead()}
					color='primary'
					marginLeft='auto!important'
					variant='contained'
					disabled={checkFields()}
				>
					Add
				</Button>
			</DialogActions>
		</Dialog>
	)
}