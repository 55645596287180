import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import IPQuoteDetails from 'components/broker/ProductDetails/IPQuoteDetails'
import MPQuoteDetails from 'components/broker/ProductDetails/MPQuoteDetails'
import LIQuoteDetails from 'components/broker/ProductDetails/LIQuoteDetails'
import PENQuoteDetails from 'components/broker/ProductDetails/PENQuoteDetails'
import HIQuoteDetails from 'components/broker/ProductDetails/HIQuoteDetails'
import BIQuoteDetails from 'components/broker/ProductDetails/BIQuoteDetails'
import HealthQuoteDetails from 'components/broker/ProductDetails/HealthQuoteDetails'
import GadgetQuoteDetails from 'components/broker/ProductDetails/GadgetQuoteDetails'
import PetQuoteDetails from 'components/broker/ProductDetails/PetQuoteDetails'
import TravelQuoteDetails from 'components/broker/ProductDetails/TravelQuoteDetails'
import PersonalDetails from 'components/broker/PersonalDetails'
import Notes from 'components/admin/Notes'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
import Voicemail from 'components/broker/Voicemail'
import Loader from 'components/general/Loader'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'
import componentStylesButton from 'assets/theme/components/button.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)
const useStylesButton = makeStyles(componentStylesButton)

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='down' ref={ref} {...props} />
})

export default function LeadModal(props) {
	const classes = {...useStyles(), ...useStylesDialog(), ...useStylesButton()}
	const api = new AdminAPI()
    const companies = props.companies

	const [lead, setLead] = useState(props.lead)
	const [showQuoteContent, setShowQuoteContent] = useState(true)
    const [company, setCompany] = useState(null)
    const [selectedBroker, setSelectedBroker] = useState('unassigned')
    const [pricing, setPricing] = useState(null)
    const [brokers, setBrokers] = useState([])
    const [loading, setLoading] = useState(false)
	const [showAlert, setShowAlert] = useState(false)
    const [showRejectLead, setShowRejectLead] = useState(false)

    async function allocateLead() {
        console.log('update company: ', company)
        console.log('update broker: ', selectedBroker)
        let broker = selectedBroker === 'unassigned' ? null : selectedBroker
        try {
            let newLead = await api.allocateLead(company, broker, lead, pricing)
            //let success = true
            console.log('newLead: ', newLead)

            props.showSuccessAlert('Lead Allocated to ' + company.toUpperCase())
            props.updateLead(newLead)
            props.close()
        } catch(e) {
            onError(e)
            NotificationManager.error('Error allocating lead')
        }
    }

    async function rejectLead() {
        try {
            let newLead = await api.rejectLead(lead.date, lead.leadId)
            //let success = true
            console.log('newLead: ', newLead)

            props.showSuccessAlert('Lead Rejected')
            props.updateLead(newLead)
            props.close()
        } catch(e) {
            onError(e)
            NotificationManager.error('Error rejecting lead')
        }
    }

	async function updateCompany(companyId) {
        console.log('update company: ', companyId)
        setCompany(companyId)
        setLoading(true)
        setShowAlert(true)
        try {
            let details = await api.getCompanyDetails(companyId)
            let brokers = details.brokerDiary ? await api.getCompanyBrokers(companyId) : []
            console.log('brokers: ', brokers)
            let pricing = null
            
            if (lead.product === 'pensions') {
                try {
                    pricing = await api.getBalanceDeliveryPricing(companyId, lead.product)
                } catch(e) {
                    //onError(e)
                    console.log(e)
                }
            }
            console.log('pricing: ', pricing)

            setBrokers(brokers)
            setPricing(pricing)
            setLoading(false)
        } catch(e) {
            onError(e)
            NotificationManager.error('Error getting company brokers')
        }
	}

    function updateLead(details) {
		setLead(details)
		props.updateLead(details)
	}

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			maxWidth='lg'
			className='lead-modal'
			//className='fixed-height-modal'
			//fullScreen
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					{lead.firstName + ' ' + lead.lastName} (<a href={'tel:' + lead.phone}>{lead.phone}</a> | <a href={'mailto:' + lead.email}>{lead.email}</a>)
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
			
			<DialogContent style={{paddingTop: '0'}}>
				<Grid container alignItems='center' justifyContent='center' style={{marginBottom: '1rem'}}>
                    <Grid item xs={3}>
                        <FormControl variant='outlined' fullWidth direction='row'>
                            <FormLabel>Company</FormLabel>
                            <Select
                                multiple={false}
                                value={lead.companyId}
                                onChange={(e) => updateCompany(e.target.value)}
                                IconComponent={KeyboardArrowDown}
                                disabled={lead.companyId}
                            >
                                {companies.map((company) => {
                                    return (
                                        <MenuItem value={company.companyId}>{company.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
				</Grid>

                <Grid container alignItems='center' justifyContent='center'>
                    {lead.companyId &&
                        <Grid item xs={10} style={{textAlign: 'center', margin: '1rem'}}>
                            <Box class='warning-text'>Updating details for a lead after it has been allocated to a company will not update the lead in that company's CRM. This can be done from company's board in the 'Lead Boards' screen.</Box>
                        </Grid>
                    }
                    {lead.notes.length > 0 && lead.notes[0].category === 'Voicemail' &&
                        <Grid item xs={10} style={{textAlign: 'center', margin: '1rem'}}>
                            <Voicemail 
                                vmKey={lead.notes[0].note}
                                product={lead.product}
                            />
                        </Grid>
                    }
                    <Grid item xs={10}>
                        <Grid container>
                            <Grid item xs={12}>
                                {lead.product === 'income-protection' &&
                                    <IPQuoteDetails
                                        lead={lead}
                                        updateLead={(details) => updateLead(details)}
                                        showQuoteContent={showQuoteContent}
                                        admin
                                    />
                                }
                                {lead.product === 'mortgage-protection' &&
                                    <MPQuoteDetails
                                        lead={lead}
                                        updateLead={(details) => updateLead(details)}
                                        showQuoteContent={showQuoteContent}
                                        admin
                                    />
                                }
                                {lead.product === 'life-insurance' &&
                                    <LIQuoteDetails
                                        lead={lead}
                                        updateLead={(details) => updateLead(details)}
                                        showQuoteContent={showQuoteContent}
                                        admin
                                    />
                                }
                                {lead.product === 'health-insurance' &&
                                    <HealthQuoteDetails
                                        lead={lead}
                                        updateLead={(details) => updateLead(details)}
                                        showQuoteContent={showQuoteContent}
                                        admin
                                    />
                                }
                                {lead.product === 'pensions' &&
                                    <PENQuoteDetails
                                        lead={lead}
                                        updateLead={(details) => updateLead(details)}
                                        showQuoteContent={showQuoteContent}
                                        admin
                                    />
                                }
                                {lead.product === 'home-insurance' &&
                                    <HIQuoteDetails
                                        lead={lead}
                                        updateLead={(details) => updateLead(details)}
                                        showQuoteContent={showQuoteContent}
                                        admin
                                    />
                                }
                                {lead.product === 'business-insurance' &&
                                    <BIQuoteDetails
                                        lead={lead}
                                        updateLead={(details) => updateLead(details)}
                                        showQuoteContent={showQuoteContent}
                                        admin
                                    />
                                }
                                {lead.product === 'gadget-insurance' &&
                                    <GadgetQuoteDetails
                                        lead={lead}
                                        updateLead={(details) => updateLead(details)}
                                        showQuoteContent={showQuoteContent}
                                        admin
                                    />
                                }
                                {lead.product === 'pet-insurance' &&
                                    <PetQuoteDetails
                                        lead={lead}
                                        updateLead={(details) => updateLead(details)}
                                        showQuoteContent={showQuoteContent}
                                        admin
                                    />
                                }
                                {lead.product === 'travel-insurance' &&
                                    <TravelQuoteDetails
                                        lead={lead}
                                        updateLead={(details) => updateLead(details)}
                                        showQuoteContent={showQuoteContent}
                                        admin
                                    />
                                }
                            </Grid>
                            <Grid item lg={12}>
                                <PersonalDetails 
                                    lead={lead}
                                    updateLead={(details) => updateLead(details)}
                                    admin
                                />
                            </Grid>
                            <Grid item lg={12}>
                                <Notes
                                    lead={lead}
                                    updateLead={(details) => updateLead(details)}
                                    admin
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
			</DialogContent>
			
            <DialogActions>
                <Button
                    onClick={() => props.close()}
                    color='primary'
                >
                    Close
                </Button>

                <Box marginLeft='auto!important'>
                    <Button
                        component={Box}
                        onClick={() => setShowRejectLead(true)}
                        color='primary'
                        variant='contained'
                    >
                        Reject Lead
                    </Button>
                </Box>
            </DialogActions>
            
            {showAlert &&
                <ReactBSAlert
                    warning
                    style={{ display: 'block', marginTop: '-100px' }}
                    title='Are you sure?'
                    onConfirm={() => allocateLead()}
                    onCancel={() => setShowAlert(false)}
                    cancelBtnCssClass='btn-secondary'
                    confirmBtnBsStyle='success'
                    cancelBtnText='Cancel'
                    confirmBtnText='Yes, Allocate Lead'
                    disabled={loading}
                    showCancel
                    btnSize=''
                >
                    This will allocate this lead to '{company.toUpperCase()}' and notify them that they have a new lead
                    <Box style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Box style={{marginTop: '2rem', marginBottom: '1rem', width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                            {!loading ? (
                                <>
                                    <FormControl variant='outlined' fullWidth direction='row'>
                                        <FormLabel>Assign to Broker</FormLabel>
                                        <Select
                                            multiple={false}
                                            IconComponent={KeyboardArrowDown}
                                            value={selectedBroker}
                                            onChange={(e) => setSelectedBroker(e.target.value)}
                                        >
                                            <MenuItem value={'unassigned'}>Auto Assign</MenuItem>
                                            {brokers.map((broker) => {
                                                return (<MenuItem value={broker.brokerId}>{broker.firstName + ' ' + broker.lastName}</MenuItem>)
                                            })}
                                        </Select>
                                    </FormControl>
                                    {lead.product === 'pensions' &&
                                        <>
                                            {pricing ? (
                                                <>
                                                    {pricing[lead.quote.currentValue] ? (
                                                        <p style={{marginTop: '1rem'}}>This pension lead has a current value of <strong>{lead.quote.currentValue}</strong> and is priced at <strong>€{pricing[lead.quote.currentValue].toFixed(2)}</strong> for <strong>{company.toUpperCase()}.</strong> <br/><br/>Allocating this lead will reduce the {company.toUpperCase()} balance automatically.</p>
                                                    ) : (
                                                        <p style={{marginTop: '1rem'}}>This pension lead does not have a valid current value and is priced as a general enquiry at <strong>€{pricing['General Enquiry (no value)'].toFixed(2)}</strong> for <strong>{company.toUpperCase()}.</strong> <br/><br/>Allocating this lead will reduce the {company.toUpperCase()} balance automatically.</p>
                                                    )}
                                                </>
                                            ) : (
                                                <p style={{marginTop: '1rem'}}>This company does not have balance delivery pricing configured.</p>
                                            )}
                                        </>
                                    }
                                </>
                            ) : (
                                <Loader height={12} />
                            )}
                        </Box>
                    </Box>
                </ReactBSAlert>
            }

            {showRejectLead &&
                <ReactBSAlert
                    warning
                    style={{ display: 'block', marginTop: '-100px' }}
                    title='Are you sure?'
                    onConfirm={() => rejectLead()}
                    onCancel={() => setShowRejectLead(false)}
                    cancelBtnCssClass='btn-secondary'
                    confirmBtnBsStyle='danger'
                    cancelBtnText='Cancel'
                    confirmBtnText='Yes, Reject Lead'
                    disabled={loading}
                    showCancel
                    btnSize=''
                >
                    This will move the lead to 'Rejected', they can still be allocated later
                </ReactBSAlert>
            }
		</Dialog>
	)
}