import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import CompaniesTable from 'components/admin/CompaniesTable'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'
import { Button } from 'aws-amplify-react'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function Companies() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const api = new AdminAPI()

    const [showStatus, setShowStatus] = useState('active')
    const [companies, setCompanies] = useState([])
    const [showCompanies, setShowCompanies] = useState([])
    const [xeroCode, setXeroCode] = useState(null)
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        getCompanies()
    }, [])

    async function getCompanies() {
        try {
            const companies = await api.getCompanies()
            console.log('companies: ', companies)

            companies.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })

            const activeCompanies = companies.filter((company) => (company.active))

            const code = new URLSearchParams(window.location.search).get(
                'code'
            );
            console.log('code: ', code)

            if (code) {
                console.log(window.location.href);
                await api.exchangeXeroCode(window.location.href);
            }

            setCompanies(companies)
            setShowCompanies(activeCompanies)
            setXeroCode(code)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function updateCompany(company) {
        const index = companies.findIndex(oldCompany => (oldCompany.companyId === company.companyId))
        console.log('company index: ', index)

        let newCompanies = [...companies]
        newCompanies[index] = company
        let checkStatus = showStatus === 'active' ? true : false
        let showCompanies = newCompanies.filter((company) => (company.active === checkStatus))
        console.log(showCompanies)
        setCompanies(newCompanies)
        setShowCompanies(showCompanies)
    }

    function updateShowStatus(showStatus) {
        let checkStatus = showStatus === 'active' ? true : false
        let showCompanies = companies.filter((company) => (company.active === checkStatus))
        setShowCompanies(showCompanies)
        setShowStatus(showStatus)
    }

    function showSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    function getXeroLink() {
        let clientId = 'C5A63D6619134B6499767448F739C445'
        let redirectUri = 'http://localhost:3000/companies'
        return 'https://login.xero.com/identity/connect/authorize?response_type=code&client_id=' + clientId + '&redirect_uri=' + redirectUri + '&scope=openid profile email accounting.transactions offline_access&state=123'
    }

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='-6rem'
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid
                                container
                                component={Box}
                                alignItems='center'
                                justifyContent='flex-start'
                                style={{marginBottom: '2rem'}}
                                direction='row'
                            >
                                <Grid item xs={12} md={2}>
                                    <FormGroup style={{marginBottom: '0'}}>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                value={showStatus}
                                                onChange={(e) => updateShowStatus(e.target.value)}
                                            >
                                                <MenuItem value='active'>Active</MenuItem>
                                                <MenuItem value='inactive'>Inactive</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                {/*
                                <Grid item xs={2}>
                                    <a href={getXeroLink()}>Link Xero</a>
                                </Grid>
                                */}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <CompaniesTable
                                companies={showCompanies}
                                updateCompany={(company) => updateCompany(company)}
                                refreshCompanies={() => getCompanies()}
                                showSuccessAlert={(text) => showSuccessAlert(text)}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default Companies