const componentStyles = (theme) => ({
  // header: {
  //   background:
  //     "linear-gradient(87deg," + theme.palette.info.main + ",#1171ef)",
  //   [theme.breakpoints.up("md")]: {
  //     paddingTop: "10rem",
  //   },
  // },
  header: {
    background:
      "transparent",
    [theme.breakpoints.up("md")]: {
      paddingTop: "2rem",
    },
  },
});

export default componentStyles;
