import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory, { textFilter, selectFilter, numberFilter } from 'react-bootstrap-table2-filter'
import componentStyles from 'assets/theme/views/admin/react-bs-table.js'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

const useStyles = makeStyles(componentStyles)

function BrokerStatsTable(props, ref) {
    const classes = { ...useStyles() }
    const brokers = props.brokers
    const details = props.details

    brokers.sort((a, b) => {
        return b.policiesSigned - a.policiesSigned
    })

    return (
        <Card>
            <CardHeader
                subheader={
                    <>
                        <Box display='flex' style={{justifyContent: 'space-between'}}>
                            <Box>
                                <Box
                                    component={Typography}
                                    variant='h2'
                                    marginBottom='0!important'
                                >
                                    <Box component='span'>Sales Leaderboard ({props.title})</Box>
                                </Box>
                            </Box>
                        </Box>
                    </>
                }
                classes={{ root: classes.cardHeaderRoot }}
            ></CardHeader>

            <div className='table-container'>
                <BootstrapTable 
                    keyField='brokerName' 
                    data={ brokers } 
                    columns={ 
                        [
                            {
                                dataField: 'brokerName',
                                text: 'Name',
                                //sort: true,
                                //filter: textFilter(),
                                formatter: (cell, row) => {
                                    if ((row.brokerId === details.brokerId) || details.permissions === 'admin') {
                                        return (<Box>{row.firstName + ' ' + row.lastName}</Box>)
                                    }
                                    else {
                                        return (<Box style={{filter: 'blur(5px)'}}>xxxxx xxxxx</Box>)
                                    }
                                }
                            },
                            {
                                dataField: 'leadsAssigned',
                                text: 'Leads',
                                sort: true,
                                //filter: numberFilter(),
                                formatter: (cell, row) => {
                                    return <>{row.leadsAssigned}</>
                                }
                            },
                            {
                                dataField: 'meetings',
                                text: 'Meetings',
                                sort: true,
                                //filter: numberFilter(),
                                formatter: (cell, row) => {
                                    return <>{row.meetings}</>
                                }
                            },
                            {
                                dataField: 'closedSales',
                                text: 'Won',
                                sort: true,
                                formatter: (cell, row) => {
                                    return <>{row.closedSales}</>
                                },
                            },
                            {
                                dataField: 'policiesSigned',
                                text: props.product === 'pensions' ? 'Transferred (€)' : 'Annual Premiums (€)',
                                sort: true,
                                formatter: (cell, row) => {
                                    return (
                                        <Box display='flex' flexDirection='row' alignItems='center'>
                                            <Box>{(row.premiums ? '€' + row.premiums.toFixed(2) : '0')}</Box>
                                            {/*
                                            <Box
                                                component={ArrowUpward}
                                                width='1rem!important'
                                                height='1rem!important'
                                                marginLeft='0.5rem'
                                                color={theme.palette.success.main}
                                            />
                                            <Box color={theme.palette.success.main}>3.2%</Box>
                                            */}
                                        </Box>
                                    )
                                }
                            }
                        ]
                    } 
                    filter={ filterFactory() } 
                    noDataIndication={<Box>No broker data</Box>}
                />
            </div>
        </Card>
    );
}

export default BrokerStatsTable