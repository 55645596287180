import React from 'react'
import Chart from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import { chartOptions, parseOptions } from 'config/constants/ChartVariables'
import { pensionTypes } from 'config/constants/Constants'

import componentStyles from 'assets/theme/components/cards/charts/card-total-orders.js'

const useStyles = makeStyles(componentStyles)

const themeColors = require('assets/theme/colors.js').default

var colors = {
    gray: themeColors.gray,
    theme: {
        default: themeColors.dark.main,
        primary: themeColors.primary.main,
        secondary: themeColors.secondary.main,
        info: themeColors.info.main,
        success: themeColors.success.main,
        danger: themeColors.error.main,
        warning: themeColors.warning.main,
    },
    black: themeColors.black.light,
    white: themeColors.white.main,
    transparent: themeColors.transparent.main,
}

const options = {
    scales: {
      yAxes: [
            {
                gridLines: {
                    color: colors.gray[200],
                    zeroLineColor: colors.gray[200],
                },
                ticks: {},
            },
        ],
    },
}

function PensionTypeBar(props) {
    const classes = useStyles()
    const theme = useTheme()
    if (window.Chart) {
        parseOptions(Chart, chartOptions())
    }

    let types = {
        'Untyped': 0
    }

    for (let type of pensionTypes) {
        types[type] = 0
    }
    //console.log('types: ', types)

    for (let lead of props.leads) {
        let buyerIndex = props.allBuyers.findIndex((buyer) => buyer.companyId === lead.companyId)
        let returnsIndex = props.returns.findIndex((request) => (request.product === lead.product && request.phone === lead.phone))
        if (returnsIndex === -1) {
            if (buyerIndex > -1) {
                let buyer = props.allBuyers[buyerIndex]
                if (buyer.companyId !== 'bq') {
                    let price = buyer.pricing ? buyer.pricing[lead.quote.currentValue] : buyer.leadPrice
                    if (price === undefined) {
                        price = 0
                    }
                    if (pensionTypes.indexOf(lead.quote.type) > -1) {
                        types[lead.quote.type] += price
                    }
                    else {
                        types['Untyped'] += price
                    }
                }
            }
        }
    }
    //console.log('types: ', types)

    let labels = []
    let values = []

    for (let type of Object.keys(types)) {
        //console.log(type)
        if (types[type] > 0) {
            labels.push(type)
            values.push(types[type])
        }
    }
    // console.log('labels: ', labels)
    // console.log('values: ', values)

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Income (€)',
                data: values,
                maxBarThickness: 10,
                backgroundColor: '#015f63'
            },
        ]
    }
  
    return (
        <>
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    title={
                        <Box component='span' color={theme.palette.gray[600]}>
                            
                        </Box>
                    }
                    subheader='Income by Type (€)'
                    classes={{ root: classes.cardHeaderRoot }}
                    titleTypographyProps={{
                        component: Box,
                        variant: 'h2',
                        letterSpacing: '2px',
                        marginBottom: '0!important',
                        classes: {
                            root: classes.textUppercase,
                        },
                    }}
                    subheaderTypographyProps={{
                        component: Box,
                        variant: 'h3',
                        marginBottom: '0!important',
                        color: 'initial',
                    }}
                ></CardHeader>
                
                <CardContent>
                    <Box position='relative' height='350px'>
                        <Bar data={data} options={options} />
                    </Box>
                </CardContent>

                <Box
                    fontSize='12px'
                    color={theme.palette.gray[600]}
                    marginBottom='1rem'
                    marginLeft='1rem'
                    //marginTop='2rem'
                    textAlign='left'
                    //className='online-discount'
                >
                    <Box component='small' fontSize='100%'>
                        * Revenue charts include accepted leads only, rejections and returns are not counted
                    </Box>
                </Box>
            </Card>
        </>
    )
}

export default PensionTypeBar
