import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Grid from '@material-ui/core/Grid'
import AddLeadModal from 'components/broker/AddLeadModal'
import LeadModal from 'components/broker/LeadModal'
import PipelineFilter from 'components/broker/PipelineFilter'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter'
import { getPipelineCount, getProductString } from 'config/helpers/Helpers'
//import { getProductString } from 'config/helpers/Helpers'
import { leadPipelineStages, pipelineStageColors }  from 'config/constants/Constants'
import { NotificationManager } from 'react-notifications'
import componentStyles from 'assets/theme/views/admin/react-bs-table.js'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

const useStyles = makeStyles(componentStyles)

const styles = {
	root: {
	  display: "flex",
	  "&$selected": {
		display: "flex"
	  }
	},
	//selected: {}
};

function LeadsTable(props) {
    const bstable: any = React.useRef()
    const classes = { ...useStyles() }
    const history = useHistory()
    const allLeads = props.allLeads
    const leads = props.leads
    const details = props.details
    const product = props.product
    const companyId = props.companyId
    
    const [lead, setLead] = useState(null)
    const [showLeadModal, setShowLeadModal] = useState(false)
    const [showAddLeadModal, setShowAddLeadModal] = useState(false)
    const [selected, setSelected] = useState([])
    const [alert, setAlert] = useState(false)
    //const [pipelineCount, setPipelineCount] = useState(props.pipelineCount || {})

    useEffect(() => {
        checkHistory()
    }, [])

    function checkHistory() {
        if (history.location.state && history.location.state.openModal && product !== 'general') {
            console.log('find lead and open modal')
            let product = history.location.state.product
            let phone = history.location.state.phone
            console.log('product: ', product)
            console.log('phone: ', phone)
    
            const associatedWith = allLeads.find((e) => (e.product == product && e.phone === phone));
            console.log('found lead: ', associatedWith)

            if (associatedWith) {
                openLeadModal(associatedWith)
                props.updateStage(associatedWith.stage)
            }
        }
        else {
            console.log('no history')
        }
    }

    function selectLead(lead) {
        let newSelected = [...selected]
        newSelected.push(lead)
        setSelected(newSelected)
    }

    function deselectLead(lead) {
        let newSelected = [...selected]
        let index = selected.findIndex(item => item.phone === lead.phone)
        newSelected.splice(index, 1)
        setSelected(newSelected)
    }

    function updateStageMultiple(stage) {
        if (stage === 'won') {
            NotificationManager.error('Leads must be moved to the Won stage individually as a monthly premium is required.')
        }
        else if (stage === 'lost') {
            NotificationManager.error('Leads must be moved to the Lost stage individually as a reason is required.')
        }
        else {
            bstable.current.selectionContext.selected = []
            //console.log(bstable)
            props.updateStageMultiple(stage, selected)
            setSelected([])
            setAlert(null)
        }
    }

    function updateBrokerMultiple(broker) {
        bstable.current.selectionContext.selected = []
        //console.log(bstable)
        props.updateBrokerMultiple(broker, selected)
        setSelected([])
        setAlert(null)
    }

    function deleteLeadsMultiple() {
        bstable.current.selectionContext.selected = []
        //console.log(bstable)
        props.deleteLeadsMultiple(selected)
        setSelected([])
        setAlert(null)
    }

    function showBulkStageWarning(stage) {
        setAlert(
            <ReactBSAlert
                warning
                style={{ display: 'block', marginTop: '-100px' }}
                title='Are you sure?'
                onConfirm={() => updateStageMultiple(stage)}
                onCancel={() => setAlert(false)}
                cancelBtnCssClass='btn-secondary'
                confirmBtnBsStyle='success'
                cancelBtnText='Cancel'
                confirmBtnText='Yes, Update Stage'
                showCancel
                btnSize=''
            >
                This will move {selected.length} leads to stage '{leadPipelineStages[stage]}'
            </ReactBSAlert>
        )
    }

    function showBulkBrokerWarning(brokerId) {
        let broker = props.brokers.find(broker => broker.brokerId === brokerId)
        let brokerName = broker.firstName + ' ' + broker.lastName
        setAlert(
            <ReactBSAlert
                warning
                style={{ display: 'block', marginTop: '-100px' }}
                title='Are you sure?'
                onConfirm={() => updateBrokerMultiple(brokerId)}
                onCancel={() => setAlert(false)}
                cancelBtnCssClass='btn-secondary'
                confirmBtnBsStyle='success'
                cancelBtnText='Cancel'
                confirmBtnText='Yes, Update Broker'
                showCancel
                btnSize=''
            >
                This will reassign {selected.length} leads to broker '{brokerName}'
            </ReactBSAlert>
        )
    }

    function showDeleteLeadsWarning() {
        setAlert(
            <ReactBSAlert
                warning
                style={{ display: 'block', marginTop: '-100px' }}
                title='Are you sure?'
                onConfirm={() => deleteLeadsMultiple()}
                onCancel={() => setAlert(false)}
                cancelBtnCssClass='btn-secondary'
                confirmBtnBsStyle='success'
                cancelBtnText='Cancel'
                confirmBtnText='Yes, Delete Leads'
                showCancel
                btnSize=''
            >
                This will delete {selected.length} leads permanently
            </ReactBSAlert>
        )
    }

    const selectRow = {
        mode: 'checkbox',
        //clickToSelect: true,
        //selected: [this.state.jobOrderValue],
        //clickToExpand: true,
        selectColumnStyle: { textAlign: 'center', margin: 'auto', width: '50px' },
        onSelect: (row, isSelect, selected, e) => {
            //this.handleOnSelect(row, isSelect, selected, e);
            // console.log('selected')
            // console.log(row)
            // console.log(isSelect)
            // console.log(selected)
            if (isSelect) {
                selectLead(row)
            }
            else {
                deselectLead(row)
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            if (isSelect) {
                setSelected(rows)
            }
            else {
                setSelected([])
            }
        }
    }

    const columns = [
        {
            dataField: 'firstName',
            text: 'Name',
            //sort: true,
            filter: textFilter(),
            formatter: (cell, row) => {
                if (!row.brokerId || (row.brokerId === details.brokerId) || details.permissions === 'admin') {
                    return (<Box className='hover-text primary-text' onClick={() => openLeadModal(row)}>{row.firstName + ' ' + (row.lastName ? row.lastName : '')}</Box>)
                }
                else {
                    return (<Box>{row.firstName + ' ' + row.lastName}</Box>)
                }
            },
            headerFormatter: (col, colIndex, components) => {
                return components.filterElement
            }
        },
        {
            dataField: 'brokerName',
            text: 'Broker',
            sort: true,
            // filter: selectFilter({
            //     placeholder: 'All',
            //     options: props.brokers.map(broker => {return {value: broker.firstName + ' ' + broker.lastName, label: broker.firstName + ' ' + broker.lastName}}),
            //     //onFilter: (filterValue) => updatePipelineCount(filterValue)
            // }),
            formatter: (cell, row) => {
                return <>{row.brokerName || '-'}</>
            },
            // headerFormatter: (col, colIndex, components) => {
            //     return components.filterElement
            // }
        },
        {
            dataField: 'phone',
            text: 'Phone',
            sort: true,
            formatter: (cell, row) => {
                if ((row.brokerId === details.brokerId) || details.permissions === 'admin') {
                    return (<Box>{row.phone}</Box>)
                }
                else {
                    return (<Box style={{filter: 'blur(5px)'}}>+353xxxxxxxxx</Box>)
                }
            },
        },
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
            formatter: (cell, row) => {
                if ((row.brokerId === details.brokerId) || details.permissions === 'admin') {
                    return (<Box>{row.email ? row.email : '-'}</Box>)
                }
                else {
                    return (<Box style={{filter: 'blur(5px)'}}>xxxxx@xxxx.xxx</Box>)
                }
            },
        },
        {
            dataField: 'premium',
            text: companyId === 'cib' ? 'Income/Commission' : 'Monthly Premium',
            sort: true,
            formatter: (cell, row) => {
                //console.log(row)
                return <Box>{row.quote.premium ? '€' + row.quote.premium : '-'}</Box>
            },
        },
        {
            dataField: 'daysInStage',
            text: 'In Stage',
            sort: true,
            formatter: (cell, row) => {
                //return <>{moment(row.createdAt).format('DD/MM/YYYY HH:mm')}</>
                //return <>{moment().diff(row.createdAt, 'days')} days ago</>
                //console.log('activity: ', row.activity)
                let day = row.createdAt
                
                for (var item of row.activity) {
                    if (item.category === 'stage-updated') {
                        day = item.timestamp
                    }
                }
                //console.log('day: ', day)
                return <>{moment(day).fromNow()}</>
            }
        },
        {
            dataField: 'createdAt',
            text: 'Created',
            sort: true,
            formatter: (cell, row) => {
                //return <>{moment(row.createdAt).format('DD/MM/YYYY HH:mm')}</>
                //return <>{moment().diff(row.createdAt, 'days')} days ago</>
                return <>{moment(row.createdAt).fromNow()}</>
            }
        }
    ]

    // change premium to value if pensions
    if (product === 'pensions') {
        let index = 4
        columns[index] = {
            dataField: 'currentValue',
            text: 'Pension Value',
            sort: true,
            formatter: (cell, row) => {
                return <>{row.quote.currentValue ? (row.quote.currentValue.includes('€') ? row.quote.currentValue : '€' + row.quote.currentValue)  : '-'}</>
            }
        }
    }

    // add type if general
    // if (product === 'general') {
    //     columns.splice(columns.length - 6, 0, {
    //         dataField: 'type',
    //         text: 'Type',
    //         sort: true,
    //         formatter: (cell, row) => {
    //             return <>{row.quote.type || '-'}</>
    //         }
    //     })
    // }

    // add lead source if admin
    if (details.permissions === 'admin') {
        columns.splice(columns.length - 2, 0, {
            dataField: 'source',
            text: 'Source',
            sort: true,
            formatter: (cell, row) => {
                return <>{row.source || '-'}</>
            }
        })
    }

    function openLeadModal(lead) {
        // only allow opening of unclaimed and owned leads
        if (details.permissions === 'admin') {
            setLead(lead)
            setShowLeadModal(true)
        }
        else {
            if (lead.brokerId === details.brokerId) {
                setLead(lead)
                setShowLeadModal(true)
            }
        }
    }

    function closeLeadModal() {
        setShowLeadModal(false)
        setLead(null)
    }
    
    return (
        <Card>
            {alert}
            <div className='table-container'>
                <CardHeader
                    subheader={
                        <>
                            <Box display='flex' style={{justifyContent: 'space-between'}}>
                                <Box>
                                    <Box
                                        component={Typography}
                                        variant='h2'
                                        marginBottom='0!important'
                                    >
                                        <Box component='span'>{getProductString(product)}</Box>
                                    </Box>
                                </Box>
                                {(product !== 'general' && companyId !== 'cib') &&
                                    <Box>
                                        <Button
                                            component={Box}
                                            onClick={() => setShowAddLeadModal(true)}
                                            color='primary'
                                            variant='contained'
                                            size='small'
                                            disabled={props.admin}
                                        >
                                            Add Lead
                                        </Button>
                                    </Box>
                                }
                            </Box>
                            <Box>
                                <PipelineFilter 
                                    pipelineCount={props.pipelineCount}
                                    updateStage={(stage) => props.updateStage(stage)}
                                    stage={props.stage}
                                    type='leads'
                                />
                            </Box>
                        </>
                    }
                    classes={{ root: classes.cardHeaderRoot }}
                ></CardHeader>

                <BootstrapTable 
                    ref={bstable}
                    keyField='phone' 
                    data={ leads }
                    columns={ columns }
                    selectRow={ selectRow }
                    filter={ filterFactory() } 
                    noDataIndication={<Box>No leads</Box>}
                    className='custom-table'
                />
            </div>
            
            <Grid container>
                {selected.length > 0 &&
                <>
                    <Grid item xs={3} style={{margin: '0.5rem'}}>
                        <FormControl variant='outlined' fullWidth direction='row'>
                            <FormLabel>Update Stage ({selected.length})</FormLabel>
                            <Select
                                multiple={false}
                                IconComponent={KeyboardArrowDown}
                                value={props.stage}
                                onChange={(e) => showBulkStageWarning(e.target.value)}
                                className='stage-select'
                            >
                                {Object.keys(leadPipelineStages).map((key) => {
                                    return (
                                        <MenuItem 
                                            value={key}
                                            classes={styles}
                                        >
                                            <Box className='stage-color' style={{backgroundColor: pipelineStageColors[key]}}></Box> 
                                            <Box>{leadPipelineStages[key]}</Box>
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    {details.permissions === 'admin' &&
                        <Grid item xs={3} style={{margin: '0.5rem'}}>
                            <FormControl variant='outlined' fullWidth direction='row'>
                                <FormLabel>Update Broker ({selected.length})</FormLabel>
                                <Select
                                    multiple={false}
                                    IconComponent={KeyboardArrowDown}
                                    value={props.broker}
                                    onChange={(e) => showBulkBrokerWarning(e.target.value)}
                                    className='stage-select'
                                >
                                    {props.brokers.map((broker, i) => {
                                        return (<MenuItem value={broker.brokerId} key={i}>{broker.firstName + ' ' + broker.lastName}</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    }
                    {props.admin &&
                        <Grid item xs={3} style={{margin: '0.5rem'}}>
                            <FormControl variant='outlined' fullWidth direction='row'>
                                <FormLabel>Delete Leads ({selected.length})</FormLabel>
                                <Box>
                                    <Button
                                        component={Box}
                                        onClick={() => showDeleteLeadsWarning(true)}
                                        color='primary'
                                        variant='contained'
                                        size='small'
                                    >
                                        Delete Permanently
                                    </Button>
                                </Box>
                            </FormControl>
                        </Grid>
                    }
                </>
                }
            </Grid>

            {showAddLeadModal &&
                <AddLeadModal 
                    isOpen={showAddLeadModal}
                    close={() => setShowAddLeadModal(false)}
                    details={details}
                    brokers={props.brokers}
                    showSuccessAlert={(text) => props.showSuccessAlert(text)}
                    refreshLeads={() => props.refreshLeads()}
                    product={product}
                />
            }

            {showLeadModal &&
                <LeadModal 
                    isOpen={showLeadModal}
                    close={() => closeLeadModal()}
                    lead={lead}
                    details={details}
                    brokers={props.brokers}
                    //claimLead={(lead) => props.claimLead(lead)}
                    updateLead={(lead) => props.updateLead(lead)}
                    removeLead={(lead, product) => props.removeLead(lead, product)}
                    //product={product}
                    companyId={props.companyId}
                    admin={props.admin}
                />
            }
        </Card>
    );
}

export default LeadsTable