import React, { useContext } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
import DirectionsRun from "@material-ui/icons/DirectionsRun";
//import EventNote from "@material-ui/icons/EventNote";
//import LiveHelp from "@material-ui/icons/LiveHelp";
import Person from "@material-ui/icons/Person";
import Lock from "@material-ui/icons/Lock";
//import Settings from "@material-ui/icons/Settings";
import { Auth } from "aws-amplify";
import { useHistory } from 'react-router-dom'
import { onError } from 'config/lib/errorLib'
import ChangePasswordModal from 'components/general/ChangePasswordModal'

//import DetailsContext from 'config/context/DetailsContext'

// core components
import componentStyles from "assets/theme/components/dropdowns/user-dropdown-alternative.js";

const useStyles = makeStyles(componentStyles);

export default function UserDropdown(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [showChangePassword, setShowChangePassword] = React.useState(false)

    const history = useHistory()

    //const details = useContext(DetailsContext);
    //console.log(details)

    //const fullName = details.firstName + ' ' + details.lastName
    const fullName = props.name
    const email = props.email

    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    function goToProfile() {
        handleMenuClose()
        history.push('/profile')
    }

    function logout() {
        Auth.signOut().then((user) => {
            //window.location.replace('/');
            //this.props.history.push('/')
            console.log(user)
        }).catch(err => 
            onError(err)
        )
    }

    const menuId = "dropdowns-user-dropdown-id";
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {/*details.accountType === 'client' &&
                <Box
                    display="flex!important"
                    alignItems="center!important"
                    component={MenuItem}
                    onClick={() => goToProfile()}
                >
                    <Box
                        component={Person}
                        width="1.25rem!important"
                        height="1.25rem!important"
                        marginRight="1rem"
                    />
                    <span>Profile</span>
                </Box>
            */}
            {email && 
                <Box style={{textAlign: 'center'}}>
                    <span>{email}</span>
                </Box>
            }
            
            <Divider component="div" classes={{ root: classes.dividerRoot }} />

            <Box
                display="flex!important"
                alignItems="center!important"
                component={MenuItem}
                onClick={() => setShowChangePassword(true)}
            >
                <Box
                    component={Lock}
                    width="1.25rem!important"
                    height="1.25rem!important"
                    marginRight="1rem"
                />
                <span>Change Password</span>
            </Box>
            
            <Box
                display="flex!important"
                alignItems="center!important"
                component={MenuItem}
                onClick={() => logout()}
            >
                <Box
                  component={DirectionsRun}
                  width="1.25rem!important"
                  height="1.25rem!important"
                  marginRight="1rem"
                />
                <span>Logout</span>
            </Box>
        </Menu>
    );

    return (
        <>
            <Button
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                classes={{
                    label: classes.buttonLabel,
                    root: classes.buttonRoot,
                }}
            >
                <Avatar
                    alt={fullName}
                    //src={require("assets/img/theme/team-4-800x800.jpg").default}
                    classes={{
                        root: classes.avatarRoot,
                    }}
                >
                    {fullName.slice(0,1)}
                </Avatar>
                <Hidden mdDown>{fullName}</Hidden>
            </Button>
            {renderMenu}

            {showChangePassword &&
                <ChangePasswordModal
                    isOpen={showChangePassword}
                    close={() => setShowChangePassword(false)}
                />
            }
        </>
    );
}
