import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import PhoneInput from 'components/general/PhoneInput'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'

import DetailsContext from 'config/context/DetailsContext'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function AddCampaignModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	//const theme = useTheme()
  	const api = new AdminAPI()

    const [name, setName] = useState('')
	const [product, setProduct] = useState('')
    const [target, setTarget] = useState(0)

  	async function addCampaign() {
  		try {   
            let campaign = await api.addCampaign(name, product, target)
            console.log('campaign: ', campaign)

  			props.refreshCampaigns()
  			props.showSuccessAlert('Campaign added!')
  			props.close()
  		} catch(e) {
  			onError(e)
  		}
  	}

  	function checkFields() {
  		if (name.length > 0 && product.length > 0 && target.length > 0) {
  			return false
  		}
  		else {
  			return true
  		}
  	}

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Add Campaign
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>

			<DialogContent>
				<FormGroup>
                  	<FormLabel>Name</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
						value={name}
                    	onChange={(e) => setName(e.target.value)}
                  	/>
                </FormGroup>
				<FormGroup>
                  	<FormLabel>Product</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
						value={product}
                    	onChange={(e) => setProduct(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Weekly Target (€)</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
						value={target}
                    	onChange={(e) => setTarget(e.target.value)}
                  	/>
                </FormGroup>
			</DialogContent>
			
			<DialogActions>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Cancel
				</Button>
			
				<Button
					component={Box}
					onClick={() => addCampaign()}
					color='primary'
					marginLeft='auto!important'
					variant='contained'
					disabled={checkFields()}
				>
					Add
				</Button>
			</DialogActions>
		</Dialog>
	)
}