import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'

import DetailsContext from 'config/context/DetailsContext'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function AddValueModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
    const api = new BrokerAPI()
    const companyDetails = useContext(DetailsContext)
	const companyId = companyDetails.companyId || props.companyId

    const lead = props.lead

  	const [currentValue, setCurrentValue] = useState(lead.quote.currentValue || '')

  	async function addCurentValue() {
        try {
            let result = await api.updatePensionValue(companyId, lead.product, lead.phone, currentValue)
            console.log(result)

			props.updateLead(result)
            props.updateStage('won', result, true)
            props.close()
		} catch(e) {
			onError(e)
		}
  	}

	// function updatePremium(premium) {
    //     let formatted = premium.replace(/€/g, '')
    //     setPremium(formatted)
    // }

  	function checkCurrentValue() {
  		if (currentValue.length > 0) {
  			return false
  		}
  		else {
  			return true
  		}
  	}

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
            maxWidth='sm'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Confirm Value at Transfer
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
			
			<DialogContent>
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <FormGroup>
                            <FormLabel>Current Value (€)</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                                autoFocus
                                //marginBottom='1rem!important'
                            >
                                <OutlinedInput
                                    fullWidth
                                    type='number'
                                    value={currentValue}
                                    onChange={(e) => setCurrentValue(e.target.value)}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
			</DialogContent>
			
			<DialogActions>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Cancel
				</Button>
				
				<Button
					component={Box}
					onClick={() => addCurentValue()}
					color='primary'
					marginLeft='auto!important'
					variant='contained'
					disabled={checkCurrentValue()}
				>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	)
}