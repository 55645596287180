import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { NotificationManager } from 'react-notifications'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'
import { timeOptions, reminderOptions, durationOptions } from 'config/constants/Constants'
import DetailsContext from 'config/context/DetailsContext'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

function Tasks(props) {
    const api = new BrokerAPI()
    const companyDetails = useContext(DetailsContext)
	const companyId = companyDetails.companyId
    
    const lead = props.lead
    const tasks = props.tasks ? props.tasks.sort((a, b) => {return Date.parse(a.date) - Date.parse(b.date)}) : []

	const [taskTitle, setTaskTitle] = useState('')
	const [taskDate, setTaskDate] = useState(moment().add(1, 'days').format('YYYY-MM-DD'))
	const [taskTime, setTaskTime] = useState('09:00')
    const [taskDuration, setTaskDuration] = useState('hour1')
	const [taskReminder, setTaskReminder] = useState('minutes15')
	const [taskNote, setTaskNote] = useState('')

    async function addTask() {
		console.log('add task...')
		let name = lead.firstName + ' ' + lead.lastName
		let date = new Date(taskDate + ' ' + taskTime)
        
        if (lead.brokerId) {
            try {
                //let result = lead.product === 'general' ? await api.addGeneralTask(companyId, lead.date, lead.createdAt, lead.product, lead.brokerId, lead.phone, name, taskTitle, date, taskDuration, taskReminder, taskNote)  : await api.addLeadTask(companyId, lead.product, lead.brokerId, lead.phone, name, taskTitle, date, taskDuration, taskReminder, taskNote)
                let result = await api.addLeadTask(companyId, lead.product, lead.brokerId, lead.phone, name, taskTitle, date, taskDuration, taskReminder, taskNote)
                console.log(result)

                let newTasks = [...tasks]
                newTasks.push(result)

                newTasks.sort((a, b) => {
                    return Date.parse(a.date) - Date.parse(b.date)
                })
                
                await props.updateTasks(newTasks)
                setTaskTitle('')
                setTaskDate(Date.now())
                setTaskReminder('atTime')
                setTaskDuration('hour1')
                setTaskNote('')
                props.openActivity()
                props.hide()
                NotificationManager.success('Task added')
            } catch(e) {
                onError(e)
            }
        }
        else {
            NotificationManager.warning('Please assign a broker in order to create a task.')
        }
	}

	function checkTaskFields() {
		if (taskDate.length > 0 && taskTime.length > 0) {
			return false
		}
		else {
			return true
		}
	}
    
    return (
        <Grid container>
            <Grid item xs={12} lg={4}>
                <FormGroup>
                    <FormLabel>Title</FormLabel>
                    <FormControl
                        variant='filled'
                        component={Box}
                        width='100%'
                    >
                        <OutlinedInput
                            fullWidth
                            type='text'
                            value={taskTitle}
                            onChange={(e) => setTaskTitle(e.target.value)}
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Date</FormLabel>
                    <FormControl
                        variant='filled'
                        component={Box}
                        width='100%'
                    >
                        <OutlinedInput
                            fullWidth
                            type='date'
                            value={taskDate}
                            onChange={(e) => setTaskDate(e.target.value)}
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Time</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                      		multiple={false}
                      		IconComponent={KeyboardArrowDown}
                      		value={taskTime}
                      		onChange={(e) => setTaskTime(e.target.value)}
                    	>
                            {timeOptions.map((option, i) => {
                                return (<MenuItem value={option} key={i}>{option}</MenuItem>)
                            })}
                    	</Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Duration</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                      		multiple={false}
                      		IconComponent={KeyboardArrowDown}
                      		value={taskDuration}
                      		onChange={(e) => setTaskDuration(e.target.value)}
                    	>
                            {Object.keys(durationOptions).map((key) => {
                                return (<MenuItem value={key}>{durationOptions[key]}</MenuItem>)

                            })}
                    	</Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Reminder</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            IconComponent={KeyboardArrowDown}
                            value={taskReminder}
                            onChange={(e) => setTaskReminder(e.target.value)}
                        >
                            {Object.keys(reminderOptions).map((key) => {
                                return (<MenuItem value={key}>{reminderOptions[key]}</MenuItem>)

                            })}
                        </Select>
                    </FormControl>
                </FormGroup>
            </Grid>
            <Grid item xs={12} lg={8}>
                <FormGroup>
                    <FormLabel>Note</FormLabel>
                    <FormControl
                        variant='filled'
                        component={Box}
                        width='100%'
                        //marginBottom='1rem!important'
                    >
                        <OutlinedInput
                            fullWidth
                            type='text'
                            multiline
                            minRows={5}
                            value={taskNote}
                            onChange={(e) => setTaskNote(e.target.value)}
                        />
                    </FormControl>
                </FormGroup>
            </Grid>
            <Grid item xs={12}>
                <Box display='flex' justifyContent='flex-end'>
                    <Button
                        onClick={() => props.hide()}
                        color='primary'
                    >
                        Cancel
                    </Button>
                    <Button
                        component={Box}
                        onClick={() => addTask()}
                        color='primary'
                        style={{marginLeft: '1rem'}}
                        variant='contained'
                        disabled={checkTaskFields()}
                    >
                        Save
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Tasks