import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Button from '@material-ui/core/Button'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'

import DetailsContext from 'config/context/DetailsContext'

function EnquiryForm(props) {
    const api = new BrokerAPI()
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId

    const details = props.details

    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')

    const [alert, setAlert] = useState(false)

    async function submitEnquiry() {
        const enquiry = {
            name: details.firstName + ' ' + details.lastName,
            companyName: companyDetails.companyId,
            email: details.email,
            phone: details.phone,
            subject: subject,
            message: message
        }

        try {
            await api.submitEnquiry(companyId, enquiry)
            openSuccessAlert('Your support ticket has been submitted. A member of our team will contact you shortly.')
        } catch(e) {
            onError(e)
        }

        setSubject('')
        setMessage('')
    }

    function checkFormFields() {
        if (subject && message.length > 0) {
            return false
        }
        else {
            return true
        }
    }

    function openSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }


    return (
        <>
            {alert}
            <FormGroup>
                <FormLabel>Subject</FormLabel>
                <OutlinedInput
                    fullWidth
                    type='text'
                    placeholder='Problem with...'
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <FormLabel>Message</FormLabel>
                <OutlinedInput
                    fullWidth
                    multiline
                    rows='5'
                    type='text'
                    placeholder='I am having issues when...'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
            </FormGroup>
            <Button
                component={Box}
                color='primary'
                marginLeft='auto!important'
                variant='contained'
                onClick={() => submitEnquiry()}
                disabled={checkFormFields()}
            >
                Submit
            </Button>
        </>
    )
}

export default EnquiryForm