import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import BootstrapTable from 'react-bootstrap-table-next'
import Grid from '@material-ui/core/Grid'
import Loader from 'components/general/Loader'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'
import filterFactory, { textFilter, selectFilter, numberFilter } from 'react-bootstrap-table2-filter'
import { Storage } from 'aws-amplify'
import { stringToHex } from 'config/helpers/Helpers'
import axios from 'axios'
import componentStyles from 'assets/theme/views/admin/react-bs-table.js'
import DetailsContext from 'config/context/DetailsContext'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()
// import fileDownload from 'js-file-download'
// import { Document } from 'react-pdf'
// import { pdfjs } from 'react-pdf'
//import { XeroClient } from 'xero-node'

// const client_id = 'C5A63D6619134B6499767448F739C445'
// const client_secret = 'nMdgYhIesLoWi_P2uhPmHAk0A4X1K3Qt2ZgjoJoeaErYcHJ9'
// const redirectUrl = 'http://localhost:3000/companies'
// const scopes = 'accounting.transactions offline_access'

// const xero = new XeroClient({
// 	clientId: client_id,
// 	clientSecret: client_secret,
// 	redirectUris: [redirectUrl],
// 	scopes: scopes.split(' '),
// });

// const xero = new XeroClient({
//     clientId: 'C5A63D6619134B6499767448F739C445',
//     clientSecret: 'nMdgYhIesLoWi_P2uhPmHAk0A4X1K3Qt2ZgjoJoeaErYcHJ9',
//     redirectUris: ['http://localhost:3000/companies'],
//     scopes: 'accounting.transactions offline_access'.split(" "),
// });

// await xero.initialize()

const useStyles = makeStyles(componentStyles)

function PaymentsTable(props) {
    const classes = { ...useStyles() }
    const api = new BrokerAPI()
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId

    const [pdf, setPdf] = useState(null)

    async function downloadInvoice(payment) {
        //alert('This feature is in development and will be available soon')

        const fileName = payment.reference ? payment.reference : stringToHex(payment.companyId + '-' + payment.date) 
        console.log('fileName: ', fileName)

        //const info = await Auth.currentUserInfo()
        let url = await Storage.get('companies/' + props.companyId + '/' + fileName, {bucket: 'brokerdiary-user-upload'})
        console.log('url: ', url)
        try {   
            await axios.get(url)
            window.open(url)
        } catch(e) {
            onError(e)
        }


        // console.log('download invoice: ', payment)
        // const invoice = await api.getInvoice(payment.invoiceId)
        // console.log(invoice)
        // console.log(typeof invoice)

        // const file = new Blob(
        //     [invoice], 
        //     {type: 'application/pdf'});

        // const fileURL = URL.createObjectURL(file);

        // window.open(fileURL);

        //setPdf(invoice)

        //fileDownload(invoicePdf, 'Invoice.pdf')

        // const fileURL = window.URL.createObjectURL(invoice.toBlob())

        // let alink = document.createElement("a");
        // alink.href = fileURL;
        // alink.download = 'Invoice.pdf';
        // alink.click();

        // fetch(invoice).then((response) => {
        //     response.blob().then((blob) => {
             
        //         // Creating new object of PDF file
        //         const fileURL = window.URL.createObjectURL(blob);
                     
        //         // Setting various property values
        //         let alink = document.createElement("a");
        //         alink.href = fileURL;
        //         alink.download = "SamplePDF.pdf";
        //         alink.click();
        //     });
        // });
    }
    
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            dataField: 'date',
            text: 'Date',
            //sort: true,
            //filter: textFilter(),
            formatter: (cell, row) => {
                return <>{row.hasOwnProperty('date') ? moment(row.date).format('DD/MM/YY') : ''}</>
            },
            //footer: 'Total'
        },
        {
            dataField: 'description',
            text: 'Description',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{row.hasOwnProperty('allocation') ? getDescription(row.allocation) : (row.hasOwnProperty('billingMethod') ? row.billingPeriod : '')}</>
            },
            //footer: ''
        },
        {
            dataField: 'paid',
            text: 'Payment Status',
            //sort: true,
            //filter: textFilter(),
            formatter: (cell, row) => {
                return <>{row.hasOwnProperty('paid') ? row.paid === true ? 'Paid' : 'Unpaid' : ''}</>
            },
            //footer: ''
        },
        {
            dataField: 'total',
            text: 'Total (€)',
            //sort: true,
            //filter: textFilter(),
            formatter: (cell, row) => {
                return <>{row.hasOwnProperty('total') ? '€' + row.total.toFixed(2) : ''}</>
            }
            //footer: '€' + grossSum.toFixed(2)
        }
    ]

    if (companyDetails.billingAccount === 'SS') {
        columns.push({
            dataField: 'companyId',
            text: 'Action',
            sort: false,
            formatter: (cell, row) => {
                return (
                    <>
                        <>
                            {row.paid === false ? (
                                <Button 
                                    color='primary'
                                    variant='contained'
                                    size='small'
                                    onClick={() => props.makePayment(row)}
                                    disabled={props.disableButtons}
                                >
                                    Pay Now
                                </Button>
                            ) : (
                                <Button 
                                    color='secondary'
                                    variant='contained'
                                    size='small'
                                    onClick={() => downloadInvoice(row)}
                                >
                                    Download
                                </Button>
                            )}
                        </>
                    </>
                )
            }
        })
    }

    function getDescription(allocations) {
        let description = ''

        for (let [product, values] of Object.entries(allocations)) {
            let shortened = '';
            if (product === 'life-insurance') {
                shortened = 'LC';
            }
            if (product === 'income-protection') {
                shortened = 'IP';
            }
            if (product === 'pensions') {
                shortened = 'PEN';
            }
            if (product === 'mortgage-protection') {
                shortened = 'MP';
            }
            if (product === 'health-insurance') {
                shortened = 'HI';
            }
            if (parseInt(values.allocation) > 0) {
                description += shortened + 'x' + values.allocation + ' ';
            }
        }

        return description
    }

    return (
        <Card>
            {!loading ? (
                <>
                    <CardHeader
                        subheader={
                            <>
                                <Box display='flex' style={{justifyContent: 'space-between'}}>
                                    <Box>
                                        <Box
                                            component={Typography}
                                            variant='h2'
                                            marginBottom='0!important'
                                        >
                                            <Box component='span'>Invoices</Box>
                                        </Box>
                                        {/*
                                        <Box
                                            component='p'
                                            fontSize='.875rem'
                                            marginBottom='0'
                                            marginTop='0'
                                            lineHeight='1.7'
                                            fontWeight='300'
                                        >
                                            Invoices are generated on Friday evening for the current week
                                        </Box>
                                        */}
                                    </Box>
                                    <Box>
                                        {props.companyId === 'ai' &&
                                            <p>Remaining Credit: €12,776</p>
                                        }
                                        {props.balanceDelivery &&
                                            <p>Remaining Balance: €{props.balanceDelivery.balance.toFixed(2)}</p>
                                        }
                                    </Box>
                                </Box>
                            </>
                        }
                        classes={{ root: classes.cardHeaderRoot }}
                    ></CardHeader>

                    <div className='table-container'>
                        <BootstrapTable 
                            keyField='date' 
                            data={ props.payments } 
                            columns={ columns } 
                            filter={ filterFactory() } 
                            noDataIndication={<Box>No invoices</Box>}
                        />
                    </div>
                </>
            ) : (
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                            <Loader />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Card>
    );
}

export default PaymentsTable