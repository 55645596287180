const isLocal = process.env.NODE_ENV === 'development'

const config =  {
    apiGatewayGuest: {
        REGION: 'eu-west-1',
        URL: isLocal ? 'https://ah7cxw1aa6.execute-api.eu-west-1.amazonaws.com/dev/' : 'https://c9rtj2s6a4.execute-api.eu-west-1.amazonaws.com/prod/',
        // PATH is just used to refer to api within the app when initialising and using amplify API
        PATH: 'guest-api',
    },
    apiGatewayBroker: {
        REGION: 'eu-west-1',
        URL: isLocal ? 'https://8lpybg22ig.execute-api.eu-west-1.amazonaws.com/dev/' : 'https://vw7j8uycql.execute-api.eu-west-1.amazonaws.com/prod/',
        // PATH is just used to refer to api within the app when initialising and using amplify API
        PATH: 'broker-api',
    },
    apiGatewayAdmin: {
        REGION: 'eu-west-1',
        URL: isLocal ? 'https://enkcgn9aqf.execute-api.eu-west-1.amazonaws.com/dev/' : 'https://qzuqqvt9nk.execute-api.eu-west-1.amazonaws.com/prod/',
        // PATH is just used to refer to api within the app when initialising and using amplify API
        PATH: 'admin-api',
    },
    apiGatewayEnergy: {
        REGION: 'eu-west-1',
        URL: isLocal ? 'https://lhcp8r86sf.execute-api.eu-west-1.amazonaws.com/dev/' : 'https://zw2f93lrx9.execute-api.eu-west-1.amazonaws.com/prod/',
        // PATH is just used to refer to api within the app when initialising and using amplify API
        PATH: 'energy-api',
    },
    cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: 'eu-west-1_z9epGqBho',
        APP_CLIENT_ID: '3m5aosl878mlpki8pkriiadjg5',
        IDENTITY_POOL_ID: 'eu-west-1:09346996-4156-4e96-bdc9-1db9ca1bb4c8'
    },
    s3: {
        REGION: 'eu-west-1',
        BUCKET: 'compareinsurance-vms'
    },
    SENTRY_DSN: 'https://a5b5ae2e79e74e2f9a51b18f3f9f0c28@o290751.ingest.sentry.io/4504044751814656',
    stripeKey: isLocal ? 'pk_test_51NIQ0nJq5iWV7EUFFOmqKnIpxPv3RCTenJTgxKFe3S48vQKsJPfB4ejL1ealJTq1yWriTvUyExAr6MbhlNThk11p00jON1gua7' : 'pk_live_51NIQ0nJq5iWV7EUFcdePrEOTFsRdEhAXTzLrXNnzVJXW2EZIEdqZOKa3cs49J1TrfAQ0LrNeB5tf2xeUR3irO1Yc00tupfhZsA',
    stripeReturnUrl: isLocal ? 'http://localhost:3000/billing' : 'https://leads.brokerdiary.io/billing',
    stripeReturnUrlGuest: isLocal ? 'http://localhost:3000/topup' : 'https://leads.brokerdiary.io/topup'
};
export default config