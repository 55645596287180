import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import GuestAPI from 'config/api/GuestAPI'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import Loader from 'components/general/Loader'
import config from 'config/api/Config'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

const paymentOptions = {
    defaultValues: {
        billingDetails: {
            address: {
                country: 'IE'
            }
        }
    }
}

export default function PaymentForm(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	const api = new GuestAPI()
    const stripe = useStripe()
    const elements = useElements()
	const companyId = props.companyId

    console.log(props.clientSecret)

    //const [saveCard, setSaveCard] = useState(true)
    const [errorMessage, setErrorMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (event) => {
        event.preventDefault()
    
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return null
        }

        setIsLoading(true)
    
        const {error} = await stripe.confirmPayment({
            //Elements instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: config.stripeReturnUrlGuest,
            },
        })
    
        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message)
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }

        setIsLoading(false)
    }
	
    return (
        <form onSubmit={handleSubmit}>
            <Box>
                {props.clientSecret &&
                    <Box>
                        <PaymentElement 
                            options={paymentOptions}
                        />
                        {errorMessage && 
                            <p className='red-text' style={{marginTop: '1rem'}}>{errorMessage}</p>
                        }
                    </Box>
                }
            </Box>
            
            <Box style={{display: 'flex', justifyContent: 'center', marginTop: '1rem'}}>
                <Button
                    type='submit'
                    color='primary'
                    //marginLeft='auto!important'
                    variant='contained'
                    disabled={isLoading || !stripe || !elements}
                >
                    <span id='button-text'>
                        {isLoading ? <Loader height={10} /> : 'Pay now'}
                    </span>
                </Button>
            </Box>
        </form>
	)
}