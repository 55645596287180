import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import BootstrapTable from 'react-bootstrap-table-next'
import Grid from '@material-ui/core/Grid'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import filterFactory, { textFilter, selectFilter, numberFilter } from 'react-bootstrap-table2-filter'
import AddDeliveryModal from 'components/admin/AddDeliveryModal'
import DeliveryModal from 'components/admin/DeliveryModal'

import componentStyles from 'assets/theme/views/admin/react-bs-table.js'

const useStyles = makeStyles(componentStyles)

function DeliveriesTable(props) {
    const classes = { ...useStyles() }
    const api = new AdminAPI()

    const deliveries = props.deliveries
    const [delivery, setDelivery] = useState(null)
    const [showDeliveryModal, setShowDeliveryModal] = useState(false)
    const [showAddDeliveryModal, setShowAddDeliveryModal] = useState(false)
    const [loading, setLoading] = useState(false)

    let allocationSum = 0
    let totalSum = 0
    for (let delivery of deliveries) {
        allocationSum += parseInt(delivery.allocation)
        totalSum += (delivery.leadPrice * delivery.allocation)
    }
    console.log('allocation sum: ', allocationSum)
    console.log('total sum: ', totalSum)

    const columns = [
        {
            dataField: 'company',
            text: 'Company',
            //sort: true,
            //filter: textFilter(),
            formatter: (cell, row) => {
                return (<Box className={row.active === false ? 'inactive-delivery hover-text primary-text' : 'active-delivery hover-text primary-text'} onClick={() => openDeliveryModal(row)}>{row.companyName} ({row.companyId.toUpperCase()})</Box>)
            },
            footer: 'Total'
        },
        {
            dataField: 'campaignName',
            text: 'Campaign',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>{row.campaignName}</Box>
            },
            footer: ''
        },
        {
            dataField: 'allocation',
            text: 'Allocation',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>{row.allocation}</Box>
            },
            footer: allocationSum.toString()
        },
        {
            dataField: 'leadPrice',
            text: 'Lead Price',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>€{row.leadPrice.toFixed(2)}</Box>
            },
            footer: ''
        },
        {
            dataField: 'total',
            text: 'Total',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>€{(row.leadPrice * row.allocation).toFixed(2)}</Box>
            },
            footer: '€' + totalSum.toFixed(2)
            // footer: columnData => columns.reduce((acc, item) => {
            //     console.log(acc)
            //     console.log(item)
            // })
        }
    ]

    function openDeliveryModal(delivery) {
        setDelivery(delivery)
        setShowDeliveryModal(true)
    }

    function closeDeliveryModal() {
        setShowDeliveryModal(false)
        setDelivery(null)
    }

    return (
        <Card>
            {!loading ? (
                <>
                    <CardHeader
                        subheader={
                            <>
                                <Box display='flex' style={{justifyContent: 'space-between'}}>
                                    <Box>
                                        <Box
                                            component={Typography}
                                            variant='h2'
                                            marginBottom='0!important'
                                        >
                                            <Box component='span'>{props.title}</Box>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Button
                                            component={Box}
                                            onClick={() => setShowAddDeliveryModal(true)}
                                            color='primary'
                                            variant='contained'
                                            size='small'
                                        >
                                            Add Delivery
                                        </Button>
                                    </Box>
                                </Box>
                            </>
                        }
                        classes={{ root: classes.cardHeaderRoot }}
                    ></CardHeader>
                    
                    <div className='table-container'>
                        <BootstrapTable 
                            keyField='company' 
                            data={ deliveries } 
                            columns={ columns } 
                            filter={ filterFactory() } 
                            noDataIndication={<Box>No deliveries</Box>}
                        />
                    </div>

                    {showAddDeliveryModal &&
                        <AddDeliveryModal 
                            isOpen={showAddDeliveryModal}
                            close={() => setShowAddDeliveryModal(false)}
                            showSuccessAlert={(text) => props.showSuccessAlert(text)}
                            refreshDeliveries={() => props.refreshDeliveries()}
                            companies={props.companies}
                            campaigns={props.campaigns}
                        />
                    }

                    {showDeliveryModal &&
                        <DeliveryModal 
                            isOpen={showDeliveryModal}
                            close={() => closeDeliveryModal()}
                            delivery={delivery}
                            updateDelivery={(delivery) => props.updateDelivery(delivery)}
                            showSuccessAlert={(text) => props.showSuccessAlert(text)}
                            companies={props.companies}
                            campaigns={props.campaigns}
                        />
                    }
                </>
            ) : (
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                            <Loader />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Card>
    );
}

export default DeliveriesTable