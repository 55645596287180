import React from 'react'
import Chart from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import { chartOptions, parseOptions, chartExample7 } from 'config/constants/ChartVariables'
import componentStyles from 'assets/theme/components/cards/charts/card-product-comparison.js'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

const useStyles = makeStyles(componentStyles)

const themeColors = require('assets/theme/colors.js').default

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

var colors = {
    gray: themeColors.gray,
    theme: {
        default: themeColors.dark.main,
        primary: themeColors.primary.main,
        secondary: themeColors.secondary.main,
        info: themeColors.info.main,
        infoLight: themeColors.info.light,
        success: themeColors.success.main,
        danger: themeColors.error.main,
        warning: themeColors.warning.main,
    },
    black: themeColors.black.light,
    white: themeColors.white.main,
    transparent: themeColors.transparent.main,
}

// const options = {
//     scales: {
//       yAxes: [
//             {
//                 gridLines: {
//                     color: colors.gray[200],
//                     zeroLineColor: colors.gray[200],
//                 },
//                 ticks: {},
//             },
//         ],
//     },
// }

const options = {
    tooltips: {
        mode: 'index',
        intersect: false,
    },
    responsive: true,
    scales: {
        xAxes: [
            {
                stacked: true,
            },
        ],
        yAxes: [
            {
                stacked: true,
            },
        ],
    }
}

function RevenueGroupedBar(props) {
    const classes = useStyles()
    const theme = useTheme()
    if (window.Chart) {
        parseOptions(Chart, chartOptions())
    }

    let labels = []
    let currentMonth = moment().month()
    for (let i = 5; i > 0; i--) {
        let previousMonth = moment().subtract(i, 'months')
        //console.log(previousMonth.month());
        labels.push(months[previousMonth.month()])
    }
    labels.push(months[currentMonth])
    console.log('labels: ', labels)

    let lifeInsuraceProfit = []
    let lifeInsuraceCost = []
    let incomeProtectionProfit = []
    let incomeProtectionCost = []
    let pensionsProfit = []
    let pensionsCost = []
    let mortgageProtectionProfit = []
    let mortgageProtectionCost = []
    let healthInsuranceProfit = []
    let healthInsuranceCost = []
    for (var month of labels) {
        let liProfit = 0
        let liCost = 0
        let ipProfit = 0
        let ipCost = 0
        let penProfit = 0
        let penCost = 0
        let mpProfit = 0
        let mpCost = 0
        let hiProfit = 0
        let hiCost = 0
        for (var week of props.weeks) {
            if (moment(week.date).isAfter(moment().startOf('month').subtract(5, 'months')) && moment(week.date).format('MMMM') === month) {
                let liValues = week.details['life-insurance'] || {}
                let liSpend = liValues.facebook || 0 + liValues.google || 0 + liValues.tiktok || 0 + liValues.reddit || 0 + liValues.bing || 0
                liProfit += (liValues.revenue - liSpend)
                liCost += liSpend

                let ipValues = week.details['income-protection'] || {}
                let ipSpend = ipValues.facebook || 0 + ipValues.google || 0 + ipValues.tiktok || 0 + ipValues.reddit || 0 + ipValues.bing || 0
                ipProfit += (ipValues.revenue - ipSpend)
                ipCost += ipSpend

                let penValues = week.details['pensions'] || {}
                let penSpend = penValues.facebook || 0 + penValues.google || 0 + penValues.tiktok || 0 + penValues.reddit || 0 + penValues.bing || 0
                penProfit += (penValues.revenue - penSpend)
                penCost += penSpend

                let mpValues = week.details['mortgage-protection'] || {}
                let mpSpend = mpValues.facebook || 0 + mpValues.google || 0 + mpValues.tiktok || 0 + mpValues.reddit || 0 + mpValues.bing || 0
                mpProfit += (mpValues.revenue - mpSpend)
                mpCost += mpSpend

                let hiValues = week.details['health-insurance'] || {}
                let hiSpend = hiValues.facebook || 0 + hiValues.google || 0 + hiValues.tiktok || 0 + hiValues.reddit || 0 + hiValues.bing || 0
                hiProfit += (hiValues.revenue - hiSpend)
                hiCost += hiSpend
            }
        }
        lifeInsuraceProfit.push(parseFloat(liProfit.toFixed(2)))
        lifeInsuraceCost.push(parseFloat(liCost.toFixed(2)))
        incomeProtectionProfit.push(parseFloat(ipProfit.toFixed(2)))
        incomeProtectionCost.push(parseFloat(ipCost.toFixed(2)))
        pensionsProfit.push(parseFloat(penProfit.toFixed(2)))
        pensionsCost.push(parseFloat(penCost.toFixed(2)))
        mortgageProtectionProfit.push(parseFloat(mpProfit.toFixed(2)))
        mortgageProtectionCost.push(parseFloat(mpCost.toFixed(2)))
        healthInsuranceProfit.push(parseFloat(hiProfit.toFixed(2)))
        healthInsuranceCost.push(parseFloat(hiCost.toFixed(2)))
    }

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Life Insurance Profit',
                data: lifeInsuraceProfit,
                maxBarThickness: 10,
                backgroundColor: colors.theme['info'],
                stack: 'Stack 0'
            },
            {
                label: 'Life Insurance Cost',
                data: lifeInsuraceCost,
                maxBarThickness: 10,
                backgroundColor: '#ddd',
                stack: 'Stack 0'
            },
            {
                label: 'Income Protection Profit',
                data: incomeProtectionProfit,
                maxBarThickness: 10,
                backgroundColor: colors.theme['danger'],
                stack: 'Stack 1'
            },
            {
                label: 'Income Protection Cost',
                data: incomeProtectionCost,
                maxBarThickness: 10,
                backgroundColor: '#ddd',
                stack: 'Stack 1'
            },
            {
                label: 'Pensions Profit',
                data: pensionsProfit,
                maxBarThickness: 10,
                backgroundColor: colors.theme['primary'],
                stack: 'Stack 2'
            },
            {
                label: 'Pensions Cost',
                data: pensionsCost,
                maxBarThickness: 10,
                backgroundColor: '#ddd',
                stack: 'Stack 2'
            },
            {
                label: 'Mortgage Protection Profit',
                data: mortgageProtectionProfit,
                maxBarThickness: 10,
                backgroundColor: colors.theme['success'],
                stack: 'Stack 3'
            },
            {
                label: 'Mortgage Protection Cost',
                data: mortgageProtectionCost,
                maxBarThickness: 10,
                backgroundColor: '#ddd',
                stack: 'Stack 3'
            },
            {
                label: 'Health Insurance Profit',
                data: healthInsuranceProfit,
                maxBarThickness: 10,
                backgroundColor: colors.theme['success'],
                stack: 'Stack 3'
            },
            {
                label: 'Health Insurance Cost',
                data: healthInsuranceCost,
                maxBarThickness: 10,
                backgroundColor: '#ddd',
                stack: 'Stack 3'
            },
        ]
    }
  
    return (
        <>
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    title={
                        <Box component='span' color={theme.palette.gray[600]}>
                            
                        </Box>
                    }
                    subheader='Revenue Summary'
                    classes={{ root: classes.cardHeaderRoot }}
                    titleTypographyProps={{
                        component: Box,
                        variant: 'h6',
                        letterSpacing: '2px',
                        marginBottom: '0!important',
                        classes: {
                            root: classes.textUppercase,
                        },
                    }}
                    subheaderTypographyProps={{
                        component: Box,
                        variant: 'h3',
                        marginBottom: '0!important',
                        color: 'initial',
                    }}
                ></CardHeader>
                
                <CardContent>
                    <Box position='relative' height='350px'>
                        <Bar
                            data={data}
                            options={options}
                            getDatasetAtEvent={(e) => console.log(e)}
                        />
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}

export default RevenueGroupedBar
