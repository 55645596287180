import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import BrokerModal from 'components/broker/BrokerModal'
import AddBrokerModal from 'components/broker/AddBrokerModal'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'

import componentStyles from 'assets/theme/components/cards/cards/card-button.js'
import componentStylesBadge from 'assets/theme/components/badge.js'

const useStyles = makeStyles(componentStyles)
const useStylesBadge = makeStyles(componentStylesBadge)

function BrokersCard(props) {
    const classes = { ...useStyles(), ...useStylesBadge() }
    //console.log('details: ', details)
    const api = new BrokerAPI()

    const brokers = props.brokers
    const invites = props.invites

    const [showAddBrokerModal, setShowAddBrokerModal] = useState(false)
    const [broker, setBroker] = useState(null)
    const [showBrokerModal, setShowBrokerModal] = useState(false)

    async function cancelInvite(invite) {
        console.log('cancel invite')
        try {
            let result = await api.cancelInvite(invite.email)
            console.log('result: ', result)

  			props.refreshBrokers()
  			props.showSuccessAlert('Invite Cancelled!')
  		} catch(e) {
  			onError(e)
  		}
    }

    function openBrokerModal(broker) {
        setBroker(broker)
        setShowBrokerModal(true)
    }

    function closeBrokerModal() {
        setBroker(null)
        setShowBrokerModal(false)
    }
    
    return (
        <Card
            classes={{
                root: classes.cardRoot,
            }}
        >
            <CardHeader
                subheader={
                    <Grid
                        container
                        component={Box}
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Grid item xs='auto'>
                            <Box
                                component={Typography}
                                variant='h3'
                                marginBottom='0!important'
                            >
                                Brokers
                            </Box>
                        </Grid>
                        
                        <Grid item xs='auto'>
                            <Box
                                justifyContent='flex-end'
                                display='flex'
                                flexWrap='wrap'
                            >
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    style={{marginRight: '0.5rem'}}
                                    onClick={() => setShowAddBrokerModal(true)}
                                    //disabled={true}
                                >
                                    Add Broker
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
            >
            </CardHeader>
            
            <CardContent>
                <Grid container>
                    <Grid item xs={12} lg={12}>  
                        {
                            brokers.map((broker, i) => {
                                return (
                                    <Box display='flex' direction='row' justifyContent='space-between' style={{marginBottom: '1rem', flexWrap: 'wrap'}} key={i}>
                                        {broker.hideInAnalytics ? (
                                            <Typography style={{fontWeight: '700', opacity: '0.5'}}>{broker.firstName + ' ' + broker.lastName}</Typography>
                                        ) : (
                                            <Typography style={{fontWeight: '700'}}>{broker.firstName + ' ' + broker.lastName}</Typography>
                                        )}
                                        <Typography className='hover-text' style={{fontWeight: '400', color: '#525f7f', fontSize: '1rem'}} onClick={() => openBrokerModal(broker)}>Settings</Typography>
                                    </Box>
                                )
                            })
                        }
                        {invites.length > 0 && 
                            <>
                                <Divider style={{marginBottom: '1rem', marginTop: '1rem'}} />
                                {
                                    invites.map((invite, i) => {
                                        return (
                                            <Box display='flex' direction='row' justifyContent='space-between' style={{marginBottom: '1rem', flexWrap: 'wrap'}} key={i}>
                                                <Typography style={{fontWeight: '700', opacity: '0.5'}}>{invite.email}</Typography>
                                                <Typography className='hover-text red-text' style={{fontWeight: '400', fontSize: '1rem'}} onClick={() => cancelInvite(invite)}>Cancel Invite</Typography>
                                            </Box>
                                        )
                                    })
                                }
                            </>
                        }
                    </Grid>
                    {/*
                    <Grid item xs={12} lg={12}>        
                        <Button
                            component={Box}
                            color='primary'
                            variant='contained'
                            size='medium'
                            disabled={checkAllocations()}
                            onClick={() => updateDetails()}
                        >
                            Save
                        </Button>
                    </Grid>
                    */}
                </Grid>
            </CardContent>

            {showBrokerModal &&
                <BrokerModal 
                    isOpen={showBrokerModal}
                    close={() => closeBrokerModal()}
                    //refreshBrokers={() => props.refreshBrokers()}
                    updateBroker={(broker) => props.updateBroker(broker)}
                    broker={broker}
                    allocation={props.allocations[props.product] ? props.allocations[props.product][broker.brokerId] : 0}
                />
            }

            {showAddBrokerModal &&
                <AddBrokerModal
                    isOpen={showAddBrokerModal}
                    close={() => setShowAddBrokerModal(false)}
                    refreshBrokers={() => props.refreshBrokers()}
                    showSuccessAlert={(text) => props.showSuccessAlert(text)}
                />
            }
        </Card>
    );
}

export default BrokersCard