import React from 'react'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Select from '@mui/material/Select'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import { getProductString } from 'config/helpers/Helpers'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  	PaperProps: {
    	style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
    	},
  	},
};

function ProductSelect(props) {
    const products = props.products
    
    async function updateProducts(products) {
        if (products.indexOf('All') > -1) {
            products = ['All']
        }
        props.updateProducts(products)
	}
    
    return (
        <Select
            multiple={true}
            value={products}
            onChange={(e) => updateProducts(e.target.value)}
            renderValue={(selected) => {
                let output = ''
                selected.forEach((product, i) => {
                    if (product === 'All') {
                        output = 'All'
                    }
                    else {
                        output += getProductString(product)
                        if (i < selected.length - 1) {
                            output += ', '
                        }
                    }
                })
                return output
            }}
            MenuProps={MenuProps}
            input={<OutlinedInput />}
        >
            <MenuItem value={'All'} key={'all'}>
                <Checkbox checked={products.indexOf('All') > -1} color='primary' />
                <ListItemText primary='All' className='checkbox-menu-item' />
            </MenuItem>
            {Object.keys(props.productsAvailable).map((option, i) => {
                return (
                    <MenuItem value={option} key={i} disabled={products.indexOf('All') > -1 && option !== 'All'}>
                        <Checkbox checked={products.indexOf(option) > -1} color='primary' />
                        <ListItemText primary={getProductString(option)} className='checkbox-menu-item' />
                    </MenuItem>
                )
            })}
        </Select>
    )
}

export default ProductSelect