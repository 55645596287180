import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' 
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import BrokerAPI from 'config/api/BrokerAPI'
import TaskModal from 'components/broker/TaskModal'
import { onError } from 'config/lib/errorLib'
import Loader from 'components/general/Loader'
import { NotificationManager } from 'react-notifications'
import DetailsContext from 'config/context/DetailsContext'
import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

const minutes = {
    minutes15: 15,
    minutes30: 30,
    hour1: 60
};

function Tasks() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const api = new BrokerAPI()
    const companyDetails = useContext(DetailsContext)
	const companyId = companyDetails.companyId

    const [details, setDetails] = useState({})
    const [tasks, setTasks] = useState([])
    const [task, setTask] = useState(null)
    const [showTaskModal, setShowTaskModal] = useState(false)
    const [broker, setBroker] = useState(null)
    const [brokers, setBrokers] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getDetails()
    }, [])

    function checkFinished(task) {
        let taskTime = moment(task.date)
        let finishedTime = taskTime.add(minutes[task.duration], 'minutes')
        let now = moment()

        return finishedTime.isBefore(now)
    }

    function getEnd(task) {
        let taskTime = moment(task.date)
        let finishedTime = taskTime.add(minutes[task.duration], 'minutes')

        return finishedTime.toDate()
    }

    async function getDetails() {
        try {
            const details = await api.getDetails(companyId)
            console.log('details: ', details)

            let tasks = []
            tasks = await api.getBrokerTasks(companyId, details.brokerId)
            //console.log('tasks: ', tasks)

            let brokers = []

            if (details.permissions === 'admin') {
                brokers = await api.getBrokers(companyId)
                console.log('brokers: ', brokers)
            }

            let events = tasks.map(i => ({...i, start: i.date, end: getEnd(i), title: i.associatedWith.name, editable: true, extendedProps: i, className: (checkFinished(i) ? 'completed' : '')}))
            console.log(events)

            setDetails(details)
            setTasks(events)
            setBrokers(brokers)
            setBroker(details.brokerId)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function updateBroker(broker) {
        setLoading(true)
		console.log('update broker: ', broker)
		try {
			let tasks = await api.getBrokerTasks(companyId, broker)
			//console.log('tasks: ', tasks)

            let events = tasks.map(i => ({...i, start: i.date, end: getEnd(i), title: i.associatedWith.name, editable: true, extendedProps: i, className: (checkFinished(i) ? 'completed' : '')}))

			setBroker(broker)
            setTasks(events)
			setLoading(false)
			//NotificationManager.success('Tasks updated')
		} catch(e) {
			onError(e)
		}
	}

    function updateTask(task) {
        const index = tasks.findIndex(oldTask => (oldTask.taskId === task.taskId))
        console.log('task index: ', index)

        let newTasks = [...tasks]
        newTasks[index] = task

        let events = newTasks.map(i => ({...i, start: i.date, end: getEnd(i), title: i.associatedWith.name, editable: true, extendedProps: i, className: (checkFinished(i) ? 'completed' : '')}))
        console.log(events)

        setTasks(events)
    }

    function deleteTask(task) {
        let newTasks = tasks.filter(item => (task.taskId !== item.taskId))
        console.log(newTasks)
        let events = newTasks.map(i => ({...i, start: i.date, end: getEnd(i), title: i.associatedWith.name, editable: true, extendedProps: i, className: (checkFinished(i) ? 'completed' : '')}))

        setTasks(events)
    }

    function openTaskModal(info) {
        console.log(info)
        let event = info.event._def
        let task = info.event._def.extendedProps
        console.log(event)
        console.log(task)

        setTask(task)
        setShowTaskModal(true)
    }

    function closeTaskModal() {
        setShowTaskModal(false)
        setTask(null)
    }

    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <Grid container justifyContent='center'>
                        {details.permissions === 'admin' &&
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    component={Box}
                                    alignItems='center'
                                    justifyContent='flex-start'
                                    style={{marginBottom: '2rem'}}
                                    direction='row'
                                >
                                    <Grid item xs={12} md={2}>
                                        <FormGroup style={{marginBottom: '1rem'}}>
                                            <FormControl variant='outlined'>
                                                <Select
                                                    multiple={false}
                                                    defaultValue={null}
                                                    IconComponent={KeyboardArrowDown}
                                                    value={broker}
                                                    onChange={(e) => updateBroker(e.target.value)}
                                                >
                                                    {brokers.map((broker, i) => {
                                                        return (<MenuItem value={broker.brokerId} key={i}>{broker.firstName + ' ' + broker.lastName}</MenuItem>)
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item>
                                        <Box style={{marginBottom: '1rem'}}>
                                            <Button
                                                component={Box}
                                                onClick={() => alert('In development')}
                                                color='primary'
                                                variant='contained'
                                                disabled
                                            >
                                                Integrate Outlook
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <FullCalendar
                                        plugins={[ dayGridPlugin, timeGridPlugin, listPlugin ]}
                                        initialView='timeGridWeek'
                                        headerToolbar = {{
                                            left: 'prev,next today',
                                            center: 'title',
                                            right: 'timeGridWeek,listWeek,dayGridMonth'
                                        }}
                                        events={tasks}
                                        eventClick={(info) => openTaskModal(info)}
                                        eventTimeFormat = {{
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            meridiem: false,
                                            hour12: false
                                        }}
                                        slotMinTime='08:00:00'
                                        slotMaxTime='20:00:00'
                                        slotDuration='00:15:00'
                                        expandRows
                                        height='80vh'
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

                        {showTaskModal &&
                            <TaskModal 
                                isOpen={showTaskModal}
                                close={() => closeTaskModal()}
                                task={task}
                                details={details}
                                updateTask={(task) => updateTask(task)}
                                deleteTask={(task) => deleteTask(task)}
                            />
                        }
                    </Grid>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default Tasks