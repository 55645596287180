import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
//import Grid from '@material-ui/core/Grid'
import { onError } from 'config/lib/errorLib'
import { Auth } from 'aws-amplify'
import { NotificationManager } from 'react-notifications'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function ForgotPasswordModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	//const theme = useTheme()

  	const [currentPassword, setCurrentPassword] = useState('')
  	const [newPassword, setNewPassword] = useState('')
  	const [confirmNewPassword, setConfirmNewPassword] = useState('')

  	async function changePasswordRequest() {
  		try {
  			let user = await Auth.currentAuthenticatedUser()
			console.log(user) 

			await Auth.changePassword(user, currentPassword, newPassword)

  			NotificationManager.success('Password changed')
  			props.close()
  		} catch(e) {
  			onError(e)
  	// 		if (e.code === 'NotAuthorizedException') {
			// 	//alert('Please ensure you have entered the correct password.')
			// 	NotificationManager.error('Please ensure you have entered the correct password.')
			// }
			// if (e.code === 'InvalidParameterException') {
			// 	//alert('Please ensure you have entered a valid password (At least 8 characters with uppercase letters, lowercase letters and numbers).')
			// 	NotificationManager.error('Please ensure you have entered the correct password.');
			// }
			// if (e.code === 'LimitExceededException') {
			// 	//alert('Attempt limit exceeded, please try after some time.')
			// 	NotificationManager.error('Please ensure you have entered the correct password.');
			// }
  		}
  	}

  	function checkNewPassword() {
  		if (newPassword.length > 0 && (newPassword === confirmNewPassword)) {
  			return false
  		}
  		else {
  			return true
  		}
  	}

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Change Password
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
			
			<DialogContent>
        		<FormGroup>
                  	<FormLabel>Current Password</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='password'
                    	placeholder='csgw@2a44'
                    	value={currentPassword}
                    	onChange={(e) => setCurrentPassword(e.target.value)}
                  	/>
            	</FormGroup>
            	<FormGroup>
                  	<FormLabel>New Password</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='password'
                    	placeholder='csgw@2a44'
                    	value={newPassword}
                    	onChange={(e) => setNewPassword(e.target.value)}
                  	/>
            	</FormGroup>
            	<FormGroup>
                  	<FormLabel>Confirm New Password</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='password'
                    	placeholder='csgw@2a44'
                    	value={confirmNewPassword}
                    	onChange={(e) => setConfirmNewPassword(e.target.value)}
                  	/>
            	</FormGroup>

				<Box
                    component='p'
                    marginBottom='1rem'
                    fontWeight='300'
                    lineHeight='1.7'
                    fontSize='1rem'
                >
                    Password must contain at least 6 characters
                </Box>
			</DialogContent>
			
			<DialogActions>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Cancel
				</Button>
				
				<Button
					component={Box}
					onClick={() => changePasswordRequest()}
					color='primary'
					marginLeft='auto!important'
					variant='contained'
					disabled={checkNewPassword()}
				>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	)
}