import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory, { textFilter, selectFilter, numberFilter } from 'react-bootstrap-table2-filter'
import cellEditFactory from 'react-bootstrap-table2-editor'
import { defaultPensionPricing } from 'config/constants/Constants'

function LeadPricingTable(props) {

    let arr = []

    for (let key of Object.keys(defaultPensionPricing)) {
        arr.push({
            product: key,
            price: defaultPensionPricing[key]
        })
    }

    for (const [product, price] of Object.entries(props.details)) {
        for (let item of arr) {
            if (item.product === product) {
                item.price = price
            }
        }
    }

    const [details, setDetails] = useState(arr)

    let columns = [
        {
            dataField: 'product',
            text: 'Pension Value',
            sort: true,
            editable: false,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{row.product}</>
            }
        },
        {
            dataField: 'price',
            text: 'Price (€)',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return (
                    <>{row.price}</>
                )
            }
        }
    ]

    const handleTableChange = (type, { data, cellEdit: { rowId, dataField, newValue } }) => {
        console.log(rowId)
        console.log(dataField)
        console.log('new value: ', newValue)

        // update state to new field
        const result = data.map((row) => {
            console.log(row)
            if (row.product === rowId) {
                const newRow = { ...row }
                // parsing so totals still work after cell update
                newRow[dataField] = parseFloat(newValue)
                return newRow
            }
            return row
        })
        console.log('table change result: ', result)
        updateDetails(result)
    }

    function updateDetails(updatedDetails) {
        console.log(updatedDetails)
        setDetails(updatedDetails)
        let obj = {}
        for (let item of updatedDetails) {
            obj[item.product] = item.price
        }
        console.log(obj)
        props.updateDetails(obj)
    }

    return (
        <div className='table-container'>
            <BootstrapTable 
                keyField='product' 
                data={ details } 
                columns={ columns } 
                filter={ filterFactory() } 
                noDataIndication={<Box>No data</Box>}
                cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) }
                onTableChange={ handleTableChange }
                remote={ {
                    filter: false,
                    pagination: false,
                    sort: false,
                    cellEdit: true
                } }
            />
        </div>
    );
}

export default LeadPricingTable