import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Select from '@mui/material/Select'
import MenuItem from '@material-ui/core/MenuItem'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import LeadPricingTable from 'components/admin/LeadPricingTable'
import componentStylesDialog from 'assets/theme/components/dialog.js'
import componentStylesForms from 'assets/theme/components/forms.js'
import componentStylesButtons from 'assets/theme/components/button.js'
import { defaultPensionPricing } from 'config/constants/Constants'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

//const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)
const useStylesForms = makeStyles(componentStylesForms)
const useStylesButtons = makeStyles(componentStylesButtons)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function DeliveryModal(props) {
	const classes = { ...useStylesDialog(), ...useStylesForms(), ...useStylesButtons() }
  	//const theme = useTheme()
  	const api = new AdminAPI()

    const delivery = props.delivery
    const companies = props.companies

    const [balance, setBalance] = useState(delivery.balance)
    //const [topupDate, setTopupDate] = useState(delivery.topupDate)
    const [active, setActive] = useState(delivery.active)
    const [pricing, setPricing] = useState(delivery.pricing || defaultPensionPricing)
    
    async function updateDelivery() {
        try {
            console.log(pricing)
            // convert pricing to key value object and update in parent
            // let obj = {}
            // for (let item of pricing) {
            //     obj[item.product] = parseFloat(item.price)
            // }
            // console.log(obj)
            let updatedDelivery = await api.updateBalanceDelivery(delivery.companyId, delivery.product, balance, active, pricing)
            console.log('delivery: ', updatedDelivery)

            props.updateDelivery(updatedDelivery)
            props.showSuccessAlert('Delivery updated!')
            props.close()
        } catch(e) {
            onError(e)
        }
    }

	return (
        <Dialog
            open={props.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => props.close()}
        >
            {alert}
            <div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Update Balance Delivery
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
            <DialogContent>
                <FormGroup>
                    <FormLabel>Company</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            value={delivery.companyId}
                            //onChange={(e) => setCompany(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                            disabled
                        >
                            {companies.map((company) => {
                                return (
                                    <MenuItem value={company.companyId}>{company.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Product</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            value={delivery.product}
                            //onChange={(e) => setProduct(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                            disabled
                        >
                            <MenuItem value={'pensions'}>Pensions</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Last Topup</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={moment(delivery.lastTopup).format('DD/MM/YY')}
                    	//onChange={(e) => setBalance(e.target.value)}
                        disabled
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Balance (€)</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={balance}
                    	onChange={(e) => setBalance(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Active</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={active}
                            onChange={(e) => setActive(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Pricing</FormLabel>
                    <LeadPricingTable 
                        details={pricing} 
                        updateDetails={(pricing) => setPricing(pricing)}
                    />
                </FormGroup>
			</DialogContent>
            <DialogActions>
                <Box width='100%' display='flex' justifyContent='space-between'>
                    <Button onClick={() => props.close()} color='primary'>
                        Close
                    </Button>
                    <Box>
                        <Box display='inline-block' marginRight='0.5rem'>
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={() => updateDelivery()}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
	)
}