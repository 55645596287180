import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Checkbox from '@material-ui/core/Checkbox'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'
import { timeOptions, reminderOptions, durationOptions } from 'config/constants/Constants'
import { getProductString } from 'config/helpers/Helpers'
import { NotificationManager } from 'react-notifications'
import DetailsContext from 'config/context/DetailsContext'
import componentStylesDialog from 'assets/theme/components/dialog.js'
import componentStylesForms from 'assets/theme/components/forms.js'
import componentStylesButtons from 'assets/theme/components/button.js'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

//const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)
const useStylesForms = makeStyles(componentStylesForms)
const useStylesButtons = makeStyles(componentStylesButtons)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function TaskModal(props) {
	const classes = { ...useStylesDialog(), ...useStylesForms(), ...useStylesButtons() }
  	//const theme = useTheme()
    const history = useHistory()
  	const api = new BrokerAPI()
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId

    const details = props.details
    const task = props.task

    const [taskTitle, setTaskTitle] = useState(task.title)
	const [taskDate, setTaskDate] = useState(moment(task.date).format('YYYY-MM-DD'))
	const [taskTime, setTaskTime] = useState(moment(task.date).format('HH:mm'))
    const [taskDuration, setTaskDuration] = useState(task.duration || 'hour1')
	const [taskReminder, setTaskReminder] = useState(task.reminder)
	const [taskNote, setTaskNote] = useState(task.note)
    const [taskCompleted, setTaskCompleted] = useState(task.completed)
    const [alert, setAlert] = useState(false)
    
    async function updateTask() {
        let date = new Date(taskDate + ' ' + taskTime)
        try {
            const result = await api.updateTask(companyId, task.brokerId, task.taskId, taskTitle, date, taskDuration, taskNote, taskReminder, taskCompleted)
            console.log('updated task: ', result)

            NotificationManager.success('Task updated')
            props.updateTask(result)
            props.close()
        } catch(e) {
            onError(e)
        }
    }

    async function deleteTask() {
        // show warning
        try {
            await api.deleteTask(companyId, task.brokerId, task.taskId)
            console.log('deleted task')

            NotificationManager.success('Task deleted')
            props.deleteTask(task)
            props.close()
        } catch(e) {
            onError(e)
        }
    }

    function showDeleteWarning() {
        setAlert(
            <ReactBSAlert
                warning
                style={{ display: 'block', marginTop: '-100px' }}
                title='Are you sure?'
                onConfirm={() => deleteTask(false)}
                onCancel={() => setAlert(false)}
                cancelBtnCssClass='btn-secondary'
                confirmBtnBsStyle='danger'
                cancelBtnText='Cancel'
                confirmBtnText='Yes, delete it'
                showCancel
                btnSize=''
            >
                This will remove the task permanently.
            </ReactBSAlert>
        )
    }

    function navToClient() {
        console.log('task: ', task)
        if (task.associatedWith.type === 'lead') {
            history.push({
                pathname: task.associatedWith.pk,
                state: {openModal: true, product: task.associatedWith.pk, phone: task.associatedWith.sk}
            })
        }
    }

	return (
        <Dialog
            open={props.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => props.close()}
        >
            {alert}
            {/*
            <div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					{task.associatedWith.name}
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
            */}
            <DialogContent>
                <FormGroup>
                    <FormLabel>Client</FormLabel>
                    <FormControl
                        variant='filled'
                        component={Box}
                        width='100%'
                    >
                        <Box className='hover-text primary-text' onClick={() => navToClient()}>{task.associatedWith.name}</Box>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Type</FormLabel>
                    <FormControl
                        variant='filled'
                        component={Box}
                        width='100%'
                    >
                        <Box>{getProductString(task.associatedWith.pk)}</Box>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Title</FormLabel>
                    <FormControl
                        variant='filled'
                        component={Box}
                        width='100%'
                    >
                        <OutlinedInput
                            fullWidth
                            type='text'
                            value={taskTitle}
                            onChange={(e) => setTaskTitle(e.target.value)}
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Date</FormLabel>
                    <FormControl
                        variant='filled'
                        component={Box}
                        width='100%'
                    >
                        <OutlinedInput
                            fullWidth
                            type='date'
                            value={taskDate}
                            onChange={(e) => setTaskDate(e.target.value)}
                        />
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Time</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                      		multiple={false}
                      		IconComponent={KeyboardArrowDown}
                      		value={taskTime}
                      		onChange={(e) => setTaskTime(e.target.value)}
                    	>
                            {timeOptions.map((option, i) => {
                                return (<MenuItem value={option} key={i}>{option}</MenuItem>)
                            })}
                    	</Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Duration</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                      		multiple={false}
                      		IconComponent={KeyboardArrowDown}
                      		value={taskDuration}
                      		onChange={(e) => setTaskDuration(e.target.value)}
                    	>
                            {Object.keys(durationOptions).map((key) => {
                                return (<MenuItem value={key} key={key}>{durationOptions[key]}</MenuItem>)

                            })}
                    	</Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Reminder</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            IconComponent={KeyboardArrowDown}
                            value={taskReminder}
                            onChange={(e) => setTaskReminder(e.target.value)}
                        >
                            {Object.keys(reminderOptions).map((key) => {
                                return (<MenuItem value={key} key={key}>{reminderOptions[key]}</MenuItem>)

                            })}
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Note</FormLabel>
                    <FormControl
                        variant='filled'
                        component={Box}
                        width='100%'
                    >
                        <OutlinedInput
                            fullWidth
                            type='text'
                            multiline
                            minRows={5}
                            value={taskNote}
                            onChange={(e) => setTaskNote(e.target.value)}
                        />
                    </FormControl>
                </FormGroup>
                {/*
                <FormGroup>
                    <FormLabel>Completed</FormLabel>
                    <div>
                        <Checkbox color='primary' checked={taskCompleted} onChange={() => setTaskCompleted(!taskCompleted)} />
                    </div>
                </FormGroup>
                */}
            </DialogContent>
            <DialogActions>
                <Box width='100%' display='flex' justifyContent='space-between'>
                    <Button onClick={() => props.close()} color='primary'>
                        Close
                    </Button>
                    <Box>
                        <Box display='inline-block' marginRight='0.5rem'>
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={() => updateTask()}
                            >
                                Update
                            </Button>
                        </Box>
                        <Button
                            classes={{
                                root: classes.buttonContainedError,
                            }}
                            variant='contained'
                            onClick={() => showDeleteWarning()}
                        >
                            Delete
                        </Button>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
	)
}