import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Grid from '@material-ui/core/Grid'
import Loader from 'components/general/Loader'
import EnergyAPI from 'config/api/EnergyAPI'
import { onError } from 'config/lib/errorLib'
import CompaniesTable from 'components/admin/Energy/CompaniesTable'
import DeliveriesTable from 'components/admin/Energy/DeliveriesTable'
import LeadsTable from 'components/admin/Energy/LeadsTable'
import { getMoments } from 'config/helpers/Helpers'
import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function EnergyEfficiency() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const theme = useTheme()
    const api = new EnergyAPI()

    const [showStatus, setShowStatus] = useState('active')
    const [companies, setCompanies] = useState([])
    const [showCompanies, setShowCompanies] = useState([])
    const [deliveries, setDeliveries] = useState([])
    const [showDeliveries, setShowDeliveries] = useState([])
    const [showLeadsStatus, setShowLeadsStatus] = useState('Unallocated')
    const [leads, setLeads] = useState([])
    const [showLeads, setShowLeads] = useState([])
    const [period, setPeriod] = useState('thisWeek')
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        getCompanies()
    }, [])

    async function getCompanies() {
        try {
            const companies = await api.getCompanies()
            console.log('companies: ', companies)
            companies.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
            const activeCompanies = companies.filter((company) => (company.active))

            const deliveries = await api.getDeliveries()
            console.log('deliveries: ', deliveries)
            const activeDeliveries = deliveries.filter((delivery) => (delivery.active))

            const leads = await api.getLeads()
            console.log('leads: ', leads)
            leads.sort((a, b) => {
                return b.createdAt - a.createdAt
            })

            let dates = getMoments('thisWeek')

            // add all time count to deliveries
            for (let lead of leads) {
                let created = moment(lead.createdAt);
                if (created.isBetween(dates.fromDate, dates.toDate) && (lead.companyId || lead.isValid)) {
                    let index = deliveries.findIndex(delivery => (delivery.companyId === lead.companyId && delivery.product === lead.product))
                    if (index > -1) {
                        if (deliveries[index]['leadCount']) {
                            deliveries[index]['leadCount'] += 1
                        }
                        else {
                            deliveries[index]['leadCount'] = 1
                        }

                        if (deliveries[index]['revenue']) {
                            deliveries[index]['revenue'] += deliveries[index]['leadPrice']
                        }
                        else {
                            deliveries[index]['revenue'] = deliveries[index]['leadPrice']
                        }
                    }
                }
            }
            console.log('deliveries with lead count: ', deliveries)

            setCompanies(companies)
            setShowCompanies(activeCompanies)
            setDeliveries(deliveries)
            setShowDeliveries(activeDeliveries)
            setLeads(leads)
            setShowLeads(leads.filter((lead) => lead.companyId === null && lead.isValid))
            setFromDate(dates.fromDate.format('MMMM Do'))
            setToDate(dates.toDate.format('MMMM Do'))
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function updatePeriod(period) {
        setPeriod(period)
        let dates = getMoments(period)

        let newDeliveries = [...deliveries]
        newDeliveries.map((delivery) => {
            delivery['leadCount'] = 0
            delivery['revenue'] = 0
            return delivery
        })
        console.log('new deliveries: ', newDeliveries)

        for (let lead of leads) {
            let created = moment(lead.createdAt);
            if (created.isBetween(dates.fromDate, dates.toDate) && (lead.companyId || lead.isValid)) {
                let index = newDeliveries.findIndex(delivery => (delivery.companyId === lead.companyId && delivery.product === lead.product))
                if (index > -1) {
                    if (newDeliveries[index]['leadCount']) {
                        newDeliveries[index]['leadCount'] += 1
                    }
                    else {
                        newDeliveries[index]['leadCount'] = 1
                    }

                    if (newDeliveries[index]['revenue']) {
                        newDeliveries[index]['revenue'] += newDeliveries[index]['leadPrice']
                    }
                    else {
                        newDeliveries[index]['revenue'] = newDeliveries[index]['leadPrice']
                    }
                }
            }
        }
        console.log('newDeliveries with lead count: ', newDeliveries)

        let checkStatus = showStatus === 'active' ? true : false
        let showDeliveries = newDeliveries.filter((delivery) => (delivery.active === checkStatus))

        setDeliveries(newDeliveries)
        setShowDeliveries(showDeliveries)
        setFromDate(dates.fromDate.format('MMMM Do'))
        setToDate(dates.toDate.format('MMMM Do'))
    }

    function updateCompany(company) {
        const index = companies.findIndex(oldCompany => (oldCompany.companyId === company.companyId))
        console.log('company index: ', index)

        let newCompanies = [...companies]
        newCompanies[index] = company
        let checkStatus = showStatus === 'active' ? true : false
        let showCompanies = newCompanies.filter((company) => (company.active === checkStatus))
        console.log(showCompanies)
        setCompanies(newCompanies)
        setShowCompanies(showCompanies)
    }

    function updateDelivery(delivery) {
        const index = deliveries.findIndex(oldDelivery => ((oldDelivery.companyId === delivery.companyId) && (oldDelivery.product === delivery.product)))
        console.log('delivery index: ', index)

        let newDeliveries = [...deliveries]
        newDeliveries[index] = delivery
        console.log(newDeliveries)
        let checkStatus = showStatus === 'active' ? true : false
        let showDeliveries = newDeliveries.filter((delivery) => (delivery.active === checkStatus))
        console.log(showDeliveries)
        setDeliveries(newDeliveries)
        setShowDeliveries(showDeliveries)
    }

    function updateLead(lead) {
        const index = leads.findIndex(oldLead => (oldLead.leadId === lead.leadId))
        console.log('lead index: ', index)

        let newLeads = [...leads]
        newLeads[index] = lead
        console.log(newLeads)
        setLeads(newLeads)
        if (showLeadsStatus === 'Unallocated') {
            setShowLeads(newLeads.filter((lead) => lead.companyId === null && lead.isValid))
        }
        if (showLeadsStatus === 'Rejected') {
            setShowLeads(newLeads.filter((lead) => lead.companyId === null && !lead.isValid))
        }
    }

    function updateShowStatus(showStatus) {
        let checkStatus = showStatus === 'active' ? true : false
        let showCompanies = companies.filter((company) => (company.active === checkStatus))
        let showDeliveries = deliveries.filter((delivery) => (delivery.active === checkStatus))
        setShowCompanies(showCompanies)
        setShowDeliveries(showDeliveries)
        setShowStatus(showStatus)
    }

    function updateShowLeadsStatus(showLeadsStatus) {
        //let checkStatus = showStatus === 'paid' ? true : false
        let showLeads = []
        if (showLeadsStatus === 'Unallocated') {
            showLeads = leads.filter((lead) => lead.companyId === null && lead.isValid)
        }
        else if (showLeadsStatus === 'Allocated') {
            showLeads = leads.filter((lead) => lead.companyId !== null)
        }
        else if (showLeadsStatus === 'Rejected') {
            showLeads = leads.filter((lead) => lead.companyId === null && !lead.isValid)
        }
        setShowLeads(showLeads)
        setShowLeadsStatus(showLeadsStatus)
    }

    function showSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='-6rem'
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid
                                container
                                component={Box}
                                alignItems='center'
                                justifyContent='flex-start'
                                style={{marginBottom: '2rem'}}
                                direction='row'
                            >
                                <Grid item xs={12} md={2}>
                                    <FormGroup style={{marginBottom: '0'}}>
                                        <FormControl variant='outlined'>
                                            <Select
                                                multiple={false}
                                                defaultValue={null}
                                                IconComponent={KeyboardArrowDown}
                                                value={period}
                                                onChange={(e) => updatePeriod(e.target.value)}
                                            >
                                                <MenuItem value={'yesterday'}>Yesterday</MenuItem>
                                                <MenuItem value={'today'}>Today</MenuItem>
                                                <MenuItem value={'thisWeek'}>This Week</MenuItem>
                                                <MenuItem value={'lastWeek'}>Last Week</MenuItem>
                                                <MenuItem value={'thisMonth'}>This Month</MenuItem>
                                                <MenuItem value={'lastMonth'}>Last Month</MenuItem>
                                                <MenuItem value={'yearToDate'}>Year to Date</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                    <Box
                                        fontSize='12px'
                                        color={theme.palette.gray[600]}
                                        //marginBottom='0.2rem'
                                        //marginLeft='1rem'
                                        marginTop='0.5rem'
                                        textAlign='left'
                                        //className='online-discount'
                                    >
                                        <Box component='small' fontSize='100%'>
                                            Showing from {fromDate} - {toDate}
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                container
                                component={Box}
                                alignItems='center'
                                justifyContent='flex-start'
                                style={{marginBottom: '2rem'}}
                                direction='row'
                            >
                                <Grid item xs={12} md={2}>
                                    <FormGroup style={{marginBottom: '0'}}>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                value={showStatus}
                                                onChange={(e) => updateShowStatus(e.target.value)}
                                            >
                                                <MenuItem value='active'>Active</MenuItem>
                                                <MenuItem value='inactive'>Inactive</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                {/*
                                <Grid item xs={2}>
                                    <a href={getXeroLink()}>Link Xero</a>
                                </Grid>
                                */}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <CompaniesTable
                                companies={showCompanies}
                                updateCompany={(company) => updateCompany(company)}
                                refreshCompanies={() => getCompanies()}
                                showSuccessAlert={(text) => showSuccessAlert(text)}
                                title={showStatus === 'active' ? 'EE Companies' : 'Inactive EE Companies'}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <DeliveriesTable 
                                deliveries={showDeliveries}
                                companies={companies}
                                refreshDeliveries={() => getCompanies()}
                                updateDelivery={(delivery) => updateDelivery(delivery)}
                                showSuccessAlert={(text) => showSuccessAlert(text)}
                                title={showStatus === 'active' ? 'EE Deliveries' : 'Inactive EE Deliveries'}
                                site='ee'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                container
                                component={Box}
                                alignItems='center'
                                justifyContent='flex-start'
                                style={{marginBottom: '2rem'}}
                                direction='row'
                            >
                                <Grid item xs={12} md={2}>
                                    <FormGroup style={{marginBottom: '0'}}>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                value={showLeadsStatus}
                                                onChange={(e) => updateShowLeadsStatus(e.target.value)}
                                            >
                                                <MenuItem value={'Unallocated'}>Unallocated</MenuItem>
                                                <MenuItem value={'Allocated'}>Allocated</MenuItem>
                                                <MenuItem value={'Rejected'}>Rejected</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <LeadsTable
                                leads={showLeads}
                                title={showLeadsStatus}
                                refreshLeads={() => getCompanies()}
                                updateLead={(lead) => updateLead(lead)}
                                showSuccessAlert={(text) => showSuccessAlert(text)}
                                companies={companies}
                                site='ee'
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default EnergyEfficiency