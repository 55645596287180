import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import LeadsTable from 'components/admin/LeadsTable'
import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'
import { getMoments, getProductString } from 'config/helpers/Helpers'
import { productOptions } from 'config/constants/Constants'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function Leads() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const theme = useTheme()
    const api = new AdminAPI()

    const [leads, setLeads] = useState([])
    const [showLeads, setShowLeads] = useState([])
    const [showStatus, setShowStatus] = useState('Unallocated')
    const [period, setPeriod] = useState('thisWeek')
    const [sourceOptions, setSourceOptions] = useState([])
    const [source, setSource] = useState('all')
    const [product, setProduct] = useState('pensions')
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [companies, setCompanies] = useState([])
    // const [loadMore, setLoadMore] = useState(false)
    // const [moreLeadsLoading, setMoreLeadsLoading] = useState(false)
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        getLeads()
    }, [])

    async function getLeads() {
        try {
            let dates = getMoments('thisWeek')

            let leads = await api.getAllLeadsPeriod(product, dates.fromDate.valueOf(), dates.toDate.valueOf())
            let sourceOptions = new Set()
            leads.sort((a, b) => {
                sourceOptions.add(a.source)
                return b.createdAt - a.createdAt
            })
            console.log('leads size: ', leads.length)
            console.log('source options: ', sourceOptions)
            
            let companies = await api.getCompanies()
            console.log('companies: ', companies)
            const activeCompanies = companies.filter((company) => (company.active))
            activeCompanies.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })

            setCompanies(activeCompanies)
            setLeads(leads)
            setShowLeads(leads.filter((lead) => (lead.companyId === null && lead.isValid)))
            setFromDate(dates.fromDate.format('MMMM Do'))
            setToDate(dates.toDate.format('MMMM Do'))
            setSourceOptions(sourceOptions)
            //setLoadMore(response.loadMore)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function updateProduct(product) {
        setLoading(true) 
        let dates = getMoments(period)
        try {
            let leads = []
            if (product === 'all') {
                for (let product of productOptions) {
                    let productLeads = await api.getAllLeadsPeriod(product.value, dates.fromDate.valueOf(), dates.toDate.valueOf())
                    console.log(product.value + ': ', productLeads.length)
                    leads = leads.concat(productLeads)
                }
            }
            else {
                leads = await api.getAllLeadsPeriod(product, dates.fromDate.valueOf(), dates.toDate.valueOf())
            }
            let sourceOptions = new Set()
            leads.sort((a, b) => {
                sourceOptions.add(a.source)
                return b.createdAt - a.createdAt
            })
            console.log('leads size: ', leads.length)
            let showLeads = []
            if (showStatus === 'Unallocated') {
                showLeads = leads.filter((lead) => lead.companyId === null && lead.isValid)
            }
            else if (showStatus === 'Allocated') {
                showLeads = leads.filter((lead) => lead.companyId !== null)
            }
            else if (showStatus === 'Rejected') {
                showLeads = leads.filter((lead) => lead.companyId === null && !lead.isValid)
            }
            console.log('show leads: ', showLeads)
            console.log('product: ', product)
            setLeads(leads)
            setSourceOptions(sourceOptions)
            setSource('all')
            setShowLeads(showLeads)
            setProduct(product)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function updatePeriod(period) {
        setLoading(true) 
        let dates = getMoments(period)
        try {
            let leads = await api.getAllLeadsPeriod(product, dates.fromDate.valueOf(), dates.toDate.valueOf())
            leads.sort((a, b) => {
                return b.createdAt - a.createdAt
            })
            console.log('leads size: ', leads.length)
            let showLeads = []
            if (showStatus === 'Unallocated') {
                showLeads = leads.filter((lead) => lead.companyId === null && lead.isValid)
            }
            else if (showStatus === 'Allocated') {
                showLeads = leads.filter((lead) => lead.companyId !== null)
            }
            else if (showStatus === 'Rejected') {
                showLeads = leads.filter((lead) => lead.companyId === null && !lead.isValid)
            }
            console.log('show leads: ', showLeads)
            setLeads(leads)
            setShowLeads(showLeads)
            setPeriod(period)
            setFromDate(dates.fromDate.format('MMMM Do'))
            setToDate(dates.toDate.format('MMMM Do'))
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function updateSource(source) {
        //let checkStatus = showStatus === 'paid' ? true : false
        //setLeadsLoading(true)
        let showLeads = []
        if (showStatus === 'Unallocated') {
            showLeads = leads.filter((lead) => lead.companyId === null && lead.isValid)
        }
        else if (showStatus === 'Allocated') {
            showLeads = leads.filter((lead) => lead.companyId !== null)
        }
        else if (showStatus === 'Rejected') {
            showLeads = leads.filter((lead) => lead.companyId === null && !lead.isValid)
        }
        if (source !== 'all') {
            showLeads = showLeads.filter((lead) => lead.source === source)
        }
        console.log('show leads: ', showLeads)
        setShowLeads(showLeads)
        setSource(source)
        setLoading(false) 
    }

    function updateShowStatus(showStatus) {
        //let checkStatus = showStatus === 'paid' ? true : false
        //setLeadsLoading(true)
        let showLeads = []
        if (showStatus === 'Unallocated') {
            showLeads = leads.filter((lead) => lead.companyId === null && lead.isValid)
        }
        else if (showStatus === 'Allocated') {
            showLeads = leads.filter((lead) => lead.companyId !== null)
        }
        else if (showStatus === 'Rejected') {
            showLeads = leads.filter((lead) => lead.companyId === null && !lead.isValid)
        }
        if (source !== 'all') {
            showLeads = showLeads.filter((lead) => lead.source === source)
        }
        console.log('show leads: ', showLeads)
        setShowLeads(showLeads)
        setShowStatus(showStatus)
        //setLeadsLoading(false)
        setLoading(false) 
    }

    function updateLead(lead) {
        const index = leads.findIndex(oldLead => (oldLead.leadId === lead.leadId))
        console.log('lead index: ', index)

        let newLeads = [...leads]
        newLeads[index] = lead
        console.log(newLeads)
        //setLeads(newLeads.filter((lead) => lead.companyId === null))
        let showLeads = []
        if (showStatus === 'Unallocated') {
            showLeads = newLeads.filter((lead) => lead.companyId === null && lead.isValid)
        }
        else if (showStatus === 'Allocated') {
            showLeads = newLeads.filter((lead) => lead.companyId !== null)
        }
        else if (showStatus === 'Rejected') {
            showLeads = newLeads.filter((lead) => lead.companyId === null && !lead.isValid)
        }
        console.log('show leads: ', showLeads)
        setShowLeads(showLeads)
        setLeads(newLeads)
    }

    function showSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='-6rem'
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                <Grid container>
                    <Grid item xs={12}>
                        <Grid
                            container
                            component={Box}
                            alignItems='center'
                            justifyContent='flex-start'
                            style={{marginBottom: '2rem'}}
                            direction='row'
                        >
                            <Grid item xs={12} md={2}>
                                <FormGroup style={{marginBottom: '0'}}>
                                    <FormControl variant='outlined'>
                                        <Select
                                            multiple={false}
                                            defaultValue={null}
                                            IconComponent={KeyboardArrowDown}
                                            value={period}
                                            onChange={(e) => updatePeriod(e.target.value)}
                                        >
                                            <MenuItem value={'yesterday'}>Yesterday</MenuItem>
                                            <MenuItem value={'today'}>Today</MenuItem>
                                            <MenuItem value={'thisWeek'}>This Week</MenuItem>
                                            <MenuItem value={'lastWeek'}>Last Week</MenuItem>
                                            <MenuItem value={'thisMonth'}>This Month</MenuItem>
                                            <MenuItem value={'lastMonth'}>Last Month</MenuItem>
                                            <MenuItem value={'yearToDate'}>Year to Date</MenuItem>
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                                <Box
                                    fontSize='12px'
                                    color={theme.palette.gray[600]}
                                    //marginBottom='0.2rem'
                                    //marginLeft='1rem'
                                    marginTop='0.5rem'
                                    textAlign='left'
                                    //className='online-discount'
                                >
                                    <Box component='small' fontSize='100%'>
                                        {fromDate} - {toDate}
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormGroup style={{marginBottom: '0'}}>
                                    <FormControl variant='outlined'>
                                        <Select
                                            multiple={false}
                                            defaultValue={null}
                                            IconComponent={KeyboardArrowDown}
                                            value={product}
                                            onChange={(e) => updateProduct(e.target.value)}
                                        >
                                            <MenuItem value={'all'}>All</MenuItem>
                                            {productOptions.map((option) => {
                                                return (<MenuItem value={option.value}>{option.label}</MenuItem>)
                                            })}
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                                <Box
                                    fontSize='12px'
                                    color={theme.palette.gray[600]}
                                    //marginBottom='0.2rem'
                                    //marginLeft='1rem'
                                    marginTop='0.5rem'
                                    textAlign='left'
                                    //className='online-discount'
                                >
                                    <Box component='small' fontSize='100%' className='invisible-text'>
                                        aaa
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormGroup style={{marginBottom: '0'}}>
                                    <FormControl variant='outlined'>
                                        <Select
                                            multiple={false}
                                            defaultValue={null}
                                            IconComponent={KeyboardArrowDown}
                                            value={source}
                                            onChange={(e) => updateSource(e.target.value)}
                                        >
                                            <MenuItem value={'all'}>All</MenuItem>
                                            {[...sourceOptions].map((option) => {
                                                return (<MenuItem value={option}>{option}</MenuItem>)
                                            })}
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                                <Box
                                    fontSize='12px'
                                    color={theme.palette.gray[600]}
                                    //marginBottom='0.2rem'
                                    //marginLeft='1rem'
                                    marginTop='0.5rem'
                                    textAlign='left'
                                    //className='online-discount'
                                >
                                    <Box component='small' fontSize='100%' className='invisible-text'>
                                        aaa
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormGroup style={{marginBottom: '0'}}>
                                    <FormControl variant='outlined' fullWidth>
                                        <Select
                                            multiple={false}
                                            value={showStatus}
                                            onChange={(e) => updateShowStatus(e.target.value)}
                                        >
                                            <MenuItem value={'Unallocated'}>Unallocated</MenuItem>
                                            <MenuItem value={'Allocated'}>Allocated</MenuItem>
                                            <MenuItem value={'Rejected'}>Rejected</MenuItem>
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                                <Box
                                    fontSize='12px'
                                    color={theme.palette.gray[600]}
                                    //marginBottom='0.2rem'
                                    //marginLeft='1rem'
                                    marginTop='0.5rem'
                                    textAlign='left'
                                    //className='online-discount'
                                >
                                    <Box component='small' fontSize='100%' className='invisible-text'>
                                        aaa
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                        <Grid item xs={12}>
                            <LeadsTable
                                leads={showLeads}
                                title={showStatus}
                                refreshLeads={() => getLeads()}
                                updateLead={(lead) => updateLead(lead)}
                                showSuccessAlert={(text) => showSuccessAlert(text)}
                                companies={companies}
                                product={product}
                                showStatus={showStatus}
                            />
                        </Grid>
                </Grid>
                ) : (
                    <Grid container>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                            <Loader />
                        </Box>
                    </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default Leads