import React from 'react'
import Chart from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'

import { chartOptions, parseOptions } from 'config/constants/ChartVariables'

import componentStyles from 'assets/theme/components/cards/charts/card-total-orders.js'

const useStyles = makeStyles(componentStyles)

const themeColors = require('assets/theme/colors.js').default

var colors = {
    gray: themeColors.gray,
    theme: {
        default: themeColors.dark.main,
        primary: themeColors.primary.main,
        secondary: themeColors.secondary.main,
        info: themeColors.info.main,
        success: themeColors.success.main,
        danger: themeColors.error.main,
        warning: themeColors.warning.main,
    },
    black: themeColors.black.light,
    white: themeColors.white.main,
    transparent: themeColors.transparent.main,
}

const options = {
    scales: {
      yAxes: [
            {
                gridLines: {
                    color: colors.gray[200],
                    zeroLineColor: colors.gray[200],
                },
                ticks: {},
            },
        ],
    },
}

function PolicyStats(props) {
    const classes = useStyles()
    const theme = useTheme()
    if (window.Chart) {
        parseOptions(Chart, chartOptions())
    }
    
    console.log(props.data)

    const data = {
        labels: Object.keys(props.data),
        datasets: [
            {
                label: props.product === 'pensions' ? 'Transferred (€)' : 'Annual Premiums (€)',
                data: Object.values(props.data),
                maxBarThickness: 10,
            },
        ]
    }
  
    return (
        <>
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    title={
                        <Box component='span' color={theme.palette.gray[600]}>
                            
                        </Box>
                    }
                    subheader={props.product === 'pensions' ? 'Total Transferred (Last 6 months)' : 'Annual Premiums Closed (Last 6 months)'}
                    classes={{ root: classes.cardHeaderRoot }}
                    titleTypographyProps={{
                        component: Box,
                        variant: 'h2',
                        letterSpacing: '2px',
                        marginBottom: '0!important',
                        classes: {
                            root: classes.textUppercase,
                        },
                    }}
                    subheaderTypographyProps={{
                        component: Box,
                        variant: 'h2',
                        marginBottom: '0!important',
                        color: 'initial',
                    }}
                ></CardHeader>
                
                <CardContent>
                    <Box position='relative' height='350px'>
                        <Bar data={data} options={options} />
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}

export default PolicyStats
