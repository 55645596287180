import React, { useEffect, useRef } from 'react'
import { useLocation, Switch, Route, Redirect } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import componentStyles from 'assets/theme/layouts/auth.js'

import Login from 'scenes/guest/Login'
import Verify from 'scenes/guest/Verify'
import Topup from 'scenes/guest/Topup'

const useStyles = makeStyles(componentStyles)

const Main = () => {
    const classes = useStyles()
    const mainContent = useRef(null)
    const location = useLocation()

    useEffect(() => {
        document.body.classList.add(classes.bgDefault)
        return () => {
            document.body.classList.remove(classes.bgDefault)
        }
    })

    useEffect(() => {
        document.documentElement.scrollTop = 0
        document.scrollingElement.scrollTop = 0
        mainContent.current.scrollTop = 0
    }, [location])

    return (
        <>
            <div className='main-content guest' ref={mainContent}>
                <Switch>
                    <Route exact path='/login' component={Login} />
                    <Route exact path='/verify' component={Verify} />
                    <Route exact path='/topup' component={Topup} />
                    <Redirect from='*' to='/login' />
                </Switch>
            </div>
        </>
    )
}

export default Main