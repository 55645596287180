import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
//import QuotesModal from 'components/broker/QuotesModal'
import BrokerAPI from 'config/api/BrokerAPI'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
import { providerOptions, riskLevelOptions } from 'config/constants/Constants'
import { getProductString } from 'config/helpers/Helpers'
import { smokingStatusOptions } from 'config/constants/Constants'

import DetailsContext from 'config/context/DetailsContext'

function LIQuoteDetails(props) {
    const api = props.admin ? new AdminAPI() : new BrokerAPI()
    const companyDetails = useContext(DetailsContext)
	const companyId = companyDetails.companyId || props.companyId

    const quote = props.lead.quote
    
    const [provider, setProvider] = useState(quote.provider || '')
    const [premium, setPremium] = useState(quote.premium || '')
    const [coverAmount, setCoverAmount] = useState(quote.coverAmount || '')
    const [siCoverAmount, setSiCoverAmount] = useState(quote.siCoverAmount || '')
    const [policyTerm, setPolicyTerm] = useState(quote.policyTerm || '')
    const [type, setType] = useState(quote.type || 'Single')
    const [partnerSmokingStatus, setPartnerSmokingStatus] = useState(getSmokingStatus(quote.partnerSmokingStatus))
    const [partnerDob, setPartnerDob] = useState(quote.partnerDob || '')
    const [showContent, setShowContent] = useState(props.showQuoteContent)
    const [showQuotesModal, setShowQuotesModal] = useState(false)

    function getSmokingStatus(smokingStatus) {
        let displaySs = ''
        if (smokingStatus && smokingStatus.toLowerCase() === 'non-smoker') {
            displaySs = 'Non Smoker'
        }
        else {
            displaySs = smokingStatus
        }
        return displaySs
    }
 
    async function updateQuote() {
        try {
            let quote = {
                provider,
                premium,
                coverAmount,
                siCoverAmount,
                policyTerm,
                type,
                partnerSmokingStatus,
                partnerDob
            }
            
            let result = null
            if (props.admin) {
                result = await api.updateLeadQuote(props.lead.date, props.lead.leadId, quote)
            }
            else {
                result = await api.updateLeadQuote(companyId, props.lead.product, props.lead.phone, quote)
            }

            props.updateLead(result)
            NotificationManager.success('Details updated')
            setShowContent(false)
        } catch(e) {
            onError(e)
        }
    }

    function updatePartnerDob(val) {
        let updated = val.replace(/^(\d{2})(\d{2})/, '$1/$2/')
        setPartnerDob(updated)
    }

    // function updatePremium(premium) {
    //     let formatted = premium.replace(/€/g, '')
    //     setPremium(formatted)
    // }
    
    return (
        <Card>
            <CardHeader
                subheader={
                    <Grid
                        container
                        component={Box}
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Grid item xs='auto'>
                            <Box
                                component={Typography}
                                variant='h3'
                                marginBottom='0!important'
                            >
                                Life Insurance Details
                            </Box>
                        </Grid>
                        <Grid item xs='auto'>
                            <Box>
                                {showContent ? (
                                    <KeyboardArrowUp />
                                ) : (
                                    <KeyboardArrowDown />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                }
                onClick={() => setShowContent(!showContent)} 
                style={{cursor: 'pointer'}}
            >
            </CardHeader>
            
            {showContent &&
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Provider</FormLabel>
                                <FormControl variant='outlined' fullWidth>
                                    <Select
                                        multiple={false}
                                        defaultValue={''}
                                        IconComponent={KeyboardArrowDown}
                                        value={provider}
                                        onChange={(e) => setProvider(e.target.value)}
                                    >
                                        {providerOptions.map((option, i) => {
                                            return (<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
                                        })}
                                    </Select>
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>{companyId === 'cib' ? 'Monthly Income/Commission (€)' : 'Monthly Premium (€)'}</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    autoFocus
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='number'
                                        value={premium}
                                        onChange={(e) => setPremium(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Cover Amount</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={coverAmount}
                                        onChange={(e) => setCoverAmount(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Serious Illness Cover Amount (€)</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={siCoverAmount}
                                        onChange={(e) => setSiCoverAmount(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Policy Term (years)</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={policyTerm}
                                        onChange={(e) => setPolicyTerm(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Application Type</FormLabel>
                                <FormControl variant='outlined' fullWidth>
                                    <Select
                                        multiple={false}
                                        IconComponent={KeyboardArrowDown}
                                        value={type}
                                        onChange={(e) => setType(e.target.value)}
                                    >
                                        <MenuItem value='Single'>Single</MenuItem>
                                        <MenuItem value='Dual'>Dual</MenuItem>
                                    </Select>
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        
                        {type === 'Dual' &&
                            <>
                                <Grid item xs={12} lg={6}>
                                    <FormGroup>
                                        <FormLabel>Partner Smoking Status</FormLabel>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                IconComponent={KeyboardArrowDown}
                                                value={partnerSmokingStatus}
                                                //error={smokingStatusValidation === 'invalid'}
                                                onChange={(e) => setPartnerSmokingStatus(e.target.value)}
                                            >
                                                {smokingStatusOptions.map((option, i) => {
                                                    return (<MenuItem value={option.value} key={i}>{option.label}</MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormGroup>
                                        <FormLabel>Partner Date of Birth</FormLabel>
                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                            //marginBottom='1rem!important'
                                        >
                                            <OutlinedInput
                                                fullWidth
                                                type='text'
                                                value={partnerDob}
                                                onChange={(e) => {updatePartnerDob(e.target.value)}}
                                            />
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </>
                        }
                        
                        <Grid item xs={12} lg={12}>
                            <Grid container justifyContent='space-between'>
                                <Grid item>
                                    {/*
                                    <Button
                                        onClick={() => setShowQuotesModal(true)}
                                        color='primary'
                                        disabled
                                    >
                                        Generate Quotes
                                    </Button>
                                    */}
                                </Grid>
                                <Grid item>
                                    <Button
                                        component={Box}
                                        color='primary'
                                        variant='contained'
                                        size='medium'
                                        disabled={false}
                                        onClick={() => updateQuote()}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/*showQuotesModal &&
                        <QuotesModal 
                            isOpen={showQuotesModal}
                            close={() => setShowQuotesModal(false)}
                            clientDetails={props.clientDetails}
                            application={application}
                            updateApplication={(provider, premium, noPeople, coverAmount, coverAmount2, illnessCover, illnessCoverAmount, illnessCoverAmount2, policyTerm, convertibleOption, indexation) => updateApplication(provider, premium, noPeople, coverAmount, coverAmount2, illnessCover, illnessCoverAmount, illnessCoverAmount2, policyTerm, convertibleOption, indexation)}
                            type={props.type}
                        />
                    */}
                </CardContent>
            }
        </Card>
    )
}

export default LIQuoteDetails