import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Select from '@mui/material/Select'
import MenuItem from '@material-ui/core/MenuItem'
import EnergyAPI from 'config/api/EnergyAPI'
import { onError } from 'config/lib/errorLib'
import { eeProductOptions } from 'config/constants/Constants'
import { NotificationManager } from 'react-notifications'

//import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'
import componentStylesForms from 'assets/theme/components/forms.js'
import componentStylesButtons from 'assets/theme/components/button.js'

//const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)
const useStylesForms = makeStyles(componentStylesForms)
const useStylesButtons = makeStyles(componentStylesButtons)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function DeliveryModal(props) {
	const classes = { ...useStylesDialog(), ...useStylesForms(), ...useStylesButtons() }
  	//const theme = useTheme()
  	const api = new EnergyAPI()

    const delivery = props.delivery
    const deliveries = props.deliveries
    const companies = props.companies

    const [company, setCompany] = useState(delivery.companyId)
    const [product, setProduct] = useState(delivery.product)
    const [leadPrice, setLeadPrice] = useState(delivery.leadPrice)
    const [webhook, setWebhook] = useState(delivery.webhook)
    const [weight, setWeight] = useState(delivery.weight)
    const [weeklyCap, setWeeklyCap] = useState(delivery.weeklyCap)
    const [active, setActive] = useState(delivery.active === true ? true : false)
    
    async function updateDelivery() {
        try {
            // let formattedStartDate = new Date(startDate)
            // console.log('formatted start date: ', formattedStartDate)

            try {
                let updatedDelivery = props.site === 'sf' ? await api.updateSfDelivery(delivery.companyId, delivery.product, leadPrice, webhook, weight, weeklyCap, active) : await api.updateDelivery(delivery.companyId, delivery.product, leadPrice, webhook, weight, active)
                console.log('delivery: ', updatedDelivery)

                props.updateDelivery(updatedDelivery)
                props.showSuccessAlert('Delivery updated!')
                props.close()
            } catch(e) {
                onError(e)
            }
        } catch(e) {
            onError(e)
        }
    }

	return (
        <Dialog
            open={props.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => props.close()}
        >
            {alert}
            <div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Update Delivery
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
            <DialogContent>
                <FormGroup>
                    <FormLabel>Company</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                            disabled
                        >
                            {companies.map((company) => {
                                return (
                                    <MenuItem value={company.companyId}>{company.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Product</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            value={product}
                            onChange={(e) => setProduct(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            {eeProductOptions.map((product) => {
                                return (
                                    <MenuItem value={product.value}>{product.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Lead Price (€)</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={leadPrice}
                    	onChange={(e) => setLeadPrice(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Webhook</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={webhook}
                    	onChange={(e) => setWebhook(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Weight</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='number'
                        value={weight}
                    	onChange={(e) => setWeight(e.target.value)}
                  	/>
                </FormGroup>
                {props.site === 'sf' &&
                    <FormGroup>
                        <FormLabel>Weekly Cap</FormLabel>
                        <OutlinedInput
                            fullWidth
                            type='number'
                            value={weeklyCap}
                            onChange={(e) => setWeeklyCap(e.target.value)}
                        />
                    </FormGroup>
                }
                <FormGroup>
                    <FormLabel>Active</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={active}
                            onChange={(e) => setActive(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
			</DialogContent>
            <DialogActions>
                <Box width='100%' display='flex' justifyContent='space-between'>
                    <Button onClick={() => props.close()} color='primary'>
                        Close
                    </Button>
                    <Box>
                        <Box display='inline-block' marginRight='0.5rem'>
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={() => updateDelivery()}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
	)
}