import React from 'react'
import Grid from '@material-ui/core/Grid'
import OrdersIcon from '@material-ui/icons/ListAlt'
import SalesIcon from '@material-ui/icons/EuroSymbol'
import TeamIcon from '@material-ui/icons/Group'

// core components
import CardStats from 'components/argon/Cards/Dashboard/CardStats.js'

const LeadStats = ( props ) => {

    const totals = props.totals
    const direction = props.direction
    
    return (
        <Grid container direction={direction} spacing={1}>
            <Grid item xs={12} md={direction === 'row' ? 4 : 12}>
                <CardStats
                    subtitle='Leads'
                    title={totals.leads}
                    icon={TeamIcon}
                    color='bgWarning'
                    // footer={
                    //     <>
                    //         <Box
                    //             component='span'
                    //             fontSize='.875rem'
                    //             color={theme.palette.success.main}
                    //             marginRight='.5rem'
                    //             display='flex'
                    //             alignItems='center'
                    //         >
                    //             <Box
                    //                 component={ArrowUpward}
                    //                 width='1.25rem!important'
                    //                 height='1.25rem!important'
                    //                 marginLeft='-.25rem'
                    //             />{' '}
                    //             10%
                    //         </Box>
                    //         <Box component='span' whiteSpace='nowrap'>
                    //             Since last month
                    //         </Box>
                    //     </>
                    // }
                />
            </Grid>
            
            <Grid item xs={12} md={direction === 'row' ? 4 : 12}>
                <CardStats
                    subtitle='Won'
                    title={totals.won}
                    icon={OrdersIcon}
                    color='bgSuccess'
                    // footer={
                    //     <>
                    //         <Box
                    //             component='span'
                    //             fontSize='.875rem'
                    //             color={theme.palette.success.main}
                    //             marginRight='.5rem'
                    //             display='flex'
                    //             alignItems='center'
                    //         >
                    //             <Box
                    //                 component={ArrowUpward}
                    //                 width='1.25rem!important'
                    //                 height='1.25rem!important'
                    //                 marginLeft='-.25rem'
                    //             />{' '}
                    //             20%
                    //         </Box>
                    //         <Box component='span' whiteSpace='nowrap'>
                    //             Since last month
                    //         </Box>
                    //     </>
                    // }
                />
            </Grid>
            <Grid item xs={12} md={direction === 'row' ? 4 : 12}>
                <CardStats
                    subtitle={props.product === 'pensions' ? 'Transferred (€)' : (props.companyId === 'cib' ? 'Annual Income/Commission (€)' : 'Annual Premiums (€)')}
                    title={'€' + (totals.premiums ? totals.premiums.toFixed(2) : '0')}
                    icon={SalesIcon}
                    color='bgPrimary'
                    // footer={
                    //     <>
                    //         <Box
                    //             component='span'
                    //             fontSize='.875rem'
                    //             color={theme.palette.success.main}
                    //             marginRight='.5rem'
                    //             display='flex'
                    //             alignItems='center'
                    //         >
                    //             <Box
                    //                 component={ArrowUpward}
                    //                 width='1.25rem!important'
                    //                 height='1.25rem!important'
                    //                 marginLeft='-.25rem'
                    //             />{' '}
                    //             10%
                    //         </Box>
                    //         <Box component='span' whiteSpace='nowrap'>
                    //             Since last month
                    //         </Box>
                    //     </>
                    // }
                />
            </Grid>
        </Grid>
    )
}

export default LeadStats
