import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import Button from '@material-ui/core/Button'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'

import { NotificationManager } from 'react-notifications'

function ValidationList(props) {
    const api = new AdminAPI()

    const [items, setItems] = useState(props.items || [])

    async function updateList() {
        const newList = items.split('\n')
        try {
            const result = await api.updateValidationList(props.list, newList)
            NotificationManager.success('List updated')
            props.updateLists(result)
        } catch(e) {
            onError(e)
        }
    }

    return (
        <>
            <FormGroup>
                <FormLabel>{props.title}</FormLabel>
                <FormControl variant='outlined' fullWidth>
                    <OutlinedInput
                        fullWidth
                        type='text'
                        value={items}
                        onChange={(e) => setItems(e.target.value)}
                        multiline
                        rows={8}
                    />
                </FormControl>
            </FormGroup>
            <Button
                component={Box}
                color='primary'
                marginLeft='auto!important'
                variant='contained'
                size='small'
                onClick={() => updateList()}
            >
                Update
            </Button>
        </>
    )
}

export default ValidationList