import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Select from '@mui/material/Select'
import MenuItem from '@material-ui/core/MenuItem'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'

//import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'
import componentStylesForms from 'assets/theme/components/forms.js'
import componentStylesButtons from 'assets/theme/components/button.js'

//const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)
const useStylesForms = makeStyles(componentStylesForms)
const useStylesButtons = makeStyles(componentStylesButtons)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function CampaignModal(props) {
	const classes = { ...useStylesDialog(), ...useStylesForms(), ...useStylesButtons() }
  	//const theme = useTheme()
  	const api = new AdminAPI()

    const campaign = props.campaign

    const [name, setName] = useState(campaign.name)
    const [product, setProduct] = useState(campaign.product)
    //const [sources, setSources] = useState(campaign.sources)
    const [target, setTarget] = useState(campaign.target)
    
    async function updateCampaign() {
        try {
            let result = await api.updateCampaign(campaign.campaignId, name, target)
            console.log('result: ', result)

  			props.updateCampaign(result)
  			NotificationManager.success('Campaign updated')
            props.close()
  		} catch(e) {
  			onError(e)
  		}
    }

	return (
        <Dialog
            open={props.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => props.close()}
        >
            {alert}
            <div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Update Campaign
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
            <DialogContent>
                <FormGroup>
                  	<FormLabel>Name</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={name}
                    	onChange={(e) => setName(e.target.value)}
                        disabled
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Product</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={product}
                    	onChange={(e) => setProduct(e.target.value)}
                        disabled
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Weekly Target (€)</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={target}
                    	onChange={(e) => setTarget(e.target.value)}
                  	/>
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Box width='100%' display='flex' justifyContent='space-between'>
                    <Button onClick={() => props.close()} color='primary'>
                        Close
                    </Button>
                    <Box>
                        <Box display='inline-block' marginRight='0.5rem'>
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={() => updateCampaign()}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
	)
}