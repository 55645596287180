import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import PaymentMethodModal from 'components/broker/PaymentMethodModal'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
import Loader from 'components/general/Loader'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import config from 'config/api/Config'

import componentStyles from 'assets/theme/components/cards/cards/card-button.js'
import componentStylesBadge from 'assets/theme/components/badge.js'

const stripePromise = loadStripe(config.stripeKey)

const useStyles = makeStyles(componentStyles)
const useStylesBadge = makeStyles(componentStylesBadge)

function PaymentMethodCard(props) {
    const classes = { ...useStyles(), ...useStylesBadge() }
    //console.log('details: ', details)
    const api = new BrokerAPI()
    const history = useHistory()

    const paymentMethod = props.paymentMethod

    const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false)
    const [clientSecret, setClientSecret] = useState(null)
    //const [paymentMethod, setPaymentMethod] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getSetupIntent()
    }, [])

    async function getSetupIntent() {
    	try {   
            const setupIntent = await api.getSetupIntent(props.companyDetails.companyId, props.billingEmail)
            console.log('setup intent: ', setupIntent)

            // let paymentMethods = null
            // if (props.companyDetails.stripeCustomerId) {
            //     paymentMethods = await api.getPaymentMethods(props.companyDetails.stripeCustomerId)
            // }
            // console.log('payment methods: ', paymentMethods)
            //console.log(paymentMethods.paymentMethods.data[0])

            setClientSecret(setupIntent.setupIntent)

            if (history.location.state && history.location.state.showPaymentMethodModal) {
                setShowPaymentMethodModal(true)
            }
            else {
                console.log('no history')
            }
        } catch(e) {
            onError(e)
        }
        setLoading(false)
    }
    
    return (
        <Card
            classes={{
                root: classes.cardRoot,
            }}
        >
            <CardHeader
                subheader={
                    <Grid
                        container
                        component={Box}
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Grid item xs='auto'>
                            <Box
                                component={Typography}
                                variant='h3'
                                marginBottom='0!important'
                            >
                                Payment Method
                            </Box>
                        </Grid>
                        
                        {/*<Grid item xs='auto'>
                            <Box
                                justifyContent='flex-end'
                                display='flex'
                                flexWrap='wrap'
                            >
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size='small'
                                    style={{marginRight: '0.5rem'}}
                                    onClick={() => setShowAddBrokerModal(true)}
                                    //disabled={true}
                                >
                                    Add Broker
                                </Button>
                            </Box>
                        </Grid>*/}
                    </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
            >
            </CardHeader>
            
            <CardContent>
                {!loading ? (
                    <Grid container>
                        <Grid item xs={12} lg={12}>  
                            {paymentMethod ? (
                                <p className='hover-text' onClick={() => setShowPaymentMethodModal(true)}>{paymentMethod.card.brand.toUpperCase()} ending {paymentMethod.card.last4}</p>
                            ) : (
                                <p className='hover-text' onClick={() => setShowPaymentMethodModal(true)}>Add payment method</p>
                            )}
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center'>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </CardContent>

            {showPaymentMethodModal &&
                <Elements stripe={stripePromise} options={{clientSecret}}>
                    <PaymentMethodModal
                        isOpen={showPaymentMethodModal}
                        close={() => setShowPaymentMethodModal(false)}
                        billingEmail={props.billingEmail}
                        clientSecret={clientSecret}
                        showSuccessAlert={(text) => props.showSuccessAlert(text)}
                    />
                </Elements>
            }
        </Card>
    );
}

export default PaymentMethodCard