import React from 'react'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Select from '@mui/material/Select'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'

import { countyOptions } from 'config/constants/Constants'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  	PaperProps: {
    	style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
    	},
  	},
};

function CountySelect(props) {
    const counties = props.counties
    
    async function updateCounties(counties) {
        if (counties.indexOf('All') > -1) {
            counties = ['All']
        }
        props.updateCounties(counties)
	}
    
    return (
        <Select
            multiple={true}
            value={counties}
            onChange={(e) => updateCounties(e.target.value)}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
            input={<OutlinedInput />}
        >
            {countyOptions.map((option, i) => {
                return (
                    <MenuItem value={option.value} key={i} disabled={counties.indexOf('All') > -1 && option.value !== 'All'}>
                        <Checkbox checked={counties.indexOf(option.value) > -1} color='primary' />
                        <ListItemText primary={option.label} className='checkbox-menu-item' />
                    </MenuItem>
                )
            })}
        </Select>
    )
}

export default CountySelect