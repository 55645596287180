import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import { getProductString } from 'config/helpers/Helpers'
import { NotificationManager } from 'react-notifications'
import Loader from 'components/general/Loader'
import componentStylesDialog from 'assets/theme/components/dialog.js'
import componentStylesForms from 'assets/theme/components/forms.js'
import componentStylesButtons from 'assets/theme/components/button.js'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

//const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)
const useStylesForms = makeStyles(componentStylesForms)
const useStylesButtons = makeStyles(componentStylesButtons)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function ReturnRequestModal(props) {
	const classes = { ...useStylesDialog(), ...useStylesForms(), ...useStylesButtons() }
  	//const theme = useTheme()
  	const api = new AdminAPI()

    const request = props.request

    const [alert, setAlert] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [pricing, setPricing] = useState(null)
    const [leadDetails, setLeadDetails] = useState(null)
    const [loading, setLoading] = useState(false)
    
    async function acceptRequest() {
        let currentValue = leadDetails ? leadDetails.quote.currentValue : null
        try {
            const result = await api.acceptReturnRequest(request, pricing, currentValue)
            console.log('accept request: ', result)

            //NotificationManager.success('Request accepted')
            props.updateRequest(request)
            props.showSuccessAlert('Return Request Accepted')
            props.close()
        } catch(e) {
            onError(e)
        }
    }

    async function rejectRequest() {
        try {
            const result = await api.rejectReturnRequest(request)
            console.log('reject request: ', result)

            //NotificationManager.success('Request rejected')
            props.updateRequest(request)
            props.showSuccessAlert('Return Request Rejected')
            props.close()
        } catch(e) {
            onError(e)
        }
    }

    async function getPricing() {
        setLoading(true)
        setShowAlert(true)
        try {
            let pricing = null
            let leadDetails = null
            
            if (request.product === 'pensions') {
                try {
                    pricing = await api.getBalanceDeliveryPricing(request.companyId, request.product)
                    leadDetails = await api.getLead(request.companyId, request.product, request.phone)
                } catch(e) {
                    onError(e)
                }
            }
            console.log('pricing: ', pricing)
            console.log('lead details: ', leadDetails)
            setPricing(pricing)
            setLeadDetails(leadDetails)
            setLoading(false)
        } catch(e) {
            onError(e)
            NotificationManager.error('Error getting pricing')
        }
	}

    function showRejectWarning() {
        setAlert(
            <ReactBSAlert
                warning
                style={{ display: 'block', marginTop: '-100px' }}
                title='Are you sure?'
                onConfirm={() => rejectRequest()}
                onCancel={() => setAlert(false)}
                cancelBtnCssClass='btn-secondary'
                confirmBtnBsStyle='danger'
                cancelBtnText='Cancel'
                confirmBtnText='Yes, Reject'
                showCancel
                btnSize=''
            >
                This will remove the lead replacement request permanently.
                <br/>
                <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '1rem', marginBottom: '1rem'}}>
                    <Box className='normal-text'>- This will leave a note on the lead</Box>
                    <Box className='normal-text'>- This will prevent further replacement requests on the lead</Box>
                    <Box className='normal-text'>- This will NOT send an email to the broker</Box>
                </Box>
            </ReactBSAlert>
        )
    }

	return (
        <Dialog
            open={props.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => props.close()}
        >
            {alert}
            <div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Review Request
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
            <DialogContent>
                <FormGroup>
                    <FormLabel>Broker</FormLabel>
                    <FormControl
                        variant='filled'
                        component={Box}
                        width='100%'
                    >
                        <Box className='normal-text'>{request.companyName}</Box>
                        <Box className='normal-text'>{request.brokerName}</Box>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Lead</FormLabel>
                    <FormControl
                        variant='filled'
                        component={Box}
                        width='100%'
                    >
                        <Box className='normal-text'>Product: {getProductString(request.product)}</Box>
                        <Box className='normal-text'>Name: {request.leadName}</Box>
                        <Box className='normal-text'>Phone: {request.phone}</Box>
                        <Box className='normal-text'>Email: {request.email}</Box>
                        <Box className='normal-text'>Lead Created: {moment(request.leadCreatedAt).format('DD/MM/YYYY HH:mm')}</Box>
                        <Box className='normal-text'>Request Made: {moment(request.createdAt).format('DD/MM/YYYY HH:mm')}</Box>
                        <Box className='normal-text'>Request Status: {request.status}</Box>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Reason</FormLabel>
                    <FormControl
                        variant='filled'
                        component={Box}
                        width='100%'
                    >
                        <Box className='normal-text'>{request.reason}</Box>
                    </FormControl>
                </FormGroup>
                {request.returnedBy &&
                    <FormGroup>
                        <FormLabel>Returned By</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <Box className='normal-text'>{request.returnedBy.firstName + ' ' + request.returnedBy.lastName}</Box>
                        </FormControl>
                    </FormGroup>
                }
            </DialogContent>
            <DialogActions>
                <Box width='100%' display='flex' justifyContent='space-between'>
                    <Button onClick={() => props.close()} color='primary'>
                        Close
                    </Button>
                    {request.status === 'pending' &&
                        <Box>
                            <Box display='inline-block' marginRight='0.5rem'>
                                <Button
                                    color='primary'
                                    variant='contained'
                                    onClick={() => getPricing()}
                                >
                                    Accept
                                </Button>
                            </Box>
                            <Button
                                classes={{
                                    root: classes.buttonContainedError,
                                }}
                                variant='contained'
                                onClick={() => showRejectWarning()}
                            >
                                Reject
                            </Button>
                        </Box>
                    }  
                </Box>
            </DialogActions>

            {showAlert &&
                <ReactBSAlert
                    warning
                    style={{ display: 'block', marginTop: '-100px' }}
                    title='Are you sure?'
                    onConfirm={() => acceptRequest()}
                    onCancel={() => setShowAlert(false)}
                    cancelBtnCssClass='btn-secondary'
                    confirmBtnBsStyle='success'
                    cancelBtnText='Cancel'
                    confirmBtnText='Yes, Accept'
                    disabled={loading}
                    showCancel
                    btnSize=''
                >
                    This increase the brokers allocation by 1 to allow space for a replacement lead.
                    <Box style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '1rem'}}>
                        <Box className='normal-text'>- This will leave a note on the lead</Box>
                        <Box className='normal-text'>- The broker will not be able to request a replacement on the replacement lead</Box>
                        <Box className='normal-text'>- This WILL send an email to the broker</Box>
                    </Box>
                    <Box style={{marginTop: '1rem', marginBottom: '1rem', width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        {!loading ? (
                            <>
                                {request.product === 'pensions' &&
                                    <>
                                        {(pricing && leadDetails) ? (
                                            <>
                                                {pricing[leadDetails.quote.currentValue] ? (
                                                    <p style={{marginTop: '1rem'}}>This pension lead has a current value of <strong>{leadDetails.quote.currentValue}</strong> and is priced at <strong>€{pricing[leadDetails.quote.currentValue].toFixed(2)}</strong> for <strong>{request.companyId.toUpperCase()}.</strong> <br/><br/>Accepting this return will increase the {request.companyId.toUpperCase()} balance automatically.</p>
                                                ) : (
                                                    <p style={{marginTop: '1rem'}}>This pension lead does not have a valid current value and is priced as a general enquiry at <strong>€{pricing['General Enquiry (no value)'].toFixed(2)}</strong> for <strong>{request.companyId.toUpperCase()}.</strong> <br/><br/>Accepting this return will increase the {request.companyId.toUpperCase()} balance automatically.</p>
                                                )}
                                            </>
                                        ) : (
                                            <p style={{marginTop: '1rem'}}>This company does not have balance delivery pricing configured.</p>
                                        )}
                                    </>
                                }
                            </>
                        ) : (
                            <Loader height={12} />
                        )}
                    </Box>
                    </Box>
                </ReactBSAlert>
            }
        </Dialog>
	)
}