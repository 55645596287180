import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import BalanceDeliveriesTable from 'components/admin/BalanceDeliveriesTable'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function BalanceDeliveries() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const api = new AdminAPI()

    const [showStatus, setShowStatus] = useState('active')
    const [deliveries, setDeliveries] = useState([])
    const [showDeliveries, setShowDeliveries] = useState([])
    const [companies, setCompanies] = useState([])
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        getDeliveries()
    }, [])

    async function getDeliveries() {
        try {
            const deliveries = await api.getBalanceDeliveries()
            console.log('deliveries: ', deliveries)
            const activeDeliveries = deliveries.filter((delivery) => (delivery.active))

            const companies = await api.getCompanies()
            console.log('companies: ', companies)
            const activeCompanies = companies.filter((company) => (company.active))
            activeCompanies.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })

            setDeliveries(deliveries)
            setShowDeliveries(activeDeliveries)
            setCompanies(activeCompanies)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function updateDelivery(delivery) {
        const index = deliveries.findIndex(oldDelivery => ((oldDelivery.companyId === delivery.companyId) && (oldDelivery.product === delivery.product)))
        console.log('delivery index: ', index)

        let newDeliveries = [...deliveries]
        newDeliveries[index] = delivery
        console.log(newDeliveries)
        let checkStatus = showStatus === 'active' ? true : false
        let showDeliveries = newDeliveries.filter((delivery) => (delivery.active === checkStatus))
        console.log(showDeliveries)
        setDeliveries(newDeliveries)
        setShowDeliveries(showDeliveries)
    }

    function updateShowStatus(showStatus) {
        let checkStatus = showStatus === 'active' ? true : false
        let showDeliveries = deliveries.filter((delivery) => (delivery.active === checkStatus))
        setShowDeliveries(showDeliveries)
        setShowStatus(showStatus)
    }

    function showSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='-6rem'
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid
                                container
                                component={Box}
                                alignItems='center'
                                justifyContent='flex-start'
                                style={{marginBottom: '2rem'}}
                                direction='row'
                            >
                                <Grid item xs={12} md={2}>
                                    <FormGroup style={{marginBottom: '0'}}>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                value={showStatus}
                                                onChange={(e) => updateShowStatus(e.target.value)}
                                            >
                                                <MenuItem value='active'>Active</MenuItem>
                                                <MenuItem value='inactive'>Inactive</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <BalanceDeliveriesTable
                                deliveries={showDeliveries}
                                companies={companies}
                                refreshDeliveries={() => getDeliveries()}
                                updateDelivery={(delivery) => updateDelivery(delivery)}
                                showSuccessAlert={(text) => showSuccessAlert(text)}
                                title={showStatus === 'active' ? 'Active Balance Deliveries' : 'Inactive Balance Deliveries'}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default BalanceDeliveries