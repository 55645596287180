import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import CountySelect from 'components/general/CountySelect'
import MenuItem from '@material-ui/core/MenuItem'
import EnergyAPI from 'config/api/EnergyAPI'
import { onError } from 'config/lib/errorLib'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function AddCompanyModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	//const theme = useTheme()
  	const api = new EnergyAPI()

    const [name, setName] = useState('')
    const [companyId, setCompanyId] = useState('')
    const [active, setActive] = useState(true)
    const [billingAccount, setBillingAccount] = useState('BQ')
    const [billingPeriod, setBillingPeriod] = useState('Monthly')
    const [billingMethod, setBillingMethod] = useState('INV')
    const [billingEmail, setBillingEmail] = useState('')
    const [vatRate, setVatRate] = useState(0.23)
    const [counties, setCounties] = useState(['All'])

  	async function addCompany() {
  		try {   
            let company = await api.addCompany(companyId, name, active, billingAccount, billingPeriod, billingMethod, billingEmail, vatRate, counties)
            console.log('company: ', company)

  			props.refreshCompanies()
  			props.showSuccessAlert('Company added!')
  			props.close()
  		} catch(e) {
  			onError(e)
  		}
  	}

  	function checkFields() {
  		if (name.length > 0 && companyId.length > 0) {
  			return false
  		}
  		else {
  			return true
  		}
  	}

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Add EE Company
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>

			<DialogContent>
				<FormGroup>
                  	<FormLabel>Name</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                    	onChange={(e) => setName(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Company ID (2/3 letters - Must be unique)</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                    	onChange={(e) => setCompanyId(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Account Active</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={active}
                            onChange={(e) => setActive(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Billing Account</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={billingAccount}
                            onChange={(e) => setBillingAccount(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value='BQ'>BQ</MenuItem>
                            <MenuItem value='SS'>SS</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Billing Period</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={billingPeriod}
                            onChange={(e) => setBillingPeriod(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value='Weekly'>Weekly</MenuItem>
                            <MenuItem value='Monthly'>Monthly</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Billing Method</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={billingMethod}
                            onChange={(e) => setBillingMethod(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value='INV'>INV</MenuItem>
                            <MenuItem value='DD'>DD</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>VAT Rate</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            value={vatRate}
                            onChange={(e) => setVatRate(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            {/*<MenuItem value='Inclusive'>23% Inclusive (included in lead price)</MenuItem>*/}
                            <MenuItem value={0.23}>23% Exclusive (not included in lead price)</MenuItem>
                            <MenuItem value={0}>0% (no VAT)</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
					<FormLabel>Counties</FormLabel>
					<FormControl variant='outlined' fullWidth>
						<CountySelect 
							counties={counties}
							updateCounties={(counties) => setCounties(counties)}
						/>
					</FormControl>
				</FormGroup>
			</DialogContent>
			
			<DialogActions>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Cancel
				</Button>
			
				<Button
					component={Box}
					onClick={() => addCompany()}
					color='primary'
					marginLeft='auto!important'
					variant='contained'
					disabled={checkFields()}
				>
					Add
				</Button>
			</DialogActions>
		</Dialog>
	)
}