import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function AddDeliveryModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	//const theme = useTheme()
  	const api = new AdminAPI()

    const companies = props.companies
    const campaigns = props.campaigns

    const [company, setCompany] = useState('')
    const [campaign, setCampaign] = useState('')
    //const [source, setSource] = useState('')
    //const [startDate, setStartDate] = useState('')
    const [leadPrice, setLeadPrice] = useState(0)
    //const [vatRate, setVatRate] = useState('')
    const [allocation, setAllocation] = useState(0)
    const [active, setActive] = useState(true)

  	async function addDelivery() {
        let selectedCompany = companies.find(item => item.companyId === company)
        console.log('company: ', selectedCompany) 
        let selectedCampaign = campaigns.find(item => item.campaignId === campaign)
        console.log('campaign: ', selectedCampaign) 
        // let formattedStartDate = new Date(startDate)
        // console.log('formatted start date: ', formattedStartDate)

  		try {   
            let delivery = await api.addDelivery(selectedCompany.companyId, selectedCompany.name, selectedCampaign.campaignId, selectedCampaign.product, selectedCampaign.name, leadPrice, allocation, active)
            console.log('delivery: ', delivery)

  			props.refreshDeliveries()
  			props.showSuccessAlert('Delivery added!')
  			props.close()
  		} catch(e) {
  			onError(e)
  		}
  	}

  	function checkFields() {
  		if (company.length > 0 && campaign.length > 0) {
  			return false
  		}
  		else {
  			return true
  		}
  	}

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Add Delivery
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>

			<DialogContent>
                <FormGroup>
                    <FormLabel>Company</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            {companies.map((company) => {
                                return (
                                    <MenuItem value={company.companyId}>{company.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Campaign</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            value={campaign}
                            onChange={(e) => setCampaign(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            {campaigns.map((campaign) => {
                                return (
                                    <MenuItem value={campaign.campaignId}>{campaign.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </FormGroup>
                {/*
                <FormGroup>
                    <FormLabel>Source</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            value={source}
                            onChange={(e) => setSource(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value='insureyourincome.ie'>insureyourincome.ie</MenuItem>
                            <MenuItem value='lifecompare.ie'>lifecompare.ie</MenuItem>
                            <MenuItem value='unlockyourpension.ie'>unlockyourpension.ie</MenuItem>
                            <MenuItem value='nationalpensionhelpline.ie'>nationalpensionhelpline.ie</MenuItem>
                            <MenuItem value='stoppayingtoomuch.ie'>stoppayingtoomuch.ie</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                */}
                {/*
				<FormGroup>
                    <FormLabel>Start Date</FormLabel>
                    <OutlinedInput
                        fullWidth
                        type='date'
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </FormGroup>
                */}
                <FormGroup>
                  	<FormLabel>Lead Price (€)</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={leadPrice}
                    	onChange={(e) => setLeadPrice(e.target.value)}
                  	/>
                </FormGroup>
                {/*
                <FormGroup>
                    <FormLabel>VAT Rate</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            value={vatRate}
                            onChange={(e) => setVatRate(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value='Inclusive'>23% Inclusive (included in lead price)</MenuItem>
                            <MenuItem value='Exclusive'>23% Exclusive (not included in lead price)</MenuItem>
                            <MenuItem value='None'>0% (no VAT)</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                */}
                <FormGroup>
                  	<FormLabel>Allocation</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={allocation}
                    	onChange={(e) => setAllocation(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Active</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={active}
                            onChange={(e) => setActive(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
			</DialogContent>
			
			<DialogActions>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Cancel
				</Button>
			
				<Button
					component={Box}
					onClick={() => addDelivery()}
					color='primary'
					marginLeft='auto!important'
					variant='contained'
					disabled={checkFields()}
				>
					Add
				</Button>
			</DialogActions>
		</Dialog>
	)
}