import React, { useState } from 'react'
import Chart from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import { chartOptions, parseOptions, chartExample6 } from 'config/constants/ChartVariables'
import { pensionSizes, pensionTypes } from 'config/constants/Constants'

import componentStyles from "assets/theme/components/cards/charts/card-affiliate-traffic.js";

const useStyles = makeStyles(componentStyles)

const sizeColors = {
    'No Size': '#B8BBBE',
    'Start Pension': '#6A6E73',
    '€0 - €10,000': '#80afb1',
    '€10,000 - €50,000': '#679fa1',
    '€50,000 - €150,000': '#4d8f92',
    '€150,000 - €500,000': '#347f82',
    '€500,000+': '#1a6f73'
}

function PensionSizePie(props) {
    const classes = useStyles()
    const theme = useTheme()
    if (window.Chart) {
        parseOptions(Chart, chartOptions())
    }

    const [type, setType] = useState('All')

    function updateType(type) {
        setType(type)
    }

    let sizes = {
        'No Size': 0
    }

    for (let size of pensionSizes) {
        sizes[size] = 0
    }
    //console.log('sizes: ', sizes)

    for (let lead of props.leads) {
        if (lead.quote.type === type || type === 'All') {
            if (pensionSizes.indexOf(lead.quote.currentValue) > -1) {
                sizes[lead.quote.currentValue] += 1
            }
            else {
                sizes['No Size'] += 1
            }
        }
    }
    //console.log('sizes: ', sizes)

    let labels = []
    let values = []
    let colors = []

    for (let size of Object.keys(sizes)) {
        if (sizes[size] > 0) {
            labels.push(size)
            values.push(sizes[size])
            colors.push(sizeColors[size])
        }
    }
    let updatedLabels = labels.map((label, i) => {
        return label + ' (' + ((values[i] / props.leads.length) * 100).toFixed() + '%)'
    })
    console.log('updated labels: ', updatedLabels)
    // console.log('labels: ', labels)
    // console.log('values: ', values)
    // console.log('colors', colors)

    const data = {
        labels: updatedLabels, // array of labels
        datasets: [
            {
                //label: 'Annual Premiums (€)',
                data: values, // array of values
                //maxBarThickness: 10,
                backgroundColor: colors,
            },
        ]
    }
  
    return (
        <>
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    title={
                        <Box component='span' color={theme.palette.gray[600]}>
                            
                        </Box>
                    }
                    subheader='Lead Volume by Size'
                    classes={{ root: classes.cardHeaderRoot }}
                    titleTypographyProps={{
                        component: Box,
                        variant: 'h6',
                        letterSpacing: '2px',
                        marginBottom: '0!important',
                        classes: {
                            root: classes.textUppercase,
                        },
                    }}
                    subheaderTypographyProps={{
                        component: Box,
                        variant: 'h3',
                        marginBottom: '0!important',
                        color: 'initial',
                    }}
                ></CardHeader>
                
                <CardContent>
                    <Box width='25%'>
                        <FormGroup style={{marginBottom: '0'}}>
                            <FormControl variant='outlined'>
                                <Select
                                    multiple={false}
                                    defaultValue={null}
                                    IconComponent={KeyboardArrowDown}
                                    value={type}
                                    onChange={(e) => updateType(e.target.value)}
                                >
                                    <MenuItem value={'All'}>All Types</MenuItem>
                                    {pensionTypes.map((type) => {
                                        return <MenuItem value={type}>{type}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Box>
                    <Box position='relative' height='350px'>
                        <Pie
                            data={data}
                            options={{
                                legend: {
                                    display: true,
                                    position: "bottom",
                                },
                            }}
                            //options={chartExample6.options}
                            //getDatasetAtEvent={(e) => console.log(e)}
                        />
                    </Box>
                </CardContent>

                <Box
                    fontSize='12px'
                    color={theme.palette.gray[600]}
                    marginBottom='1rem'
                    marginLeft='1rem'
                    //marginTop='2rem'
                    textAlign='left'
                    //className='online-discount'
                >
                    <Box component='small' fontSize='100%'>
                        * Volume charts reflect gross lead volume, including rejections and returns
                    </Box>
                </Box>
            </Card>
        </>
    )
}

export default PensionSizePie