import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Select from '@mui/material/Select'
import MenuItem from '@material-ui/core/MenuItem'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
import ProductsAvailable from 'components/admin/ProductsAvailable'
import { defaultProducts } from 'config/constants/Constants'

//import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'
import componentStylesForms from 'assets/theme/components/forms.js'
import componentStylesButtons from 'assets/theme/components/button.js'

//const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)
const useStylesForms = makeStyles(componentStylesForms)
const useStylesButtons = makeStyles(componentStylesButtons)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function CompanyModal(props) {
	const classes = { ...useStylesDialog(), ...useStylesForms(), ...useStylesButtons() }
  	//const theme = useTheme()
  	const api = new AdminAPI()

    const company = props.company

    const [dailyCapsActive, setDailyCapsActive] = useState(company.dailyCapsActive === true ? true : false)
    const [replacementsActive, setReplacementsActive] = useState(company.replacementsActive === true ? true : false)
    const [active, setActive] = useState(company.active === true ? true : false)
    const [brokerDiary, setBrokerDiary] = useState(company.brokerDiary === true ? true : false)
    const [billingAccount, setBillingAccount] = useState(company.billingAccount || '')
    const [billingPeriod, setBillingPeriod] = useState(company.billingPeriod || '')
    const [billingMethod, setBillingMethod] = useState(company.billingMethod || '' )
    const [billingEmail, setBillingEmail] = useState(company.billingEmail || '' )
    const [productsAvailable, setProductsAvailable] = useState(company.productsAvailable || defaultProducts)
    const [vatRate, setVatRate] = useState(company.vatRate)
    
    async function updateCompany() {
        try {
            let result = await api.updateCompany(company.companyId, dailyCapsActive, replacementsActive, active, billingAccount, billingPeriod, billingMethod, billingEmail, vatRate, brokerDiary, productsAvailable)
            console.log('result: ', result)

  			props.updateCompany(result)
  			NotificationManager.success('Company updated')
            props.close()
  		} catch(e) {
  			onError(e)
  		}
    }

	return (
        <Dialog
            open={props.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => props.close()}
        >
            {alert}
            <div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					{company.name} ({company.companyId.toUpperCase()})
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
            <DialogContent>
                <FormGroup>
                    <FormLabel>Daily Caps</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={dailyCapsActive}
                            onChange={(e) => setDailyCapsActive(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value={true}>On</MenuItem>
                            <MenuItem value={false}>Off</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Replacement Requests</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={replacementsActive}
                            onChange={(e) => setReplacementsActive(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value={true}>On</MenuItem>
                            <MenuItem value={false}>Off</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Account Active</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={active}
                            onChange={(e) => setActive(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Using Broker Diary</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={brokerDiary}
                            onChange={(e) => setBrokerDiary(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Billing Account</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={billingAccount}
                            onChange={(e) => setBillingAccount(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value='BQ'>BQ</MenuItem>
                            <MenuItem value='RMA'>RMA</MenuItem>
                            <MenuItem value='SS'>SS</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Billing Period</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={billingPeriod}
                            onChange={(e) => setBillingPeriod(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value='Weekly'>Weekly</MenuItem>
                            <MenuItem value='Monthly'>Monthly</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Billing Method</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={billingMethod}
                            onChange={(e) => setBillingMethod(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value='INV'>INV</MenuItem>
                            <MenuItem value='DD'>DD</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Billing Email</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={billingEmail}
                    	onChange={(e) => setBillingEmail(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                    <FormLabel>VAT Rate</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            value={vatRate}
                            onChange={(e) => setVatRate(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            {/*<MenuItem value='Inclusive'>23% Inclusive (included in lead price)</MenuItem>*/}
                            <MenuItem value={0.23}>23% Exclusive (not included in lead price)</MenuItem>
                            <MenuItem value={0}>0% (no VAT)</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Products Available</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <ProductsAvailable 
                            productsAvailable={productsAvailable}
                            updateProductsAvailable={(products) => setProductsAvailable(products)}
                        />
                    </FormControl>
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Box width='100%' display='flex' justifyContent='space-between'>
                    <Button onClick={() => props.close()} color='primary'>
                        Close
                    </Button>
                    <Box>
                        <Box display='inline-block' marginRight='0.5rem'>
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={() => updateCompany()}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
	)
}