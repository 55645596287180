import React, { useState, useEffect } from 'react'
import { useLocation, Switch, Route, Redirect } from 'react-router-dom'
import AdminNavbarAlternative from 'components/argon/Navbars/AdminNavbarAlternative.js'
import Sidebar from 'components/argon/Sidebar/Sidebar.js'
import Box from '@material-ui/core/Box'
import DashboardIcon from '@material-ui/icons/Dashboard'
import BusinessIcon from '@material-ui/icons/Business'
import DeliveriesIcon from '@material-ui/icons/CheckBox'
import PaymentsIcon from '@material-ui/icons/Payment'
import ReplacementsIcon from '@material-ui/icons/Autorenew'
import CampaignsIcon from '@material-ui/icons/AddToPhotos'
import AllocationsIcon from '@material-ui/icons/CalendarToday'
import AnalyticsIcon from '@material-ui/icons/BarChart'
import PeopleIcon from '@material-ui/icons/People'
import BalanceIcon from '@material-ui/icons/AccountBalance'
import BoardsIcon from '@material-ui/icons/ZoomIn'
import SettingsIcon from '@material-ui/icons/Settings'

import logo from 'resources/images/logo-blue-500.png'

import { makeStyles } from '@material-ui/core/styles'
import componentStyles from 'assets/theme/layouts/admin.js'

//import Dashboard from 'scenes/admin/Dashboard'
import Companies from 'scenes/admin/Companies'
import ThisWeek from 'scenes/admin/ThisWeek'
import Replacements from 'scenes/admin/Replacements'
import Campaigns from 'scenes/admin/Campaigns'
import BalanceDeliveries from 'scenes/admin/BalanceDeliveries'
import Payments from 'scenes/admin/Payments'
import Profitability from 'scenes/admin/Profitability'
import Leads from 'scenes/admin/Leads'
import LeadBoards from 'scenes/admin/LeadBoards'
import NationalPensionHelpline from 'scenes/admin/NationalPensionHelpline'
import StartPension from 'scenes/admin/StartPension'
import CompareInsurance from 'scenes/admin/CompareInsurance'
import EnergyEfficiency from 'scenes/admin/EnergyEfficiency'
import Settings from 'scenes/admin/Settings'
import SolarFinder from 'scenes/admin/SolarFinder'

const useStyles = makeStyles(componentStyles)

const Main = (props) => {
    const classes = useStyles()
    const location = useLocation()
    const [sidebarOpenResponsive, setSidebarOpenResponsive] = useState(false)

    useEffect(() => {
        document.documentElement.scrollTop = 0
        document.scrollingElement.scrollTop = 0
        // mainContent.current.scrollTop = 0
    }, [location])

    var routes = [
        {
            path: '/companies',
            name: 'Companies',
            icon: BusinessIcon,
            iconColor: 'Info',
            component: Companies,
        },
        {
            path: '/this-week',
            name: 'This Week',
            icon: DeliveriesIcon,
            iconColor: 'Info',
            component: ThisWeek,
        },
        {
            path: '/leads',
            name: 'Leads',
            icon: PeopleIcon,
            iconColor: 'Info',
            component: Leads,
        },
        {
            path: '/lead-boards',
            name: 'Lead Boards',
            icon: BoardsIcon,
            iconColor: 'Info',
            component: LeadBoards,
        },
        {
            path: '/replacements',
            name: 'Replacements',
            icon: ReplacementsIcon,
            iconColor: 'Info',
            component: Replacements,
        },
        {
            path: '/campaigns',
            name: 'Weekly Deliveries',
            icon: CampaignsIcon,
            iconColor: 'Info',
            component: Campaigns,
        },
        {
            path: '/balance-deliveries',
            name: 'Balance Deliveries',
            icon: BalanceIcon,
            iconColor: 'Info',
            component: BalanceDeliveries,
        },
        // {
        //     path: '/allocations',
        //     name: 'Weekly Allocations',
        //     icon: AllocationsIcon,
        //     iconColor: 'Info',
        //     component: Allocations,
        // },
        {
            path: '/invoices',
            name: 'Invoices',
            icon: PaymentsIcon,
            iconColor: 'Info',
            component: Payments,
        },
        {
            path: '/profitability',
            name: 'Profitability',
            icon: AnalyticsIcon,
            iconColor: 'Info',
            component: Profitability,
        },
        {
            path: '/nph',
            name: 'NPH',
            icon: DashboardIcon,
            iconColor: 'Info',
            component: NationalPensionHelpline,
        },
        {
            path: '/pension-compare',
            name: 'Pension Compare',
            icon: DashboardIcon,
            iconColor: 'Info',
            component: StartPension,
        },
        {
            path: '/compare-insurance',
            name: 'Compare Insurance',
            icon: DashboardIcon,
            iconColor: 'Info',
            component: CompareInsurance,
        },
        {
            path: '/energy-efficiency',
            name: 'Energy Efficiency',
            icon: DashboardIcon,
            iconColor: 'Info',
            component: EnergyEfficiency,
        },
        {
            path: '/solar-finder',
            name: 'Solar Finder',
            icon: DashboardIcon,
            iconColor: 'Info',
            component: SolarFinder,
        },
        {
            path: '/settings',
            name: 'Settings',
            icon: SettingsIcon,
            iconColor: 'Info',
            component: Settings,
        }
    ]

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views)
            } 
            else {
                return (
                    <Route
                        path={prop.path}
                        component={prop.component}
                        key={key}
                    />
                )
            }
        });
    };

    return (
        <>
            <Box display='flex'>
                <Sidebar
                    routes={routes}
                    openResponsive={sidebarOpenResponsive}
                    closeSidebarResponsive={() => setSidebarOpenResponsive(false)}
                    accountType='admin'
                    logo={{
                        innerLink: '/',
                        imgSrc: logo,
                        imgAlt: '...',
                        className: 'sidebar-logo',
                    }}
                />
                <Box position='relative' flex='1' className={classes.mainContent}>
                    <AdminNavbarAlternative
                        openSidebarResponsive={() => setSidebarOpenResponsive(true)}
                        accountType='admin'
                        //permissions={details.permissions}
                        name={'Admin'}
                    />
                    
                    <Switch>
                        {getRoutes(routes)}
                        <Redirect from='*' to='/companies' />
                    </Switch>
                </Box>
            </Box>
        </>
    )
}

export default Main