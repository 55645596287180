import React, { useState, useEffect } from 'react'
import { styled } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FilledInput from '@material-ui/core/FilledInput'
import Phone from '@material-ui/icons/Phone'
import InputAdornment from '@material-ui/core/InputAdornment'

function PhoneInput(props) {
    const [countryCode, setCountryCode] = useState('+353')
    const [phone, setPhone] = useState(props.phone)

    useEffect(() => {
        updatePhone(phone)
    }, [])

    function updatePhone(phone) {
        setPhone(phone)
        
        let formattedPhone = countryCode + phone.replace(/^0+/, '')
        //console.log('formatted phone: ', formattedPhone)

        props.updatePhone(formattedPhone)
    }

    return (
        <Grid container>
            <Grid item xs={4}>
                <FilledInput
                    autoComplete='off'
                    type='text'
                    placeholder='Country'
                    value={countryCode}
                    disabled={true}
                    onChange={(e) => setCountryCode(e.target.value)}
                    startAdornment={
                        <InputAdornment position='start'>
                            <Phone />
                        </InputAdornment>
                    }
                />
            </Grid>
            <Grid item xs={8}>
                <FilledInput
                    autoComplete='off'
                    type='text'
                    placeholder='Phone'
                    value={phone}
                    onChange={(e) => updatePhone(e.target.value)}
                    autoFocus={props.autoFocus}
                />
            </Grid>
        </Grid>
    )
}

export default PhoneInput