import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
//import QuotesModal from 'components/broker/QuotesModal'
import BrokerAPI from 'config/api/BrokerAPI'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
import { providerOptions } from 'config/constants/Constants'
import { getProductString } from 'config/helpers/Helpers'

import DetailsContext from 'config/context/DetailsContext'

function HIQuoteDetails(props) {
    const api = props.admin ? new AdminAPI() : new BrokerAPI()
    const companyDetails = useContext(DetailsContext)
	const companyId = companyDetails.companyId || props.companyId

    const quote = props.lead.quote
    
    const [propertyType, setPropertyType] = useState(quote.propertyType || '')
    const [bedrooms, setBedrooms] = useState(quote.bedrooms || '')
    const [bathrooms, setBathrooms] = useState(quote.bathrooms || '')
    const [heating, setHeating] = useState(quote.heating || '')
    const [constructionDate, setConstructionDate] = useState(quote.constructionDate || '')
    const [propertyValue, setPropertyValue] = useState(quote.propertyValue || '')
    const [contentsCover, setContentsCover] = useState(quote.contentsCover || '')
    const [noClaims, setNoClaims] = useState(quote.noClaims || '')
    const [startDate, setStartDate] = useState(quote.startDate || '')
    const [premium, setPremium] = useState(quote.premium || '')
    const [propertyAddress, setPropertyAddress] = useState(quote.propertyAddress || '')
    const [selfBuild, setSelfBuild] = useState(quote.selfBuild || '')
    const [showContent, setShowContent] = useState(props.showQuoteContent)
    const [showQuotesModal, setShowQuotesModal] = useState(false)
 
    async function updateQuote() {
        try {
            let quote = {
                propertyType,
                bedrooms,
                bathrooms,
                heating,
                constructionDate,
                propertyValue,
                contentsCover,
                noClaims,
                startDate,
                propertyAddress,
                selfBuild,
                premium
            }
            
            let result = null
            if (props.admin) {
                result = await api.updateLeadQuote(props.lead.date, props.lead.leadId, quote)
            }
            else {
                result = await api.updateLeadQuote(companyId, props.lead.product, props.lead.phone, quote)
            }

            props.updateLead(result)
            NotificationManager.success('Details updated')
            setShowContent(false)
        } catch(e) {
            onError(e)
        }
    }

    // function updatePremium(premium) {
    //     let formatted = premium.replace(/€/g, '')
    //     setPremium(formatted)
    // }

    // function updateCurrentPremium(currentPremium) {
    //     let formatted = currentPremium.replace(/€/g, '')
    //     setCurrentPremium(formatted)
    // }
    
    return (
        <Card>
            <CardHeader
                subheader={
                    <Grid
                        container
                        component={Box}
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Grid item xs='auto'>
                            <Box
                                component={Typography}
                                variant='h3'
                                marginBottom='0!important'
                            >
                                Home Insurance Details
                            </Box>
                        </Grid>
                        <Grid item xs='auto'>
                            <Box>
                                {showContent ? (
                                    <KeyboardArrowUp />
                                ) : (
                                    <KeyboardArrowDown />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                }
                onClick={() => setShowContent(!showContent)} 
                style={{cursor: 'pointer'}}
            >
            </CardHeader>
            
            {showContent &&
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Property Type</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    autoFocus
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={propertyType}
                                        onChange={(e) => setPropertyType(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Bedrooms</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    autoFocus
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={bedrooms}
                                        onChange={(e) => setBedrooms(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Bathrooms</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    autoFocus
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={bathrooms}
                                        onChange={(e) => setBathrooms(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Heating</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    autoFocus
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={heating}
                                        onChange={(e) => setHeating(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Construction Date</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    autoFocus
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={constructionDate}
                                        onChange={(e) => setConstructionDate(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Property Value (€)</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={propertyValue}
                                        onChange={(e) => setPropertyValue(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Contents Cover (€)</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={contentsCover}
                                        onChange={(e) => setContentsCover(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>No Claims (Years)</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={noClaims}
                                        onChange={(e) => setNoClaims(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Start Date</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Property Address</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={propertyAddress}
                                        onChange={(e) => setPropertyAddress(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Self Build</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={selfBuild}
                                        onChange={(e) => setSelfBuild(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>{companyId === 'cib' ? 'Monthly Income/Commission (€)' : 'Monthly Premium (€)'}</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    autoFocus
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='number'
                                        value={premium}
                                        onChange={(e) => setPremium(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        
                        <Grid item xs={12} lg={12}>
                            <Grid container justifyContent='space-between'>
                                <Grid item>
                                    {/*
                                    <Button
                                        onClick={() => setShowQuotesModal(true)}
                                        color='primary'
                                        disabled
                                    >
                                        Generate Quotes
                                    </Button>
                                    */}
                                </Grid>
                                <Grid item>
                                    <Button
                                        component={Box}
                                        color='primary'
                                        variant='contained'
                                        size='medium'
                                        disabled={false}
                                        onClick={() => updateQuote()}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/*showQuotesModal &&
                        <QuotesModal 
                            isOpen={showQuotesModal}
                            close={() => setShowQuotesModal(false)}
                            clientDetails={props.clientDetails}
                            application={application}
                            updateApplication={(provider, premium, noPeople, coverAmount, coverAmount2, illnessCover, illnessCoverAmount, illnessCoverAmount2, policyTerm, convertibleOption, indexation) => updateApplication(provider, premium, noPeople, coverAmount, coverAmount2, illnessCover, illnessCoverAmount, illnessCoverAmount2, policyTerm, convertibleOption, indexation)}
                            type={props.type}
                        />
                    */}
                </CardContent>
            }
        </Card>
    )
}

export default HIQuoteDetails