import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import BootstrapTable from 'react-bootstrap-table-next'
import Grid from '@material-ui/core/Grid'
import Loader from 'components/general/Loader'
import filterFactory, { textFilter, selectFilter, numberFilter } from 'react-bootstrap-table2-filter'
import { getProductString } from 'config/helpers/Helpers'

import componentStyles from 'assets/theme/views/admin/react-bs-table.js'

const useStyles = makeStyles(componentStyles)

function ProductsTable(props) {
    const classes = { ...useStyles() }

    const columns = [
        {
            dataField: 'product',
            text: 'Product',
            formatter: (cell, row) => {
                return (<Box className='primary-text'>{getProductString(row.product)}</Box>)
                
            }
        },
        {
            dataField: 'volume',
            text: 'Volume',
            //sort: true,
            formatter: (cell, row) => {
                return <>{row.volume}</>
            }
        },
        {
            dataField: 'meetings',
            text: 'Meetings',
            //sort: true,
            formatter: (cell, row) => {
                return <>{row.meetings}</>
            }
        },
        {
            dataField: 'won',
            text: 'Deals Won',
            //sort: true,
            formatter: (cell, row) => {
                return <>{row.won}</>
            }
        },
        {
            dataField: 'premiums',
            text: props.companyId === 'cib' ? 'Annual Income/Commission (€)' : 'Annual Premiums (€)',
            //sort: true,
            formatter: (cell, row) => {
                return <>{'€' + parseFloat(row.premiums).toFixed(2)}</>
            }
        },
        
    ]

    return (
        <Card style={{marginTop: '2rem'}}>
            <>
                <CardHeader
                    subheader={
                        <>
                            <Box display='flex' style={{justifyContent: 'space-between'}}>
                                <Box>
                                    <Box
                                        component={Typography}
                                        variant='h2'
                                        marginBottom='0!important'
                                    >
                                        <Box component='span'>Lead Breakdown</Box>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    }
                    classes={{ root: classes.cardHeaderRoot }}
                ></CardHeader>

                <div className='table-container'>
                    <BootstrapTable 
                        keyField='name' 
                        data={ props.products } 
                        columns={ columns } 
                        filter={ filterFactory() } 
                        noDataIndication={<Box>No products</Box>}
                    />
                </div>
            </>
        </Card>
    );
}

export default ProductsTable