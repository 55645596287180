import { Component } from 'react'
import { API } from 'aws-amplify'
//import { v4 as uuidv4 } from 'uuid'
import config from './Config'

const apiPath = config.apiGatewayAdmin.PATH

class AdminClient extends Component {
	getCompanies() {
		return API.post(apiPath, 'companies/list', {})
	}

	getAllBrokers() {
		return API.post(apiPath, 'companies/get-all-brokers', {})
	}

	getCompanyBrokers(companyId) {
		const data = {
			companyId
		}
		console.log(data)

		return API.post(apiPath, 'companies/get-company-brokers', {
			body: data
		})
	}

	getReturnRequests() {
		return API.post(apiPath, 'leads/return-requests', {})
	}

	addCompany(companyId, name, dailyCapsActive, replacementsActive, active, billingAccount, billingPeriod, billingMethod, vatRate, brokerDiary) {
        const data = {
			companyId,
			name,
			dailyCapsActive,
			replacementsActive,
			active,
			billingAccount,
			billingPeriod,
			billingMethod,
			vatRate,
			brokerDiary
		}
		console.log(data)

        return API.post(apiPath, 'companies/add', {
            body: data
        })
    }

    updateCompany(companyId, dailyCapsActive, replacementsActive, active, billingAccount, billingPeriod, billingMethod, billingEmail, vatRate, brokerDiary, productsAvailable) {
        const data = {
			companyId,
			dailyCapsActive,
			replacementsActive,
			active,
			billingAccount,
			billingPeriod,
			billingMethod,
			billingEmail,
			vatRate,
			brokerDiary,
			productsAvailable
		}
		console.log(data)

        return API.post(apiPath, 'companies/update', {
            body: data
        })
    }

	getCompanyDetails(companyId) {
		const data = {
			companyId
		}
		console.log(data)

		return API.post(apiPath, 'companies/get', {
			body: data
		})
	}

	getAllocated(product) {
		const data = {
			product
		}
		return API.post(apiPath, 'leads/get-allocated', {
			body: data
		})
	}

	getLeadsPeriod(product, fromDate, toDate) {
		const data = {
			product,
			fromDate,
			toDate
		}
		console.log(data)
		return API.post(apiPath, 'leads/get-leads-period', {
			body: data
		})
	}

	getAllLeadsPeriod(product, fromDate, toDate) {
		const data = {
			product,
			fromDate,
			toDate
		}
		console.log(data)
		
		return API.post(apiPath, 'leads/get-all-period', {
			body: data
		})
	}

	rejectReturnRequest(request) {
		const data = {
			request
		}
		return API.post(apiPath, 'leads/reject-request', {
			body: data
		})
	}

	acceptReturnRequest(request, pricing, currentValue) {
		const data = {
			request,
			pricing,
			currentValue
		}
		return API.post(apiPath, 'leads/accept-request', {
			body: data
		})
	}

	acceptReturnRequestMultiple(requests) {
		const data = {
			requests
		}
		return API.post(apiPath, 'leads/accept-request-multiple', {
			body: data
		})
	}

	deleteLeadsMultiple(companyId, product, leads) {
		const data = {
			companyId,
			product,
			leads
		}
		console.log(data)

		return API.post(apiPath, 'leads/delete-multiple', {
			body: data
		})
	}

	deleteGeneralLeadsMultiple(companyId, leads) {
		const data = {
			companyId,
			leads
		}
		console.log(data)

		return API.post(apiPath, 'leads/delete-gen-multiple', {
			body: data
		})
	}

	getCampaigns() {
		return API.post(apiPath, 'campaigns/list', {})
	}

	addCampaign(name, product, target) {
        const data = {
			name,
			product,
			target
		}
		console.log(data)

        return API.post(apiPath, 'campaigns/add', {
            body: data
        })
    }

	updateCampaign(campaignId, name, target) {
        const data = {
			campaignId,
			name,
			target
		}
		console.log(data)

        return API.post(apiPath, 'campaigns/update', {
            body: data
        })
    }

	getDeliveries() {
		return API.post(apiPath, 'deliveries/list', {})
	}

	getBalanceDeliveries() {
		return API.post(apiPath, 'deliveries/list-balance', {})
	}

	getBalanceDeliveryPricing(companyId, product) {
		const data = {
			companyId,
			product
		}
		console.log(data)

		return API.post(apiPath, 'deliveries/get-pricing', {
			body: data
		})
	}

	addDelivery(companyId, companyName, campaignId, product, campaignName, leadPrice, allocation, active) {
		const data = {
			companyId,
			companyName,
			campaignId,
			product,
			campaignName,
			leadPrice,
			allocation,
			active
		}
		console.log(data)

        return API.post(apiPath, 'deliveries/add', {
            body: data
        })
	}

	addBalanceDelivery(companyId, companyName, product, active, pricing) {
		const data = {
			companyId,
			companyName,
			product,
			active,
			pricing
		}
		console.log(data)

        return API.post(apiPath, 'deliveries/add-balance', {
            body: data
        })
	}

	updateDelivery(companyId, product, leadPrice, allocation, leadbyteNumber, active) {
		const data = {
			companyId,
			product,
			leadPrice,
			allocation,
			leadbyteNumber,
			active
		}
		console.log(data)

        return API.post(apiPath, 'deliveries/update', {
            body: data
        })
	}

	updateBalanceDelivery(companyId, product, balance, active, pricing) {
		const data = {
			companyId,
			product,
			balance,
			active,
			pricing
		}
		console.log(data)

        return API.post(apiPath, 'deliveries/update-balance', {
            body: data
        })
	}

	getPayments() {
		return API.post(apiPath, 'payments/list', {})
	}

	addPayment(companyId, companyName, date, billingMethod, billingAccount, billingPeriod, vatRate, allocation, subtotal, total, invoiced, paid, notes) {
		const data = {
			companyId,
			companyName,
			date,
			billingMethod,
			billingAccount,
			billingPeriod,
			vatRate,
			allocation, 
			subtotal, 
			total,
			invoiced,
			paid,
			notes
		}
		console.log(data)

        return API.post(apiPath, 'payments/add', {
            body: data
        })
	}

	updatePayment(companyId, date, billingAccount, vatRate, allocation, subtotal, total, invoiced, paid, notes, reference) {
		const data = {
			companyId,
			date,
			billingAccount,
			vatRate,
			allocation, 
			subtotal, 
			total,
			invoiced,
			paid,
			notes,
			reference
		}
		console.log(data)

        return API.post(apiPath, 'payments/update', {
            body: data
        })
	}

	deletePayment(companyId, date) {
		const data = {
			companyId,
			date
		}
		console.log(data)

        return API.post(apiPath, 'payments/delete', {
            body: data
        })
	}

	getProfitabilityWeeks() {
		return API.post(apiPath, 'profitability/list', {})
	}

	addProfitabilityWeek(country, date, details, leads, revenue, cost, notes) {
		const data = {
			country,
			date,
			details,
			leads,
			revenue,
			cost, 
			notes
		}
		console.log('data: ', data)

        return API.post(apiPath, 'profitability/add', {
            body: data
        })
	}

	updateProfitabilityWeek(country, date, details, leads, revenue, cost, notes) {
		const data = {
			country,
			date,
			details,
			leads,
			revenue,
			cost, 
			notes
		}
		console.log(data)

        return API.post(apiPath, 'profitability/update', {
            body: data
        })
	}

	deleteProfitabilityWeek(country, date) {
		const data = {
			country,
			date
		}
		console.log(data)

        return API.post(apiPath, 'profitability/delete', {
            body: data
        })
	}

	getLeads() {
		return API.post(apiPath, 'leads/list-all', {})
	}

	getLead(companyId, product, phone) {
		const data = {
			companyId,
			product,
			phone
		}
		console.log(data)

        return API.post(apiPath, 'leads/get', {
            body: data
        })
	}

	allocateLead(companyId, brokerId, lead, pricing) {
		const data = {
			companyId,
			brokerId,
			lead,
			pricing
		}
		console.log(data)

        return API.post(apiPath, 'leads/allocate', {
            body: data
        })
	}

	rejectLead(date, leadId) {
		const data = {
			date,
			leadId
		}
		console.log(data)

        return API.post(apiPath, 'leads/reject', {
            body: data
        })
	}

	getStartPensionApplications(companyId) {
		const data = {
			companyId
		}
		console.log(data)

        return API.post(apiPath, 'start-pension/list', {
			body: data
		})
	}

	// getCompareInsuranceLeads() {
    //     return API.post(apiPath, 'compare-insurance/list', {})
	// }

	getCompareInsuranceLeads() {
		return API.post(apiPath, 'compare-insurance/list-all', {})
	}

	chargeCompany(payment) {
		const data = {
			payment
		}
		console.log(data)

        return API.post(apiPath, 'stripe/create-payment-intent', {
            body: data
        })
	}

	sendPaymentLink(payment) {
		const data = {
			payment
		}
		console.log(data)

        return API.post(apiPath, 'payments/send-link', {
            body: data
        })
	}

	sendPaymentReminder(payment) {
		const data = {
			payment
		}
		console.log(data)

        return API.post(apiPath, 'payments/send-reminder', {
            body: data
        })
	}

	sendTopupLink(companyId, total) {
		const data = {
			companyId,
			total
		}
		console.log(data)

        return API.post(apiPath, 'payments/send-topup', {
            body: data
        })
	}

	// exchangeXeroCode(code) {
	// 	const data = {
	// 		code
	// 	}
	// 	console.log(data)

    //     return API.post(apiPath, 'payments/exchange-xero-code', {
    //         body: data
    //     })
	// }

	getValidationLists() {
        return API.post(apiPath, 'settings/get-validation-lists', {})
	}

	updateValidationList(list, items) {
		const data = {
			list,
			items
		}
		console.log(data)

        return API.post(apiPath, 'settings/update-validation-list', {
            body: data
        })
	}

	getActiveNPHBuyers() {
        return API.post(apiPath, 'nph/buyers', {})
	}

	getAllNPHLeads() {
		return API.post(apiPath, 'nph/leads', {})
	}

	updateLeadQuote(date, leadId, quote) {
		const data = {
			date,
			leadId,
			quote
		}
		console.log(data)

		return API.post(apiPath, 'leads/update-quote', {
			body: data
		})
	}

	updateLeadDetails(date, leadId, details) {
		const data = {
			date,
			leadId,
			details
		}
		console.log(data)

		return API.post(apiPath, 'leads/update-details', {
			body: data
		})
	}

	updateLeadNote(date, leadId, note) {
		const data = {
			date,
			leadId,
			note
		}
		console.log(data)

		return API.post(apiPath, 'leads/update-note', {
			body: data
		})
	}
} 

export default AdminClient