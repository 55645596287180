import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Select from '@mui/material/Select'
import MenuItem from '@material-ui/core/MenuItem'
import AllocationTable from 'components/admin/AllocationTable'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'

//import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'
import componentStylesForms from 'assets/theme/components/forms.js'
import componentStylesButtons from 'assets/theme/components/button.js'

//const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)
const useStylesForms = makeStyles(componentStylesForms)
const useStylesButtons = makeStyles(componentStylesButtons)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function PaymentModal(props) {
	const classes = { ...useStylesDialog(), ...useStylesForms(), ...useStylesButtons() }
  	//const theme = useTheme()
  	const api = new AdminAPI()

    const payment = props.payment
    console.log('payment: ', payment)
    const companies = props.companies

    const [company, setCompany] = useState(payment.companyId)
    const [date, setDate] = useState(payment.date)
    const [billingMethod, setBillingMethod] = useState(payment.billingMethod)
    const [billingAccount, setBillingAccount] = useState(payment.billingAccount)
    const [billingPeriod, setBillingPeriod] = useState(payment.billingPeriod)
    const [vatRate, setVatRate] = useState(payment.vatRate)
    //const [description, setDescription] = useState(payment.description)
    const [allocation, setAllocation] = useState(payment.allocation || null)
    const [subtotal, setSubtotal] = useState(payment.subtotal)
    const [total, setTotal] = useState(payment.total)
    const [invoiced, setInvoiced] = useState(payment.invoiced)
    const [paid, setPaid] = useState(payment.paid)
    const [notes, setNotes] = useState(payment.notes)
    const [reference, setReference] = useState(payment.reference || '')
    const [alert, setAlert] = useState(false)
    
    async function updatePayment() {
        try {
            try {   
                let updatedPayment = await api.updatePayment(payment.companyId, payment.date, billingAccount, vatRate, allocation, subtotal, total, invoiced, paid, notes, reference)
                console.log('payment: ', updatedPayment)

                props.updatePayment(updatedPayment)
                props.showSuccessAlert('Payment updated!')
                props.close()
            } catch(e) {
                onError(e)
            }
  		} catch(e) {
  			onError(e)
  		}
    }

    async function deletePayment() {
        try {
            try {   
                await api.deletePayment(payment.companyId, payment.date)

                props.refreshPayments()
                props.showSuccessAlert('Payment deleted!')
                props.close()
            } catch(e) {
                onError(e)
            }
  		} catch(e) {
  			onError(e)
  		}
    }

    function showDeleteWarning() {
        setAlert(
            <ReactBSAlert
                warning
                style={{ display: 'block', marginTop: '-100px' }}
                title='Are you sure?'
                onConfirm={() => deletePayment()}
                onCancel={() => setAlert(false)}
                cancelBtnCssClass='btn-secondary'
                confirmBtnBsStyle='danger'
                cancelBtnText='Cancel'
                confirmBtnText='Yes, delete it'
                showCancel
                btnSize=''
            >
                This will remove the invoice permanently.
            </ReactBSAlert>
        )
    }

    function updateAllocation(allocation) {
        let subtotal = 0
        for (const [product, values] of Object.entries(allocation)) {
            subtotal += (values.allocation * values.leadPrice)
        }
        subtotal = parseFloat(subtotal.toFixed(2))
        let total = subtotal + (subtotal * vatRate)
        total = parseFloat(total.toFixed(2))
        setAllocation(allocation)
        setSubtotal(subtotal)
        setTotal(total)
    }

    function updateVatRate(vatRate) {
        let total = subtotal + (subtotal * vatRate)
        total = parseFloat(total.toFixed(2))
        setVatRate(vatRate)
        setTotal(total)
    }

	return (
        <Dialog
            open={props.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => props.close()}
        >
            {alert}
            <div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Update Invoice
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
            <DialogContent>
                <FormGroup>
                    <FormLabel>Company</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                            disabled
                        >
                            {companies.map((company) => {
                                return (
                                    <MenuItem value={company.companyId}>{company.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </FormGroup>
				<FormGroup>
                    <FormLabel>Date</FormLabel>
                    <OutlinedInput
                        fullWidth
                        type='date'
                        value={date}
                        defaultValue={date}
                        onChange={(e) => setDate(e.target.value)}
                        disabled
                    />
                </FormGroup>
                <FormGroup>
                    <FormLabel>Reference (Xero Invoice)</FormLabel>
                    <OutlinedInput
                        fullWidth
                        type='text'
                        value={reference}
                        onChange={(e) => setReference(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <FormLabel>Billing Method</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={billingMethod}
                            onChange={(e) => setBillingMethod(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                            disabled
                        >
                            <MenuItem value='INV'>INV</MenuItem>
                            <MenuItem value='DD'>DD</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Billing Account</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={billingAccount}
                            onChange={(e) => setBillingAccount(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value='BQ'>BQ</MenuItem>
                            <MenuItem value='SS'>SS</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Billing Period</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={billingPeriod}
                            onChange={(e) => setBillingPeriod(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                            disabled
                        >
                            <MenuItem value='Weekly'>Weekly</MenuItem>
                            <MenuItem value='Monthly'>Monthly</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>VAT Rate</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            value={vatRate}
                            onChange={(e) => updateVatRate(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value={0.23}>23% Exclusive (not included in lead price)</MenuItem>
                            <MenuItem value={0}>0% (no VAT)</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Allocation</FormLabel>
                    <AllocationTable 
                        allocations={allocation} 
                        updateAllocations={(allocation) => updateAllocation(allocation)}
                    />
                    {/*
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={allocation}
                    	onChange={(e) => setAllocation(e.target.value)}
                        multiline
                        minRows={2}
                    />*/}
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Subtotal (€)</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={subtotal}
                    	//onChange={(e) => setSubtotal(e.target.value)}
                        disabled
                  	/>
                </FormGroup>      
                <FormGroup>
                  	<FormLabel>Total (€)</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={total}
                    	//onChange={(e) => setTotal(e.target.value)}
                        disabled
                  	/>
                </FormGroup>   
                <FormGroup>
                    <FormLabel>Invoiced</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={invoiced}
                            onChange={(e) => setInvoiced(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Paid</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={paid}
                            onChange={(e) => setPaid(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Notes</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={notes}
                    	onChange={(e) => setNotes(e.target.value)}
                        multiline
                        minRows={2}
                  	/>
                </FormGroup>

                <Box>
                    <Typography className='hover-text red-text' style={{fontWeight: '400', fontSize: '1rem'}} onClick={() => showDeleteWarning()}>Delete Invoice</Typography>
                </Box>
			</DialogContent>
            <DialogActions>
                <Box width='100%' display='flex' justifyContent='space-between'>
                    <Button onClick={() => props.close()} color='primary'>
                        Close
                    </Button>
                    <Box>
                        <Box display='inline-block' marginRight='0.5rem'>
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={() => updatePayment()}
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
	)
}