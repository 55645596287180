import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { defaultProducts } from 'config/constants/Constants'
import { getProductString } from 'config/helpers/Helpers'

function ProductsAvailable(props) {

    const products = props.productsAvailable
    console.log('products available: ', products)

    function updateProducts(product, available) {
        console.log(product)
        console.log(available)

        let newProducts = {...products}
        newProducts[product] = available
        props.updateProductsAvailable(newProducts)
    }
    
    return (
        <Grid container>
            {Object.keys(defaultProducts).map((product) => {
                return (
                    <Grid item xs={12}>
                        <Grid container direction='row' alignItems='center' justifyContent='flex-start'>
                            <Box>
                                <Checkbox
                                    color='primary' 
                                    checked={products[product]} 
                                    onChange={() => updateProducts(product, !products[product])}
                                />
                            </Box>
                            <Box>
                                <FormLabel>{getProductString(product)}</FormLabel>
                            </Box>
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default ProductsAvailable