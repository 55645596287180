import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Loader from 'components/general/Loader'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import EnquiryForm from 'components/broker/EnquiryForm'
import LeadReturnForm from 'components/broker/LeadReturnForm'

import DetailsContext from 'config/context/DetailsContext'

import componentStyles from 'assets/theme/components/cards/cards/card-button.js'

const useStyles = makeStyles(componentStyles)

function Help() {
    const api = new BrokerAPI()
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId

    const classes = { ...useStyles() }

    const [details, setDetails] = useState(null)
    const [loading, setLoading] = useState(true)
    const [type, setType] = useState('general')

    useEffect(() => {
        getDetails()
    }, [])

    async function getDetails() {
        try {
            const details = await api.getDetails(companyId)
            console.log('details: ', details)

            setDetails(details)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Card
                                classes={{
                                    root: classes.cardRoot,
                                }}
                            >
                                <CardHeader
                                    subheader={
                                        <Grid
                                            container
                                            component={Box}
                                            alignItems='center'
                                            justifyContent='space-between'
                                        >
                                            <Grid item xs='auto'>
                                                <Box
                                                    component={Typography}
                                                    variant='h3'
                                                    marginBottom='0!important'
                                                >
                                                    Submit Support Ticket
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    }
                                    classes={{ root: classes.cardHeaderRoot }}
                                >
                                </CardHeader>
                                
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={12} lg={12}>  
                                            <FormGroup>
                                                <FormLabel>Ticket Type</FormLabel>
                                                <FormControl variant='outlined' fullWidth>
                                                    <Select
                                                        multiple={false}
                                                        IconComponent={KeyboardArrowDown}
                                                        value={type}
                                                        onChange={(e) => setType(e.target.value)}
                                                    >
                                                        <MenuItem value='general'>General Enquiry</MenuItem>
                                                        {companyDetails.replacementsActive &&
                                                            <MenuItem value='return'>Lead Return</MenuItem>
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12} lg={12}>  
                                            {type === 'general' &&
                                                <EnquiryForm 
                                                    details={details}
                                                />
                                            }
                                            {type === 'return' &&
                                                <LeadReturnForm 
                                                    details={details}
                                                />
                                            }
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        {/*
                        <Grid item xs={6} xl={6}>
                            <Card
                                classes={{
                                    root: classes.cardRoot,
                                }}
                            >
                                <CardHeader
                                    subheader={
                                        <Grid
                                            container
                                            component={Box}
                                            alignItems='center'
                                            justifyContent='space-between'
                                        >
                                            <Grid item xs='auto'>
                                                <Box
                                                    component={Typography}
                                                    variant='h3'
                                                    marginBottom='0!important'
                                                >
                                                    Resources
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    }
                                    classes={{ root: classes.cardHeaderRoot }}
                                >
                                </CardHeader>
                                
                                <CardContent>
                                    <Grid container direction='column' spacing={2}>
                                        <Grid item xs={12} lg={12}>  
                                            <Typography style={{fontWeight: '700', color: '#525f7f'}}>Income Protection</Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={12}>  
                                            <a href='https://brokerdiary.io/wp-content/uploads/2022/11/Setting-A-Meeting-From-Insure-Your-Income.pdf'>Setting A Meeting From Insure Your Income</a>
                                        </Grid>
                                        <Grid item xs={12} lg={12}>  
                                            <a href='https://brokerdiary.io/wp-content/uploads/2022/10/Claims-Report-2021-002-3.pdf'>Aviva Claims Report</a>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        */}
                    </Grid> 
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default Help