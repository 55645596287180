import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import AllocationTable from 'components/admin/AllocationTable'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function AddPaymentModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	//const theme = useTheme()
  	const api = new AdminAPI()

    const companies = props.companies

    const [company, setCompany] = useState('')
    const [date, setDate] = useState('')
    const [billingMethod, setBillingMethod] = useState('')
    const [billingAccount, setBillingAccount] = useState('')
    const [billingPeriod, setBillingPeriod] = useState('')
    const [vatRate, setVatRate] = useState('')
    const [allocation, setAllocation] = useState('')
    const [subtotal, setSubtotal] = useState(0)
    const [total, setTotal] = useState(0)
    const [invoiced, setInvoiced] = useState(false)
    const [paid, setPaid] = useState(false)
    const [notes, setNotes] = useState('')

  	async function addPayment() {
  		try {   
            let selectedCompany = companies.find(item => item.companyId === company)
            console.log('company: ', selectedCompany) 
            let formattedDate = moment(date).format('YYYY-MM-DD');
            console.log('formatted date: ', formattedDate); 
            
            let payment = await api.addPayment(selectedCompany.companyId, selectedCompany.name, formattedDate, billingMethod, billingAccount, billingPeriod, vatRate, allocation, subtotal, total, invoiced, paid, notes);
            console.log('payment: ', payment)

  			props.refreshPayments()
  			props.showSuccessAlert('Payment added!')
  			props.close()
  		} catch(e) {
  			onError(e)
  		}
  	}

  	function checkFields() {
  		if (company.length > 0 && date.length > 0) {
  			return false
  		}
  		else {
  			return true
  		}
  	}

    function updateCompany(companyId) {
        let selectedCompany = companies.find(item => item.companyId === companyId)
        console.log('company: ', selectedCompany) 
        setCompany(selectedCompany.companyId)
        setBillingMethod(selectedCompany.billingMethod)
        setBillingAccount(selectedCompany.billingAccount)
        setBillingPeriod(selectedCompany.billingPeriod)
        setVatRate(selectedCompany.vatRate)
    }

    function updateAllocation(allocation) {
        let subtotal = 0
        for (const [product, values] of Object.entries(allocation)) {
            subtotal += (values.allocation * values.leadPrice)
        }
        subtotal = parseFloat(subtotal.toFixed(2))
        let total = subtotal + (subtotal * vatRate)
        total = parseFloat(total.toFixed(2))
        setAllocation(allocation)
        setSubtotal(subtotal)
        setTotal(total)
    }

    function updateVatRate(vatRate) {
        let subtotal = 0
        for (const [product, values] of Object.entries(allocation)) {
            subtotal += (values.allocation * values.leadPrice)
        }
        subtotal = parseFloat(subtotal.toFixed(2))
        let total = subtotal + (subtotal * vatRate)
        total = parseFloat(total.toFixed(2))
        setVatRate(vatRate)
        setSubtotal(subtotal)
        setTotal(total)
    }

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Add Invoice
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>

			<DialogContent>
            <FormGroup>
                    <FormLabel>Company</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            value={company}
                            onChange={(e) => updateCompany(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            {companies.map((company) => {
                                return (
                                    <MenuItem value={company.companyId}>{company.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </FormGroup>
				<FormGroup>
                    <FormLabel>Date</FormLabel>
                    <OutlinedInput
                        fullWidth
                        type='date'
                        value={date}
                        defaultValue={date}
                        onChange={(e) => setDate(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <FormLabel>Billing Method</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={billingMethod}
                            onChange={(e) => setBillingMethod(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value='INV'>INV</MenuItem>
                            <MenuItem value='DD'>DD</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Billing Account</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={billingAccount}
                            onChange={(e) => setBillingAccount(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value='BQ'>BQ</MenuItem>
                            <MenuItem value='SS'>SS</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Billing Period</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={billingPeriod}
                            onChange={(e) => setBillingPeriod(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value='Weekly'>Weekly</MenuItem>
                            <MenuItem value='Monthly'>Monthly</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>VAT Rate</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            value={vatRate}
                            onChange={(e) => updateVatRate(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value={0.23}>23% Exclusive (not included in lead price)</MenuItem>
                            <MenuItem value={0}>0% (no VAT)</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Allocation</FormLabel>
                    <AllocationTable 
                        allocations={allocation} 
                        updateAllocations={(allocation) => updateAllocation(allocation)}
                    />
                    {/*
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={allocation}
                    	onChange={(e) => setAllocation(e.target.value)}
                        multiline
                        minRows={2}
                    />*/}
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Subtotal (€)</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={subtotal}
                        disabled
                    	//onChange={(e) => setSubtotal(e.target.value)}
                  	/>
                </FormGroup>      
                <FormGroup>
                  	<FormLabel>Total (€)</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={total}
                        disabled
                    	//onChange={(e) => setTotal(e.target.value)}
                  	/>
                </FormGroup>   
                <FormGroup>
                    <FormLabel>Invoiced</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={invoiced}
                            onChange={(e) => setInvoiced(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Paid</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //IconComponent={KeyboardArrowDown}
                            value={paid}
                            onChange={(e) => setPaid(e.target.value)}
                            input={<OutlinedInput />}
                            style={{marginBottom: '0.5rem'}}
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Notes</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                        value={notes}
                    	onChange={(e) => setNotes(e.target.value)}
                        multiline
                        minRows={2}
                  	/>
                </FormGroup>
			</DialogContent>
			
			<DialogActions>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Cancel
				</Button>
			
				<Button
					component={Box}
					onClick={() => addPayment()}
					color='primary'
					marginLeft='auto!important'
					variant='contained'
					disabled={checkFields()}
				>
					Add
				</Button>
			</DialogActions>
		</Dialog>
	)
}