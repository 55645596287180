import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
//import ToggleButtonGroup from '@material-ui/core/ToggleButtonGroup'
//import ToggleButton from '@material-ui/core/ToggleButton'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { 
    leadPipelineStages, 
    pipelineStageColors 
}  from 'config/constants/Constants'

function PipelineFilter(props) {
    const pipelineCount = props.pipelineCount
    console.log('pipeline count: ', pipelineCount)
    const activeStage = props.stage
    
    return (
        <Grid container style={{marginTop: '1rem'}}>
            <Grid item xs={12} lg={12}>
                <ToggleButtonGroup size='medium' className='pipeline-btn-group' value={activeStage}>
                    {/*
                    <ToggleButton 
                        value='active' 
                        className='pipeline-btn' 
                        key='active' 
                        onClick={() => props.updateStage('active')}
                        //color='primary'
                    >
                        Active ({pipelineCount['active']})
                    </ToggleButton>
                    */}

                    {
                        Object.keys(leadPipelineStages).map((stage, i) => {
                            return (
                                <ToggleButton 
                                    value={stage} 
                                    className='pipeline-btn' 
                                    key={i} 
                                    onClick={() => props.updateStage(stage)}
                                    //color='primary'
                                >
                                    <Box className='stage-color' style={{backgroundColor: pipelineStageColors[stage]}}></Box> 
                                    <Box>{leadPipelineStages[stage] + ' (' + pipelineCount[stage] + ')'}</Box>
                                </ToggleButton>
                            )
                        })
                    }
                </ToggleButtonGroup>
            </Grid>
        </Grid>
    )
}

export default PipelineFilter