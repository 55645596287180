import React, { useState, useEffect, useContext } from 'react'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'
import Loader from 'components/general/Loader'
import PaymentMethodCard from 'components/broker/PaymentMethodCard'
import PaymentsTable from 'components/broker/PaymentsTable'
import PaymentModal from 'components/broker/PaymentModal'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import config from 'config/api/Config'
import DetailsContext from 'config/context/DetailsContext'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

const stripePromise = loadStripe(config.stripeKey)

function Billing() {
    const api = new BrokerAPI()
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId

    const [details, setDetails] = useState(null)
    const [payments, setPayments] = useState([])
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [balanceDelivery, setBalanceDelivery] = useState(false)
    const [hasPaymentMethod, setHasPaymentMethod] = useState(true)
    const [showPaymentModal, setShowPaymentModal] = useState(false)
    const [payment, setPayment] = useState(null)
    const [clientSecret, setClientSecret] = useState(null)
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)

    //const weeklyLimit = parseInt(companyDetails.weeklyLimits[product]) + parseInt(companyDetails.extraWeeklyLimits[product])

    useEffect(() => {
        getDetails()
    }, [])

    async function getDetails() {
        try {
            const paymentIntentId = new URLSearchParams(window.location.search).get(
                'payment_intent'
            );

            let paymentIntentStatus = ''

            if (paymentIntentId) {
                paymentIntentStatus = await api.getPaymentIntentStatus(paymentIntentId)
                console.log('payment intent status: ', paymentIntentStatus)
                if (paymentIntentStatus === 'succeeded') {
                    const paymentDate = new URLSearchParams(window.location.search).get(
                        'payment_date'
                    );
                    try {
                        await api.markPaymentPaid(companyId, paymentDate)
                        console.log('marked as paid')
                    } catch(e) {
                        onError(e)
                    }
                }
            }

            const details = await api.getDetails(companyId)
            console.log('details: ', details)

            const balanceDelivery = await api.getBalanceDelivery(companyId, 'pensions')
            console.log('balanceDelivery: ', balanceDelivery)

            const payments = await api.getPayments(companyId)
            console.log('payments: ', payments)
            payments.sort((a, b) => {
                return moment(b.date).valueOf() - moment(a.date).valueOf()
            })

            let paymentMethods = null
            if (companyDetails.stripeCustomerId) {
                paymentMethods = await api.getPaymentMethods(companyDetails.stripeCustomerId)
            }
            console.log('payment methods: ', paymentMethods)

            let paymentMethod = paymentMethods ? paymentMethods.paymentMethods.data[0] : null
            let hasPaymentMethod = (paymentMethods && paymentMethods.paymentMethods.data.length > 0) ? true : false
            console.log('has payment method: ', hasPaymentMethod)

            setDetails(details)
            setPayments(payments)
            setPaymentMethod(paymentMethod)
            setHasPaymentMethod(hasPaymentMethod)
            setBalanceDelivery(balanceDelivery)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function makePayment(payment) {
        console.log('has payment method: ', hasPaymentMethod)
        setLoading(true)
        if (hasPaymentMethod) {
            try {
                try {
                    let updatedPayment = await api.makePaymentExisting(payment)
                    console.log('payment: ', updatedPayment)

                    updatePayment(updatedPayment)
                    showSuccessAlert('Payment succeeded!')
                } catch(e) {
                    onError(e)
                }
            } catch(e) {
                onError(e)
            }
        }
        else {
            //alert('No payment method')
            openPaymentModal(payment)
        }
        setLoading(false)
    }

    function updatePayment(payment) {
        const index = payments.findIndex(oldPayment => (oldPayment.companyId === payment.companyId && oldPayment.date === payment.date))
        console.log('payment index: ', index)

        let newPayments = [...payments]
        newPayments[index] = payment
        //let checkStatus = showStatus === 'paid' ? true : false
        //let showPayments = newPayments.filter((payment) => (payment.paid === checkStatus))
        //console.log('check status: ', checkStatus)
        //console.log(showPayments)
        setPayments(newPayments)
    }

    async function openPaymentModal(payment) {
        try {   
            const paymentIntent = await api.makePaymentNew(payment)
            console.log('payment intent: ', paymentIntent)

            setClientSecret(paymentIntent.client_secret)
            setPayment(payment)
            setShowPaymentModal(true)
        } catch(e) {
            onError(e)
        }
    }

    function showSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    function closePaymentModal(payment) {
        setShowPaymentModal(false)
        setPayment(null)
    }

    // function updateHasPaymentMethod(hasPaymentMethodNew) {
    //     console.log('wtf: ', hasPaymentMethodNew)
    //     setHasPaymentMethod(hasPaymentMethodNew)
    // }

    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                marginTop='2rem'
                //classes={{ root: classes.containerRoot }}
            >
                {alert}
                {!loading ? (
                    <Grid container justifyContent='flex-start'>
                        {companyDetails.billingAccount === 'SS' &&
                            <Grid item xs={4}>
                                <PaymentMethodCard
                                    companyDetails={companyDetails}
                                    billingEmail={details.email}
                                    //updateHasPaymentMethod={(hasPaymentMethod) => updateHasPaymentMethod(hasPaymentMethod)}
                                    paymentMethod={paymentMethod}
                                    showSuccessAlert={(text) => showSuccessAlert(text)}
                                />
                            </Grid>
                        }
                        <Grid item xs={companyDetails.billingAccount === 'SS' ? 8 : 12}>
                            <PaymentsTable 
                                companyId={companyId}
                                payments={payments}
                                refreshPayments={() => getDetails()}
                                updatePayment={(payment) => updatePayment(payment)}
                                showSuccessAlert={(text) => showSuccessAlert(text)}
                                //hasPaymentMethod={hasPaymentMethod}
                                makePayment={(payment) => makePayment(payment)}
                                openPaymentModal={(payment) => openPaymentModal(payment)}
                                balanceDelivery={balanceDelivery}
                            />
                        </Grid>

                        {showPaymentModal &&
                            <Elements stripe={stripePromise} options={{clientSecret}}>
                                <PaymentModal 
                                    isOpen={showPaymentModal}
                                    close={() => closePaymentModal()}
                                    payment={payment}
                                    details={details}
                                    clientSecret={clientSecret}
                                />
                            </Elements>
                        }
                    </Grid>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    )
}

export default Billing