import React from 'react'
import Box from '@material-ui/core/Box'
import ErrorCard from './ErrorCard'
import { logError } from 'config/lib/errorLib'

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        logError(error, errorInfo)
    }

    render() {
        return this.state.hasError ? (
            <Box 
                display='flex' 
                flexDirection='column' 
                justifyContent='center' 
                alignItems='center'
                style={{marginTop: '50px'}}
            >
                <ErrorCard />
            </Box>
        ) : (
            this.props.children
        )
    }
}
