import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
//import Checkbox from '@material-ui/core/Checkbox'
import Container from '@material-ui/core/Container'
import FilledInput from '@material-ui/core/FilledInput'
import FormControl from '@material-ui/core/FormControl'
//import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import Email from '@material-ui/icons/Email'
import Lock from '@material-ui/icons/Lock'
import ForgotPasswordModal from 'components/general/ForgotPasswordModal'
import { NotificationManager } from 'react-notifications'
import { Auth } from 'aws-amplify'
import GuestAPI from 'config/api/GuestAPI'
import { onError } from 'config/lib/errorLib'
//import { useHistory } from 'react-router-dom'
import Loader from 'components/general/Loader'

import logo from 'resources/images/logo-blue-500.png'

// core components
import AuthHeader from 'components/argon/Headers/AuthHeader.js'
import componentStyles from 'assets/theme/views/auth/login.js'
import componentStylesButtons from 'assets/theme/components/button.js'

const useStyles = makeStyles(componentStyles)
const useStylesButtons = makeStyles(componentStylesButtons)

function Verify() {
    const classes = { ...useStyles(), ...useStylesButtons() }
    const theme = useTheme()
    //const history = useHistory()
    const api = new GuestAPI

    const [email, setEmail] = useState('')
    //const [password, setPassword] = useState('')
    //const [showForgotPassword, setShowForgotPassword] = useState(false)
    const [stage, setStage] = useState('login')
    const [confirmationCode, setConfirmationCode] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        var email = new URLSearchParams(window.location.search).get(
            'email'
        )
        console.log('email: ', email)
        if (email) {
            email = email.replace(/\D/g,'')
        }
        else {
            email = ''
        }

        setEmail(email)
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()

		if (stage === 'login') {
			signIn()
		}
		else {
			confirmAccount()
		}
    }

    async function signIn() {
        setLoading(true)
        console.log('email: ', email)

        try {
            const invite = await api.getInvite(email)
            console.log('invite: ', invite)

            const user = await Auth.signIn(email, invite.pw)
            console.log('user: ', user)

            window.location.replace('/')
        } catch(e) {
            console.log(e)
            console.log(e.code)
            if (e.code === 'UserNotConfirmedException') {
                //NotificationManager.error('You must confirm your account in order to log in. Please check your email for a confirmation link')
                //TODO; change this to stage on login screen (for brokers)
                setStage('confirmation')
            }
            else if (e.code === 'UserNotFoundException') {
                NotificationManager.error('An account with this email has not yet been registered.')
            }
            else if (e.code === 'NotAuthorizedException') {
                NotificationManager.error('The email or password you have entered is incorrect')
            }
            else {
                onError(e)
            }
            setLoading(false)
        }
    }

    async function confirmAccount() {
        setLoading(true)
        if (confirmationCode.length > 0) {
            try {
                await Auth.confirmSignUp(email, confirmationCode)
                //await api.sendWelcomeEmail(email)
                signIn()
            } catch(e) {
                console.log(e.code)
                if (e.code === 'CodeMismatchException') {
                    NotificationManager.error('Invalid code, please ensure you have entered the correct code')
                }
                onError(e)
            }
        }
        else {
            NotificationManager.error('Please enter the code that was sent to ' + email)
            setLoading(false)
        }
    }

    async function resendCode() {
        try {
            await Auth.resendSignUp(email)
            NotificationManager.success('Confirmation code sent to ' + email)
        } catch(e) {
            console.log(e.code)
            onError(e)
        }
    }
  
    return (
        <>  
            <Box 
                display='flex'
                justifyContent='center'
                alignItems='center'
                style={{paddingTop: '5rem'}}
            >
                <img className='login-logo' src={logo} alt='brokerdiary-logo' />
            </Box>
            
            {stage === 'login' &&
                <AuthHeader
                    title='Welcome!'
                    description='Verify your email to get full access to your account'
                />
            }

            {stage === 'confirmation' &&
                <AuthHeader
                    title='Check your email'
                    description={'We just sent a confirmation code via email to ' + email}
                />
            }
          
            <Container
                component={Box}
                maxWidth='xl'
                marginTop='-12rem'
                paddingBottom='3rem'
                position='relative'
                zIndex='101'
            >
                {stage === 'login' &&
                    <form onSubmit={handleSubmit}>
                        <Box component={Grid} container justifyContent='center'>
                            <Grid item xs={12} lg={5} md={7}>
                                <Card classes={{ root: classes.cardRoot }} style={{backgroundColor: '#fff'}}>
                                    <CardContent classes={{ root: classes.cardContent }}>
                                        <Box
                                            color={theme.palette.gray[600]}
                                            textAlign='center'
                                            marginBottom='1rem'
                                            marginTop='.5rem'
                                            fontSize='1rem'
                                        >
                                            <Box fontSize='80%' fontWeight='400' component='small'>
                                                Enter your work email below
                                            </Box>
                                        </Box>
                                
                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                            marginBottom='1rem!important'
                                        >
                                            <FilledInput
                                                autoComplete='off'
                                                type='email'
                                                placeholder='Email'
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                startAdornment={
                                                    <InputAdornment position='start'>
                                                        <Email />
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        {/*
                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                            marginBottom='1rem!important'
                                        >
                                            <FilledInput
                                                autoComplete='off'
                                                type='password'
                                                placeholder='Password'
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                startAdornment={
                                                    <InputAdornment position='start'>
                                                        <Lock />
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        */}
                                        <Box
                                            textAlign='center'
                                            marginTop='1.5rem'
                                            marginBottom='1.5rem'
                                        >
                                            <Button
                                                variant='contained'
                                                classes={{ root: classes.buttonContainedInfo }}
                                                //onClick={() => signIn()}
                                                type='submit'
                                                disabled={loading}
                                            >
                                                {!loading ? (
                                                    <>Verify Email</>
                                                ) : (
                                                    <Loader height={12} />
                                                )}
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Box>
                    </form>
                }
                {stage === 'confirmation' &&
                    <form onSubmit={handleSubmit}>
                        <Box component={Grid} container justifyContent='center'>
                            <Grid item xs={12} lg={6} md={8}>
                                <Card classes={{ root: classes.cardRoot }} style={{backgroundColor: '#fff'}}>
                                    <CardContent classes={{ root: classes.cardContent }}>
                                        <Box
                                            color={theme.palette.gray[600]}
                                            textAlign='center'
                                            marginBottom='1rem'
                                            marginTop='.5rem'
                                            fontSize='1rem'
                                        >
                                            <Box fontSize='80%' fontWeight='400' component='small'>
                                                Enter your confirmation code below
                                            </Box>
                                        </Box>

                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                            marginBottom='1rem!important'
                                        >
                                            <FilledInput
                                                autoComplete='off'
                                                type='email'
                                                placeholder='Email'
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </FormControl>

                                        <FormControl
                                            variant='filled'
                                            component={Box}
                                            width='100%'
                                            marginBottom='1rem!important'
                                        >
                                            <FilledInput
                                                autoComplete='off'
                                                type='text'
                                                placeholder='Confirmation Code'
                                                value={confirmationCode}
                                                onChange={(e) => setConfirmationCode(e.target.value)}
                                            />
                                        </FormControl>
                                        
                                        <Box
                                            textAlign='center'
                                            marginTop='1.5rem'
                                            marginBottom='1.5rem'
                                        >
                                            <Button
                                                variant='contained'
                                                classes={{ root: classes.buttonContainedInfo }}
                                                onClick={() => resendCode()}
                                                style={{marginRight: '1rem'}}
                                            >
                                                Resend Code
                                            </Button>
                                            <Button
                                                variant='contained'
                                                classes={{ root: classes.buttonContainedInfo }}
                                                //onClick={() => confirmAccount()}
                                                type='submit'
                                                disabled={loading}
                                            >
                                                {!loading ? (
                                                    <>Confirm Account</>
                                                ) : (
                                                    <Loader height={12} />
                                                )}
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Box>
                    </form>
                }
            </Container>
        </>
    )
}

export default Verify