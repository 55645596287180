import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
//import CardFooter from '@material-ui/core/CardFooter'
import Typography from '@material-ui/core/Typography'
import BootstrapTable from 'react-bootstrap-table-next'
import Grid from '@material-ui/core/Grid'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Loader from 'components/general/Loader'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'
import filterFactory, { textFilter, selectFilter, numberFilter } from 'react-bootstrap-table2-filter'
import { getProductString } from 'config/helpers/Helpers'
import { NotificationManager } from 'react-notifications'
import cellEditFactory from 'react-bootstrap-table2-editor'
import componentStyles from 'assets/theme/views/admin/react-bs-table.js'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

const useStyles = makeStyles(componentStyles)

function AllocationsTable(props) {
    const classes = { ...useStyles() }
    const api = new BrokerAPI()

    //const brokers = props.brokers
    const product = props.product
    const companyDetails = props.companyDetails
    const allocated = props.allocated
    const allocations = companyDetails.allocations[product] ? companyDetails.allocations[product] : {}
    const extraAllocations = (companyDetails.extraAllocations && companyDetails.extraAllocations[product]) ? companyDetails.extraAllocations[product] : {}
    const dailyCaps = (companyDetails.dailyCaps && companyDetails.dailyCaps[product]) ? companyDetails.dailyCaps[product] : {}
    const weeklyLimit = props.weeklyLimit

    let updatedBrokers = []
    for (let broker of props.brokers) {
        if (!broker.products || broker.products[0] === 'All' || broker.products.indexOf(product) > -1) {
            broker['weeklyAllocation'] = allocations[broker.brokerId] ? allocations[broker.brokerId] : 0
            broker['extraAllocation'] = extraAllocations[broker.brokerId] ? extraAllocations[broker.brokerId] : 0
            broker['dailyCap'] = dailyCaps[broker.brokerId] ? dailyCaps[broker.brokerId] : 0
            updatedBrokers.push(broker)
        }
    }
    // highest weekly allocation first
    updatedBrokers.sort((a, b) => {
        let aAllocation = allocations[a.brokerId] ? allocations[a.brokerId] : 0
        let bAllocation = allocations[b.brokerId] ? allocations[b.brokerId] : 0
        return bAllocation - aAllocation
    })
    // updatedBrokers.map((broker) => {
    //     broker['weeklyAllocation'] = allocations[broker.brokerId] ? allocations[broker.brokerId] : 0
    //     broker['extraAllocation'] = extraAllocations[broker.brokerId] ? extraAllocations[broker.brokerId] : 0
    //     broker['dailyCap'] = dailyCaps[broker.brokerId] ? dailyCaps[broker.brokerId] : 0
    // })
    //console.log('brokers: ', updatedBrokers)

    //const [allocations, setAllocations] = useState(companyDetails.allocations[product])
    //const [dailyCaps, setDailyCaps] = useState(companyDetails.dailyCaps[product])
    //const [editAllocations, setEditAllocations] = useState(false)
    const [loading, setLoading] = useState(false)
    const [brokers, setBrokers] = useState(updatedBrokers)
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [invalidAllocations, setInvaildAllocations] = useState(false)

    let columns = [
        {
            dataField: 'brokerId',
            text: 'Broker',
            sort: true,
            editable: false,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{row.firstName + ' ' + row.lastName}</>
            }
        },
        {
            dataField: 'weeklyAllocation',
            text: 'Weekly Allocation',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return (
                    <>{row.weeklyAllocation}</>
                )
            }
        },
        {
            dataField: 'delivered',
            text: 'Delivered',
            sort: true,
            editable: false,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return (
                    <>{allocated[row.brokerId] ? allocated[row.brokerId] : 0}</>
                )
            }
        },
        {
            dataField: 'counties',
            text: 'Counties',
            sort: true,
            editable: false,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{row.counties && row.counties.length > 0 ? row.counties.join(', ') : ''}</>
            }
        }
    ]
    // if replacements
    if (companyDetails.replacementsActive) {
        let replacements = {
            dataField: 'extraAllocation',
            text: 'Extra Allocation',
            sort: true,
            editable: false,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return (
                    <>{row.extraAllocation}</>
                )
            }
        }
        columns.splice(2, 0, replacements)
    }
    // if active add daily caps
    if (companyDetails.dailyCapsActive) {
        let dailyCap = {
            dataField: 'dailyCap',
            text: 'Daily Limit',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{row.dailyCap}</>
            }
        }
        columns.splice(3, 0, dailyCap)
    }

    const handleTableChange = (type, { data, cellEdit: { rowId, dataField, newValue } }) => {
        console.log(rowId)
        console.log(dataField)
        console.log(newValue)

        // update state to new field
        const result = data.map((row) => {
            console.log(row)
            if (row.brokerId === rowId) {
                const newRow = { ...row }
                if (newRow[dataField] !== newValue) {
                    setUnsavedChanges(true)
                }
                newRow[dataField] = newValue
                return newRow
            }
            return row
        })
        console.log('table change result: ', result)
        //setBrokers(result.sort((a, b) => b.weeklyAllocation - a.weeklyAllocation))
        setBrokers(result)
        checkAllocations(result)
    }

    function checkAllocations(data) {
        let total = 0
        //let hasUnlimited = false
        for (var broker of data) {
            //updatedAllocations[broker.brokerId] = broker['weeklyAllocation']
            let allocation = parseInt(broker['weeklyAllocation'])
            if (allocation !== -1) {
                total += allocation 
            }
        }
        console.log('total: ', total)
        console.log('is nan: ', isNaN(total))
        console.log('weekly limit: ', weeklyLimit)
        if (total !== weeklyLimit || isNaN(total)) {
            setInvaildAllocations(true)
        }
        else {
            setInvaildAllocations(false)
        }
    }

    async function saveAllocations() {
        console.log(brokers)
        let updatedAllocations = {}
        let updatedDailyCaps = {}
        let total = 0
        for (var broker of brokers) {
            updatedAllocations[broker.brokerId] = broker['weeklyAllocation']
            updatedDailyCaps[broker.brokerId] = broker['dailyCap']
            if (parseInt(broker['weeklyAllocation']) !== -1) {
                total += parseInt(broker['weeklyAllocation'])
            }
        }
        console.log('allocations: ', updatedAllocations)
        console.log('daily caps: ', updatedDailyCaps)
        console.log('total: ', total)

        if (total === weeklyLimit) {
            try {
                let result = await api.updateAllocations(companyDetails.companyId, props.product, updatedAllocations)
                console.log('update allocations: ', result)

                if (companyDetails.dailyCapsActive) {
                    result = await api.updateDailyCaps(companyDetails.companyId, props.product, updatedDailyCaps)
                    console.log('update daily caps: ', result)
                }
                NotificationManager.success('Allocations updated')
                setUnsavedChanges(false)
            } catch(e) {
                onError(e)
            }
        }
        else {
            NotificationManager.error('Invalid allocations. Values must add up to total weekly allocation. Email colm@bq.ie if you need to update your total weekly allocation.')
        }
    }

    return (
        <Card>
            {!loading ? (
                <>
                    <CardHeader
                        subheader={
                            <>
                                <Box display='flex' style={{justifyContent: 'space-between'}}>
                                    <Box>
                                        <Box
                                            component={Typography}
                                            variant='h2'
                                            marginBottom='0!important'
                                        >
                                            <Box component='span'>{getProductString(props.product)} Allocations</Box>
                                        </Box>
                                        <Box
                                            component='p'
                                            fontSize='.875rem'
                                            marginBottom='0'
                                            marginTop='0'
                                            lineHeight='1.7'
                                            fontWeight='300'
                                        >
                                            Click on an allocation or daily limit to make changes
                                        </Box>
                                    </Box>
                                </Box>
                            </>
                        }
                        classes={{ root: classes.cardHeaderRoot }}
                    ></CardHeader>

                    <div className='table-container'>
                        <BootstrapTable 
                            keyField='brokerId' 
                            data={ brokers } 
                            columns={ columns } 
                            filter={ filterFactory() } 
                            noDataIndication={<Box>No brokers</Box>}
                            cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) }
                            onTableChange={ handleTableChange }
                            remote={ {
                                filter: false,
                                pagination: false,
                                sort: false,
                                cellEdit: true
                            } }
                        />
                    </div>
                    
                    {unsavedChanges &&
                        <Grid container direction='column' justifyContent='center' alignItems='center' spacing={1} style={{margin: '1rem'}}>
                            {invalidAllocations ? (
                                <Grid item xs={12} style={{textAlign: 'center'}}>
                                    <Box class='warning-text'>Invalid allocations. Values must add up to total weekly allocation ({weeklyLimit}).</Box>
                                    <Box class='warning-text'>Email colm@bq.ie if you need to update your total weekly allocation.</Box>
                                </Grid>
                            ) : (
                                <>
                                <Grid item xs={12} style={{textAlign: 'center'}}>
                                    <Box class='warning-text'>You have made unsaved changes to your allocations</Box>
                                </Grid>
                                <Grid item>
                                    <Button
                                        component={Box}
                                        color='primary'
                                        variant='contained'
                                        size='medium'
                                        //disabled={checkRequiredFields()}
                                        onClick={() => saveAllocations()}
                                    >
                                        Save Changes
                                    </Button>
                                </Grid>
                                </>
                            )}
                        </Grid>
                    }
                </>
            ) : (
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                            <Loader />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Card>
    );
}

export default AllocationsTable