import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
//import QuotesModal from 'components/broker/QuotesModal'
import BrokerAPI from 'config/api/BrokerAPI'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
import { providerOptions } from 'config/constants/Constants'
import { getProductString } from 'config/helpers/Helpers'

import DetailsContext from 'config/context/DetailsContext'

function BIQuoteDetails(props) {
    const api = props.admin ? new AdminAPI() : new BrokerAPI()
    const companyDetails = useContext(DetailsContext)
	const companyId = companyDetails.companyId || props.companyId

    const quote = props.lead.quote
    
    const [insuranceType, setInsuranceType] = useState(quote.insuranceType || '')
    const [premium, setPremium] = useState(quote.premium || '')
    const [businessType, setBusinessType] = useState(quote.businessType || '')
    const [businessName, setBusinessName] = useState(quote.businessName || '')
    const [turnover, setTurnover] = useState(quote.turnover || '')
    const [showContent, setShowContent] = useState(props.showQuoteContent)
    const [showQuotesModal, setShowQuotesModal] = useState(false)
 
    async function updateQuote() {
        try {
            let quote = {
                insuranceType,
                businessType,
                businessName,
                turnover,
                premium
            }
            
            let result = null
            if (props.admin) {
                result = await api.updateLeadQuote(props.lead.date, props.lead.leadId, quote)
            }
            else {
                result = await api.updateLeadQuote(companyId, props.lead.product, props.lead.phone, quote)
            }

            props.updateLead(result)
            NotificationManager.success('Quote updated')
            setShowContent(false)
        } catch(e) {
            onError(e)
        }
    }

    // function updatePremium(premium) {
    //     let formatted = premium.replace(/€/g, '')
    //     setPremium(formatted)
    // }

    // function updateCurrentPremium(currentPremium) {
    //     let formatted = currentPremium.replace(/€/g, '')
    //     setCurrentPremium(formatted)
    // }
    
    return (
        <Card>
            <CardHeader
                subheader={
                    <Grid
                        container
                        component={Box}
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Grid item xs='auto'>
                            <Box
                                component={Typography}
                                variant='h3'
                                marginBottom='0!important'
                            >
                                Business Insurance Details
                            </Box>
                        </Grid>
                        <Grid item xs='auto'>
                            <Box>
                                {showContent ? (
                                    <KeyboardArrowUp />
                                ) : (
                                    <KeyboardArrowDown />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                }
                onClick={() => setShowContent(!showContent)} 
                style={{cursor: 'pointer'}}
            >
            </CardHeader>
            
            {showContent &&
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Insurance Type</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    autoFocus
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={insuranceType}
                                        onChange={(e) => setInsuranceType(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Business Type</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    autoFocus
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={businessType}
                                        onChange={(e) => setBusinessType(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Business Name</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    autoFocus
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={businessName}
                                        onChange={(e) => setBusinessName(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>Turnover</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    autoFocus
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='text'
                                        value={turnover}
                                        onChange={(e) => setTurnover(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={12} lg={6}>
                            <FormGroup>
                                <FormLabel>{companyId === 'cib' ? 'Monthly Income/Commission (€)' : 'Monthly Premium (€)'}</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                    autoFocus
                                    //marginBottom='1rem!important'
                                >
                                    <OutlinedInput
                                        fullWidth
                                        type='number'
                                        value={premium}
                                        onChange={(e) => setPremium(e.target.value)}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        
                        <Grid item xs={12} lg={12}>
                            <Grid container justifyContent='space-between'>
                                <Grid item>
                                    {/*
                                    <Button
                                        onClick={() => setShowQuotesModal(true)}
                                        color='primary'
                                        disabled
                                    >
                                        Generate Quotes
                                    </Button>
                                    */}
                                </Grid>
                                <Grid item>
                                    <Button
                                        component={Box}
                                        color='primary'
                                        variant='contained'
                                        size='medium'
                                        disabled={false}
                                        onClick={() => updateQuote()}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/*showQuotesModal &&
                        <QuotesModal 
                            isOpen={showQuotesModal}
                            close={() => setShowQuotesModal(false)}
                            clientDetails={props.clientDetails}
                            application={application}
                            updateApplication={(provider, premium, noPeople, coverAmount, coverAmount2, illnessCover, illnessCoverAmount, illnessCoverAmount2, policyTerm, convertibleOption, indexation) => updateApplication(provider, premium, noPeople, coverAmount, coverAmount2, illnessCover, illnessCoverAmount, illnessCoverAmount2, policyTerm, convertibleOption, indexation)}
                            type={props.type}
                        />
                    */}
                </CardContent>
            }
        </Card>
    )
}

export default BIQuoteDetails