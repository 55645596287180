import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

// update this to just format any hyphenated string
export function getProductString(product) {
	if (product === 'income-protection') {
		return 'Income Protection'
	}
	else if (product === 'mortgage-protection') {
		return 'Mortgage Protection'
	}
	else if (product === 'life-insurance') {
		return 'Life Insurance'
	}
	else if (product === 'pensions') {
		return 'Pensions'
	}
	else if (product === 'health-insurance') {
		return 'Health Insurance'
	}
	else if (product === 'home-insurance') {
		return 'Home Insurance'
	}
	else if (product === 'business-insurance') {
		return 'Business Insurance'
	}
	else if (product === 'gadget-insurance') {
		return 'Gadget Insurance'
	}
	else if (product === 'pet-insurance') {
		return 'Pet Insurance'
	}
	else if (product === 'travel-insurance') {
		return 'Travel Insurance'
	}
	else if (product === 'general') {
		return 'General'
	}
}

export function getLeadActivityTimeline(lead, tasks) {
	let items = [];

	items.push({category: 'core', label: 'Lead Created', timestamp: lead.createdAt - 100})

	for (var note of lead.notes) {
		items.push({category: 'note', label: 'Note', timestamp: note.createdAt, note: note})
	}
	for (var call of lead.calls) {
		items.push({category: 'call', label: 'Call', timestamp: call.date, call: call})
	}
	for (var task of tasks) {
		// may have to call get tasks here or before - maybe just when someone opens the lead modal - or if no tasks get tasks and can use on both screens
		items.push({category: 'task', label: 'Task', timestamp: task.date, task: task})
	}

	if (lead.activity.length > 0) {
		items = items.concat(lead.activity)
	}

	items.sort((a, b) => {
		return new Date(b.timestamp) - new Date(a.timestamp)
	})

	return items
}

export function getPipelineCount(leads) {
	console.log('count leads...')
	let newLead = 0
	let attempting = 0
	let engaged = 0
	let meeting = 0
	let pending = 0
	let won = 0
	let lost = 0


	for (var lead of leads) {
		if (lead.stage === 'new') {
			newLead++
		}
		if (lead.stage === 'attempting') {
			attempting++
		}
		if (lead.stage === 'engaged') {
			engaged++
		}
		if (lead.stage === 'meeting') {
			meeting++
		}
		if (lead.stage === 'pending') {
			pending++
		}
		if (lead.stage === 'won') {
			won++
		}
		if (lead.stage === 'lost') {
			lost++
		}
	}

	let count = {
		new: newLead,
		attempting: attempting,
		engaged: engaged,
		meeting: meeting,
		pending: pending,
		won: won,
		lost: lost
	}
	console.log('count: ', count)
	return count
}

export function stringToHex(str) {
	let hex = ''
	for (let i = 0; i < str.length; i++) {
	  	const charCode = str.charCodeAt(i)
	  	const hexValue = charCode.toString(16)
	  	// Pad with zeros to ensure two-digit representation
	  	hex += hexValue.padStart(2, '0')
	}
	return hex
}

export function hexToString(hex) {
	let str = ''
  	for (let i = 0; i < hex.length; i += 2) {
    	const hexValue = hex.substr(i, 2)
    	const decimalValue = parseInt(hexValue, 16)
    	str += String.fromCharCode(decimalValue)
  	}
  	return str
}

export function getMoments(period) {
	let fromDate = moment()
	let toDate = moment()
	if (period === 'yesterday') {
		fromDate.subtract(1, 'day')
		fromDate.set('hour', 0).set('minute', 0).set('second', 0)
		toDate.subtract(1, 'day')
		toDate.set('hour', 23).set('minute', 59).set('second', 59)
	}
	if (period === 'today') {
		fromDate.set('hour', 0).set('minute', 0).set('second', 0)
		toDate.set('hour', 23).set('minute', 59).set('second', 59)
	}
	if (period === 'thisWeek') {
		// saturday -1 week at 00:00:00
		fromDate.set('weekday', 6).subtract(1, 'week')
        fromDate.set('hour', 0).set('minute', 0).set('second', 0)
		// this Friday at 23:59:59
		toDate.set('weekday', 5)
        toDate.set('hour', 23).set('minute', 59).set('second', 59)
	}
	if (period === 'lastWeek') {
		// saturday -2 weeks at 00:00:00
		fromDate.set('weekday', 6).subtract(2, 'week')
        fromDate.set('hour', 0).set('minute', 0).set('second', 0)
		// Friday -1 week at 23:59:59
        toDate.set('weekday', 5).subtract(1, 'week')
        toDate.set('hour', 23).set('minute', 59).set('second', 59)
	}
	if (period === 'thisMonth') {
		fromDate.startOf('month')
        toDate.endOf('month')
	}
	if (period === 'lastMonth') {
		fromDate.subtract(1, 'month').startOf('month')
        toDate.subtract(1, 'month').endOf('month')
	}
	if (period === 'yearToDate') {
		fromDate.startOf('year')
        //toDate.endOf('year')
	}
	return {
		fromDate: fromDate,
		toDate: toDate
	}
}

export function getTooltipContent(lead) {
	let quoteInfo = null
	let excludeFields = [
		'activity',
		'notes',
		'calls',
		'tasks',
		'leadId',
		'createdAt',
		'stage',
		'isValid'
	]
	return (
		<div class='lead-tooltip'>
			{Object.keys(lead).map((key) => {
				if (lead[key]) {
					// console.log(lead[key])
					// console.log(excludeFields.indexOf(lead[key]))
					if (excludeFields.indexOf(key) < 0) {
						if (key === 'quote') {
							quoteInfo = lead[key]
						}
						else {
							return (
								<p><strong>{key}</strong>: {lead[key]}</p>
							)
						}
					}
				}
			})}
			{Object.keys(quoteInfo).map((key) => {
				if (quoteInfo[key]) {
					return (
						<p><strong>{key}</strong>: {quoteInfo[key]}</p>
					)
				}
			})}
		</div>
	)
}