import React, { useState, useEffect } from 'react'
import { useLocation, Switch, Route, Redirect } from 'react-router-dom'
import AdminNavbarAlternative from 'components/argon/Navbars/AdminNavbarAlternative.js'
//import Sidebar from 'components/argon/Sidebar/Sidebar.js'
import Box from '@material-ui/core/Box'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PeopleIcon from '@material-ui/icons/People'
//import FolderIcon from '@material-ui/icons/FolderShared'
import SettingsIcon from '@material-ui/icons/Settings'
import TasksIcon from '@material-ui/icons/CalendarToday'
//import CampaignsIcon from '@material-ui/icons/Subscriptions'
import HelpIcon from '@material-ui/icons/Help'

import logo from 'resources/images/logo-blue-500.png'

import { makeStyles } from '@material-ui/core/styles'
import componentStyles from 'assets/theme/layouts/admin.js'

import ChangePassword from 'scenes/broker/ChangePassword'

const useStyles = makeStyles(componentStyles)

const Main = (props) => {
    const classes = useStyles()
    const location = useLocation()

    const details = props.details

    useEffect(() => {
        document.documentElement.scrollTop = 0
        document.scrollingElement.scrollTop = 0
        // mainContent.current.scrollTop = 0
    }, [location])

    return (
        <>
            <Box display='flex'>
                <Box position='relative' flex='1' className={classes.mainContent}>
                    <AdminNavbarAlternative
                        //openSidebarResponsive={() => setSidebarOpenResponsive(true)}
                        accountType='broker'
                        permissions={details.permissions}
                        name={details.firstName + ' ' + details.lastName}
                    />
                    
                    <Switch>
                        <Route exact path='/change-password' component={ChangePassword} />
                        <Redirect from='*' to='/change-password' />
                    </Switch>
                </Box>
            </Box>
        </>
    )
}

export default Main