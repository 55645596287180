import React from 'react'
import Grid from '@material-ui/core/Grid'
import TeamIcon from '@material-ui/icons/Group'
import LoginIcon from '@material-ui/icons/LockOpen'
import CheckIcon from '@material-ui/icons/Check'

import CardStats from 'components/argon/Cards/Dashboard/CardStats.js'

const StartPensionStats = ( props ) => {
    const totals = props.totals
    
    return (
        <Grid container direction='row' spacing={1}>
            <Grid item xs={12} md={4}>
                <CardStats
                    subtitle='All'
                    title={totals.all}
                    icon={TeamIcon}
                    color='bgWarning'
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <CardStats
                    subtitle='Verified'
                    title={totals.loggedIn}
                    icon={LoginIcon}
                    color='bgSuccess'
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <CardStats
                    subtitle='Submitted'
                    title={totals.submitted}
                    icon={CheckIcon}
                    color='bgPrimary'
                />
            </Grid>
        </Grid>
    )
}

export default StartPensionStats
