import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import ValidationList from 'components/admin/ValidationList'

import componentStyles from 'assets/theme/components/cards/cards/card-button.js'

const useStyles = makeStyles(componentStyles)

function Settings() {
    const api = new AdminAPI()

    const classes = { ...useStyles() }

    const [validationLists, setValidationLists] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getSettings()
    }, [])

    async function getSettings() {
        try {
            const lists = await api.getValidationLists()
            console.log('validation lists: ', lists)

            setValidationLists(lists)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <Grid container>
                        <Grid item xs={12} xl={12}>
                            <Card
                                classes={{
                                    root: classes.cardRoot,
                                }}
                            >
                                <CardHeader
                                    subheader={
                                        <Grid
                                            container
                                            component={Box}
                                            alignItems='center'
                                            justifyContent='space-between'
                                        >
                                            <Grid item xs='auto'>
                                                <Box
                                                    component={Typography}
                                                    variant='h3'
                                                    marginBottom='0!important'
                                                >
                                                    Lead Validation
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    }
                                    classes={{ root: classes.cardHeaderRoot }}
                                >
                                </CardHeader>
                                
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={3}>  
                                            <ValidationList 
                                                title={'Profanities (Not Allowed)'}
                                                list={'profanities'}
                                                items={validationLists.profanities.join('\n')}
                                                updateLists={(lists) => setValidationLists(lists)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>  
                                            <ValidationList 
                                                title={'Fake Names (Not Allowed)'}
                                                list={'fakeNames'}
                                                items={validationLists.fakeNames.join('\n')}
                                                updateLists={(lists) => setValidationLists(lists)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>  
                                            <ValidationList 
                                                title={'Famous Names (Not Allowed)'}
                                                list={'famousNames'}
                                                items={validationLists.famousNames.join('\n')}
                                                updateLists={(lists) => setValidationLists(lists)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={3}>  
                                            <ValidationList 
                                                title={'Phone Prefixes (Allowed)'}
                                                list={'phonePrefix'}
                                                items={validationLists.phonePrefix.join('\n')}
                                                updateLists={(lists) => setValidationLists(lists)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{marginTop: '2rem'}}>
                                        <Grid item xs={12}>
                                            <h3>Active Validation Checks</h3>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box style={{color: '#525f7f'}}>
                                                <ul>
                                                    <li>Full Name checked for exact match to Fake Names & Famous Names</li>
                                                    <li>First Name checked for contains Profanities</li>
                                                    <li>Last Name checked for contains Profanities</li>
                                                    <li>Email start (before @) checked for contains Profanities</li>
                                                    <li>Phone checked for starts with Phone Prefixes</li>
                                                    <li>Phone checked for 4 repeated numbers (1111)</li>
                                                    <li>Phone checked for 4 sequential numbers (1234)</li>
                                                </ul>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        {/*
                        <Grid item xs={6} xl={6}>
                            <Card
                                classes={{
                                    root: classes.cardRoot,
                                }}
                            >
                                <CardHeader
                                    subheader={
                                        <Grid
                                            container
                                            component={Box}
                                            alignItems='center'
                                            justifyContent='space-between'
                                        >
                                            <Grid item xs='auto'>
                                                <Box
                                                    component={Typography}
                                                    variant='h3'
                                                    marginBottom='0!important'
                                                >
                                                    Resources
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    }
                                    classes={{ root: classes.cardHeaderRoot }}
                                >
                                </CardHeader>
                                
                                <CardContent>
                                    <Grid container direction='column' spacing={2}>
                                        <Grid item xs={12} lg={12}>  
                                            <Typography style={{fontWeight: '700', color: '#525f7f'}}>Income Protection</Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={12}>  
                                            <a href='https://brokerdiary.io/wp-content/uploads/2022/11/Setting-A-Meeting-From-Insure-Your-Income.pdf'>Setting A Meeting From Insure Your Income</a>
                                        </Grid>
                                        <Grid item xs={12} lg={12}>  
                                            <a href='https://brokerdiary.io/wp-content/uploads/2022/10/Claims-Report-2021-002-3.pdf'>Aviva Claims Report</a>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        */}
                    </Grid> 
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default Settings