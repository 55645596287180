import React, { useState, useEffect, useContext } from 'react'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import BrokerAPI from 'config/api/BrokerAPI'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { onError } from 'config/lib/errorLib'
import Loader from 'components/general/Loader'
import AllocationsTable from 'components/broker/AllocationsTable'
import BrokersCard from 'components/broker/BrokersCard'
import AllocationStats from 'components/broker/AllocationStats'
import { defaultProducts } from 'config/constants/Constants'
import { getProductString } from 'config/helpers/Helpers'

import DetailsContext from 'config/context/DetailsContext'

function Allocations() {
    const api = new BrokerAPI()
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId
    const productsAvailable = companyDetails.productsAvailable || defaultProducts
    let initialProduct = 'income-protection'
    if (productsAvailable['income-protection'] === false) {
        for (let key of Object.keys(productsAvailable)) {
            if (productsAvailable[key] === true) {
                console.log(key)
                initialProduct = key
            }
        }
    }
    console.log('products available: ', productsAvailable)
    console.log('initial product: ', initialProduct)

    const [details, setDetails] = useState(null)
    const [allBrokers, setAllBrokers] = useState([])
    const [brokers, setBrokers] = useState([])
    const [invites, setInvites] = useState([])
    //const [broker, setBroker] = useState(null)
    const [product, setProduct] = useState(initialProduct)
    const [allocated, setAllocated] = useState([])
    const [delivery, setDelivery] = useState(null)
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)

    //const weeklyLimit = parseInt(companyDetails.weeklyLimits[product]) + parseInt(companyDetails.extraWeeklyLimits[product])

    useEffect(() => {
        getDetails()
    }, [])

    async function getDetails() {
        try {
            const details = await api.getDetails(companyId)
            console.log('details: ', details)

            let brokers = await api.getBrokers(companyId)
            console.log('brokers: ', brokers)

            let activeBrokers = brokers.filter(broker => (!broker.hideInAnalytics) && (!broker.products || broker.products[0] === 'All' || broker.products.indexOf(product) > -1))

            const invites = await api.getInvites(companyId)
            console.log('invites: ', invites)

            const allocated = await api.getAllocatedLeads(companyId, product)
            console.log('allocated: ', allocated)

            const delivery = await api.getDelivery(companyId, product)
            console.log('delivery: ', delivery)

            setDetails(details)
            setAllBrokers(brokers)
            setBrokers(activeBrokers)
            setInvites(invites)
            setAllocated(allocated)
            setDelivery(delivery)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function updateBroker(broker) {
        setLoading(true)
        const index = brokers.findIndex(oldBroker => (oldBroker.brokerId === broker.brokerId))
        console.log('broker index: ', index)

        let newBrokers = [...brokers]
        newBrokers[index] = broker
        console.log(newBrokers)
        
        setBrokers(newBrokers)
        setLoading(false)
    }

    async function updateProduct(product) {
        setProduct(product)
        setLoading(true)
        try {
            const allocated = await api.getAllocatedLeads(companyId, product)
            console.log('allocated: ', allocated)

            const delivery = await api.getDelivery(companyId, product)
            console.log('delivery: ', delivery)

            let activeBrokers = allBrokers.filter(broker => (!broker.hideInAnalytics) && (!broker.products || broker.products[0] === 'All' || broker.products.indexOf(product) > -1))

            setAllocated(allocated)
            setDelivery(delivery)
            setBrokers(activeBrokers)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function showSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                marginTop='2rem'
                //classes={{ root: classes.containerRoot }}
            >
                {alert}
                {!loading ? (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Grid
                                container
                                component={Box}
                                alignItems='center'
                                justifyContent='flex-start'
                                style={{marginBottom: '2rem'}}
                                direction='row'
                            >
                                <Grid item xs={12} md={2}>
                                    <FormGroup style={{marginBottom: '0'}}>
                                        <FormControl variant='outlined' fullWidth>
                                            <Select
                                                multiple={false}
                                                IconComponent={KeyboardArrowDown}
                                                value={product}
                                                onChange={(e) => updateProduct(e.target.value)}
                                            >
                                                {Object.keys(productsAvailable).map((product) => {
                                                    if (productsAvailable[product]) {
                                                        return (<MenuItem value={product}>{getProductString(product)}</MenuItem>)
                                                    }
                                                })}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Grid container direction='row' spacing={1}>
                                <Grid item xs={12}>
                                    <AllocationStats 
                                        allocation={delivery ? delivery.allocation : 0}
                                        extraAllocation={delivery ? delivery.extraAllocation : 0}
                                        delivered={allocated.total}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AllocationsTable
                                        details={details}
                                        brokers={brokers}
                                        product={product}
                                        companyDetails={companyDetails}
                                        allocated={allocated.allocated}
                                        weeklyLimit={delivery ? parseInt(delivery.allocation) : 0}
                                        //requests={requests}
                                        //updateRequest={(request) => deleteRequest(request)}
                                        //deleteRequest={(request) => deleteRequest(request)}
                                        //showSuccessAlert={(text) => showSuccessAlert(text)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <BrokersCard
                                brokers={allBrokers}
                                invites={invites}
                                refreshBrokers={() => getDetails()}
                                updateBroker={(broker) => updateBroker(broker)}
                                showSuccessAlert={(text) => showSuccessAlert(text)}
                                allocations={companyDetails.allocations}
                                product={product}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    )
}

export default Allocations