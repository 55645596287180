import React, { useState, useEffect, useContext } from 'react'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import BrokerAPI from 'config/api/BrokerAPI'
import AdminAPI from 'config/api/AdminAPI'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { onError } from 'config/lib/errorLib'
import Loader from 'components/general/Loader'
import LeadsTable from 'components/broker/LeadsTable'
import { NotificationManager } from 'react-notifications'
import { getPipelineCount, getProductString } from 'config/helpers/Helpers'

function LeadBoards() {
    const api = new BrokerAPI()
    const adminApi = new AdminAPI()

    //const [details, setDetails] = useState(null)
    const [companies, setCompanies] = useState([])
    const [companyId, setCompanyId] = useState(null)
    const [companyProducts, setCompanyProducts] = useState([])
    const [product, setProduct] = useState(null)
    const [allLeads, setAllLeads] = useState([])
    //const [leads, setLeads] = useState([])
    const [showLeads, setShowLeads] = useState([])
    const [stage, setStage] = useState('new')
    const [pipelineCount, setPipelineCount] = useState({})
    const [broker, setBroker] = useState('allBrokers')
    const [brokers, setBrokers] = useState([])
    const [loadMore, setLoadMore] = useState(false)
    const [loading, setLoading] = useState(true)
    const [leadsLoading, setLeadsLoading] = useState(false)
    const [moreLeadsLoading, setMoreLeadsLoading] = useState(false)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        getCompanies()
    }, [])

    async function getCompanies() {
        try {
            const companies = await adminApi.getCompanies()
            console.log('companies: ', companies)
            companies.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })

            const activeCompanies = companies.filter((company) => (company.active))

            setCompanies(activeCompanies)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function updateCompanyId(companyId) {
        setLeadsLoading(true)
        try {
            let company = companies.find((company) => company.companyId === companyId)
            console.log(company)

            const brokers = await api.getBrokers(companyId)
            console.log('brokers: ', brokers)

            setCompanyId(companyId)
            setCompanyProducts(company.productsAvailable)
            setBrokers(brokers)
            setProduct(null)
            setAllLeads([])
            setShowLeads([])
            setLeadsLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function updateProduct(product) {
        setLeadsLoading(true)
        try {
            let brokerId = null

            let response = await api.getLeadsLimit(product, companyId, brokerId, false)
            console.log('response: ', response)
            let leads = response.items

            // get leads from legacy 'general' table and combine with all other leads
            if (product === 'general' && companyId === 'ci') {
                let generalTableLeads = await api.getGeneral(companyId, brokerId)
                console.log('gen leads', generalTableLeads.items)
                leads.push(...generalTableLeads.items)
            }

            leads.sort((a, b) => {
                return b.createdAt - a.createdAt
            })

            updatePipelineCount(leads)
            setAllLeads(leads)
            setProduct(product)
            //setLeads(leads)
            setShowLeads(leads.filter((lead) => lead.stage === 'new'))
            setLoadMore(response.loadMore)
            setLeadsLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function loadMoreLeads() {
        setMoreLeadsLoading(true)
        try {
            let brokerId = null
            const response = await api.getLeadsLimit(product, companyId, brokerId, loadMore)
            console.log('response: ', response)

            response.items.sort((a, b) => {
                return b.createdAt - a.createdAt
            })

            let combinedLeads = [...allLeads, ...response.items]
            console.log('combined: ', combinedLeads.length)

            let brokerLeads = []

            if (broker !== 'allBrokers') {
                brokerLeads = combinedLeads.filter(lead => lead.brokerId === broker)
            }
            else {
                brokerLeads = combinedLeads
            }

            let filteredLeads = brokerLeads.filter((lead) => lead.stage === stage)

            updatePipelineCount(brokerLeads)
            setAllLeads(combinedLeads)
            //setLeads(combinedLeads)
            setShowLeads(filteredLeads)
            setLoadMore(response.loadMore || false)
            //setLoading(false)
        } catch(e) {
            onError(e)
        }
        setMoreLeadsLoading(false)
    }

    async function refreshLeads() {
        try {
            let brokerId = null 
            const response = await api.getLeads(product, companyId, brokerId)
            console.log('response: ', response)
            const leads = response.items

            // get leads from legacy 'general' table and combine with all other leads
            if (product === 'general' && companyId === 'ci') {
                let generalTableLeads = await api.getGeneral(companyId, brokerId)
                console.log('gen leads', generalTableLeads.items)
                leads.push(...generalTableLeads.items)
            }

            if (leads && leads.length > 0) {
                leads.sort((a, b) => {
                    return b.createdAt - a.createdAt
                })
            }

            let brokerLeads = []

            if (broker !== 'allBrokers') {
                brokerLeads = leads.filter(lead => lead.brokerId === broker)
            }
            else {
                brokerLeads = leads
            }

            let filteredLeads = brokerLeads.filter((lead) => lead.stage === stage)

            updatePipelineCount(brokerLeads)
            setAllLeads(leads)
            //setLeads(combinedLeads)
            setShowLeads(filteredLeads)
            setLoadMore(response.loadMore || false)
        } catch(e) {
            onError(e)
        }
    }

    async function updateBroker(broker) {
        setLoading(true)
        console.log(broker)

        try {
            let brokerLeads = []

            if (broker !== 'allBrokers') {
                brokerLeads = allLeads.filter(lead => lead.brokerId === broker)
            }
            else {
                brokerLeads = allLeads
            }

            let filteredLeads = brokerLeads.filter((lead) => lead.stage === stage)
            
            updatePipelineCount(brokerLeads)
            setBroker(broker)
            setShowLeads(filteredLeads)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
	}

    async function updateStageMultiple(stage, leads) {
        console.log('update stage multiple')
        console.log(stage)
        console.log(leads)
        try {
            //let updated = product === 'general' ? await api.updateGeneralStageMultiple(companyId, leads, stage) : await api.updateLeadStageMultiple(companyId, product, leads, stage)
            let updated = await api.updateLeadStageMultiple(companyId, product, leads, stage)
            console.log('leads: ', updated)

            NotificationManager.success('Leads updated')
            refreshLeads()
        } catch(e) {
            onError(e)
        }
    }

    async function updateBrokerMultiple(brokerId, leads) {
        console.log('update broker multiple')
        console.log(brokerId)
        console.log(leads)
        let broker = brokers.find(broker => broker.brokerId === brokerId)
        let brokerName = broker.firstName + ' ' + broker.lastName
        console.log(brokerName)
        try {
            let updated = await api.updateLeadBrokerMultiple(companyId, product, leads, brokerId, brokerName)
            console.log('leads: ', updated)

            NotificationManager.success('Leads updated')
            refreshLeads()
        } catch(e) {
            onError(e)
        }
    }

    async function deleteLeadsMultiple(leads) {
        console.log('delete leads multiple')
        console.log(leads)
        try {
            //let updated = product === 'general' ? await adminApi.deleteGeneralLeadsMultiple(companyId, leads) : await adminApi.deleteLeadsMultiple(companyId, product, leads, stage)
            let updated = await adminApi.deleteLeadsMultiple(companyId, product, leads, stage)
            console.log('leads: ', updated)

            NotificationManager.success('Leads deleted')
            refreshLeads()
        } catch(e) {
            onError(e)
        }
    }

    function updatePipelineCount(leads) {
        let count = getPipelineCount(leads)
        setPipelineCount(count)
    }

    function updateStage(stage, newLeads = null) {
        console.log('stage: ', stage)
        console.log('update stage leads: ', newLeads)

        let filteredLeads = newLeads ? newLeads : allLeads

        if (broker !== 'allBrokers') {
            filteredLeads = filteredLeads.filter(lead => lead.brokerId === broker)
        }

        updatePipelineCount(filteredLeads)
        setShowLeads(filteredLeads.filter((lead) => lead.stage === stage))
        setStage(stage)
    }

    function updateLead(lead) {
        //const index = product === 'general' ? allLeads.findIndex(oldLead => ((oldLead.date === lead.date) && (oldLead.createdAt === lead.createdAt))) : allLeads.findIndex(oldLead => ((oldLead.product === lead.product) && (oldLead.phone === lead.phone)))
        const index = allLeads.findIndex(oldLead => ((oldLead.product === lead.product) && (oldLead.phone === lead.phone)))
        console.log('lead index: ', index)

        let newLeads = [...allLeads]
        newLeads[index] = lead
        console.log(newLeads)
        
        setAllLeads(newLeads)
        updatePipelineCount(newLeads)
        updateStage(stage, newLeads)
    }

    function removeLead(lead, product) {
        //const index = product === 'general' ? allLeads.findIndex(oldLead => ((oldLead.date === lead.date) && (oldLead.createdAt === lead.createdAt))) : allLeads.findIndex(oldLead => ((oldLead.product === lead.product) && (oldLead.phone === lead.phone)))
        const index = allLeads.findIndex(oldLead => ((oldLead.product === lead.product) && (oldLead.phone === lead.phone)))
        console.log('lead index: ', index)

        let newLeads = [...allLeads]
        newLeads.splice(index, 1)
        console.log('new leads: ', newLeads)
        
        setAllLeads(newLeads)
        updatePipelineCount(newLeads)
        updateStage(stage, newLeads)
        showSuccessAlert('Lead has been moved to the ' + getProductString(product) + ' deal board')
    }

    function showSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                marginTop='2rem'
                //classes={{ root: classes.containerRoot }}
            >
                {alert}
                {!loading ? (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Grid
                                container
                                component={Box}
                                alignItems='center'
                                justifyContent='flex-start'
                                style={{marginBottom: '2rem'}}
                                direction='row'
                            >
                                <Grid item xs={12} md={2}>
                                    <FormGroup style={{marginBottom: '1rem'}}>
                                        <FormControl variant='outlined'>
                                            <Select
                                                multiple={false}
                                                defaultValue={null}
                                                IconComponent={KeyboardArrowDown}
                                                value={companyId}
                                                onChange={(e) => updateCompanyId(e.target.value)}
                                            >
                                                {companies.map((company, i) => {
                                                    return (<MenuItem value={company.companyId} key={i}>{company.name}</MenuItem>)
                                                })}
                                            </Select>
                                        </FormControl>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    {companyId &&
                                        <FormGroup style={{marginBottom: '1rem'}}>
                                            <FormControl variant='outlined'>
                                                <Select
                                                    multiple={false}
                                                    defaultValue={null}
                                                    IconComponent={KeyboardArrowDown}
                                                    value={product}
                                                    onChange={(e) => updateProduct(e.target.value)}
                                                >
                                                    {Object.keys(companyProducts).map((product, i) => {
                                                        if (companyProducts[product] === true) { 
                                                            return (<MenuItem value={product} key={i}>{getProductString(product)}</MenuItem>)
                                                        }
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </FormGroup>
                                    }
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    {(companyId && product) &&
                                        <FormGroup style={{marginBottom: '1rem'}}>
                                            <FormControl variant='outlined'>
                                                <Select
                                                    multiple={false}
                                                    defaultValue={null}
                                                    IconComponent={KeyboardArrowDown}
                                                    value={broker}
                                                    onChange={(e) => updateBroker(e.target.value)}
                                                >
                                                    <MenuItem value={'allBrokers'} key={'all'}>All Brokers</MenuItem>
                                                    {brokers.map((broker, i) => {
                                                        return (<MenuItem value={broker.brokerId} key={i}>{broker.firstName + ' ' + broker.lastName}</MenuItem>)
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </FormGroup>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            {!companyId &&
                                <h3>Select a company...</h3>
                            }
                            {(companyId && !product) &&
                                <h3>Select a product...</h3>
                            }
                        </Grid>
                        {leadsLoading &&
                            <Grid item xs={12}>
                                <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                    <Loader />
                                </Box>
                            </Grid>
                        }
                        {(allLeads.length > 0 && !leadsLoading) &&
                            <Grid item xs={12}>
                                <LeadsTable 
                                    allLeads={allLeads}
                                    leads={showLeads}
                                    pipelineCount={pipelineCount}
                                    stage={stage}
                                    updateStage={(stage) => updateStage(stage)}
                                    updateLead={(lead) => updateLead(lead)}
                                    removeLead={(lead, product) => removeLead(lead, product)}
                                    updateStageMultiple={(stage, selected) => updateStageMultiple(stage, selected)}
                                    updateBrokerMultiple={(broker, selected) => updateBrokerMultiple(broker, selected)}
                                    deleteLeadsMultiple={(selected) => deleteLeadsMultiple(selected)}
                                    updatePipelineCount={(leads) => updatePipelineCount(leads)}
                                    refreshLeads={() => refreshLeads()}
                                    showSuccessAlert={(text) => showSuccessAlert(text)}
                                    brokers={brokers}
                                    details={{
                                        permissions: 'admin'
                                    }}
                                    product={product}
                                    companyId={companyId}
                                    admin
                                />
                            </Grid>
                        }
                        {(loadMore && !leadsLoading) &&
                            <Grid item xs={12} style={{textAlign: 'center', paddingBottom: '3rem'}}>
                                <Button
                                    component={Box}
                                    onClick={() => loadMoreLeads()}
                                    color='secondary'
                                    variant='contained'
                                    disabled={moreLeadsLoading}
                                >
                                    {!moreLeadsLoading ? (
                                        <>Load More Leads</>
                                    ) : (
                                        <Loader height={12} />
                                    )}
                                </Button>
                            </Grid>
                        }
                    </Grid>
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    )
}

export default LeadBoards