import React, { useState } from 'react'
import Chart from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import { chartOptions, parseOptions, chartExample6 } from 'config/constants/ChartVariables'
import { ciPrices } from 'config/constants/Constants'

import componentStyles from 'assets/theme/components/cards/charts/card-affiliate-traffic.js'
import { getProductString } from 'config/helpers/Helpers'

const useStyles = makeStyles(componentStyles)

const productColors = {
    'Income Protection': '#1a0a00',
    'Mortgage Protection': '#803300',
    'Life Insurance': '#803300',
    'Health Insurance': '#b34700',
    'Home Insurance': '#e65c00',
    'Business Insurance': '#ff751a',
    'Gadget Insurance': '#ff944d',
    'Pet Insurance': '#ffb280',
    'Travel Insurance': '#ffd1b3',
    'General': '#fff0e5'
}

function ProductPie(props) {
    const classes = useStyles()
    const theme = useTheme()
    if (window.Chart) {
        parseOptions(Chart, chartOptions())
    }

    let products = {}

    for (let product of Object.keys(ciPrices)) {
        products[getProductString(product)] = 0
    }
    console.log('products: ', products)

    for (let lead of props.leads) {
        products[getProductString(lead.product)] += 1
    }
    //console.log('types: ', types)

    let labels = []
    let values = []
    let colors = []

    for (let product of Object.keys(products)) {
        //console.log(type)
        if (products[product] > 0) {
            labels.push(product)
            values.push(products[product])
            colors.push(productColors[product])
        }
    }
    let updatedLabels = labels.map((label, i) => {
        return label + ' (' + ((values[i] / props.leads.length) * 100).toFixed() + '%)'
    })
    console.log('updated labels: ', updatedLabels)
    // console.log('labels: ', labels)
    // console.log('values: ', values)
    // console.log('colors', colors)

    const data = {
        labels: updatedLabels, // array of labels
        datasets: [
            {
                //label: 'Annual Premiums (€)',
                data: values, // array of values,
                //maxBarThickness: 10,
                backgroundColor: colors,
            },
        ]
    }
  
    return (
        <>
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    title={
                        <Box component='span' color={theme.palette.gray[600]}>
                            
                        </Box>
                    }
                    subheader='Lead Volume by Product'
                    classes={{ root: classes.cardHeaderRoot }}
                    titleTypographyProps={{
                        component: Box,
                        variant: 'h6',
                        letterSpacing: '2px',
                        marginBottom: '0!important',
                        classes: {
                            root: classes.textUppercase,
                        },
                    }}
                    subheaderTypographyProps={{
                        component: Box,
                        variant: 'h3',
                        marginBottom: '0!important',
                        color: 'initial',
                    }}
                ></CardHeader>
                
                <CardContent>
                    <Box position='relative' height='350px'>
                        <Pie
                            data={data}
                            options={{
                                legend: {
                                    display: true,
                                    position: "bottom",
                                },
                            }}
                            //options={chartExample6.options}
                            //getDatasetAtEvent={(e) => console.log(e)}
                        />
                    </Box>
                </CardContent>

                <Box
                    fontSize='12px'
                    color={theme.palette.gray[600]}
                    marginBottom='1rem'
                    marginLeft='1rem'
                    //marginTop='2rem'
                    textAlign='left'
                    //className='online-discount'
                >
                    <Box component='small' fontSize='100%'>
                        * Volume charts reflect gross lead volume, including rejections and returns
                    </Box>
                </Box>
            </Card>
        </>
    )
}

export default ProductPie