import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import BootstrapTable from 'react-bootstrap-table-next'
import Grid from '@material-ui/core/Grid'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import filterFactory, { textFilter, selectFilter, numberFilter } from 'react-bootstrap-table2-filter'
import AddPaymentModal from 'components/admin/AddPaymentModal'
import PaymentModal from 'components/admin/PaymentModal'
import componentStyles from 'assets/theme/views/admin/react-bs-table.js'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

const useStyles = makeStyles(componentStyles)

function PaymentsTable(props) {
    const classes = { ...useStyles() }
    const api = new AdminAPI()

    async function chargeCompany(payment) {
        console.log('charge company for: ', payment)
        try {   
            let updatedPayment = await api.chargeCompany(payment)
            console.log('payment: ', updatedPayment)

            props.updatePayment(updatedPayment)
            props.showSuccessAlert('Payment succeeded!')
        } catch(e) {
            onError(e)
        }
    }

    async function sendPaymentLink(payment) {
        console.log('send link for: ', payment)
        try {   
            let companyDetails = await api.getCompanyDetails(payment.companyId)
            console.log('company details: ', companyDetails)

            if (companyDetails.billingEmail) {
                let updatedPayment = await api.sendPaymentLink(payment)
                console.log('payment: ', updatedPayment)

                props.updatePayment(updatedPayment)
                props.showSuccessAlert('Payment link sent!')
            }
            else {
                alert('Please add billing email in companies screen')
            }
        } catch(e) {
            onError(e)
        }
    }

    async function sendPaymentReminder(payment) {
        console.log('send reminder for: ', payment)
        try {   
            let companyDetails = await api.getCompanyDetails(payment.companyId)
            console.log('company details: ', companyDetails)

            if (companyDetails.billingEmail) {
                let response = await api.sendPaymentReminder(payment)
                console.log('response: ', response)

                //props.updatePayment(updatedPayment)
                props.showSuccessAlert('Payment reminder sent!')
            }
            else {
                alert('Please add billing email in companies screen')
            }
        } catch(e) {
            onError(e)
        }
    }

    let payments = []

    for (let i = 0; i < props.payments.length; i++) {
        if (props.payments[i+1]) {
            if (props.payments[i].date !== props.payments[i+1].date) {
                payments.push(props.payments[i])
                console.log('insert blank')
                payments.push({})
            }   
            else {
                payments.push(props.payments[i])
            }
        }
        else {
            payments.push(props.payments[i])
        }
    }
    console.log('sorted: ', payments)
    let netSum = 0
    let grossSum = 0
    for (let payment of props.payments) {
        netSum += parseFloat(payment.subtotal)
        grossSum += parseFloat(payment.total)
    }
    console.log('net sum: ', netSum)
    console.log('gross sum: ', grossSum)

    const [payment, setPayment] = useState(null)
    const [showPaymentModal, setShowPaymentModal] = useState(false)
    const [showAddPaymentModal, setShowAddPaymentModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            dataField: 'date',
            text: 'Date',
            //sort: true,
            //filter: textFilter(),
            formatter: (cell, row) => {
                return <>{row.hasOwnProperty('date') ? moment(row.date).format('DD/MM') : '- Week -'}</>
            },
            footer: 'Total'
        },
        // income protection
        {
            dataField: 'company',
            text: 'Company',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return (<Box className='hover-text primary-text' onClick={() => openPaymentModal(row)}>{row.hasOwnProperty('companyName') ? row.companyName : ''}</Box>)
            },
            footer: ''
        },
        {
            dataField: 'description',
            text: 'Description',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{row.hasOwnProperty('allocation') ? getDescription(row.allocation) : (row.hasOwnProperty('billingMethod') ? row.billingPeriod : '')}</>
            },
            footer: ''
        },
        // {
        //     dataField: 'billingAccount',
        //     text: 'Billing Ac',
        //     sort: true,
        //     //filter: numberFilter(),
        //     formatter: (cell, row) => {
        //         return <>{row.hasOwnProperty('billingAccount') ? row.billingAccount : ''}</>
        //     },
        //     footer: ''
        // },
        {
            dataField: 'invoiced',
            text: 'Invoice Status',
            //sort: true,
            //filter: textFilter(),
            formatter: (cell, row) => {
                return <>{row.hasOwnProperty('invoiced') ? row.invoiced === true ? 'Sent' : 'Not Sent' : ''}</>
            },
            footer: ''
        },
        {
            dataField: 'paid',
            text: 'Payment Status',
            //sort: true,
            //filter: textFilter(),
            formatter: (cell, row) => {
                return <>{row.hasOwnProperty('paid') ? row.paid === true ? 'Paid' : 'Unpaid' : ''}</>
            },
            footer: ''
        },
        // {
        //     dataField: 'subtotal',
        //     text: 'Net (€)',
        //     //sort: true,
        //     //filter: textFilter(),
        //     formatter: (cell, row) => {
        //         return <>{row.hasOwnProperty('subtotal') ? '€' + row.subtotal.toFixed(2) : ''}</>
        //     },
        //     footer: '€' + netSum.toFixed(2)
        // },
        {
            dataField: 'total',
            text: 'Total (€)',
            //sort: true,
            //filter: textFilter(),
            formatter: (cell, row) => {
                return <>{row.hasOwnProperty('total') ? '€' + row.total.toFixed(2) : ''}</>
            },
            footer: '€' + grossSum.toFixed(2)
        },
        {
            dataField: 'companyId',
            text: 'Action',
            sort: false,
            formatter: (cell, row) => {
                return (
                    <>
                        {(row.paid === false && row.billingAccount === 'SS' && row.billingMethod === 'INV') ? (
                            <>
                                {/*
                                <Button 
                                    color='primary'
                                    variant='contained'
                                    size='small'
                                    onClick={() => chargeCompany(row)}
                                    style={{margin: '0.5rem'}}
                                >
                                    Charge
                                </Button>
                                */}
                                {row.invoiced ? (
                                    <Button 
                                        color='secondary'
                                        variant='contained'
                                        size='small'
                                        onClick={() => sendPaymentReminder(row)}
                                        style={{margin: '0.5rem'}}
                                    >
                                        Send Reminder
                                    </Button>
                                ) : (
                                    <Button 
                                        color='secondary'
                                        variant='contained'
                                        size='small'
                                        onClick={() => sendPaymentLink(row)}
                                        style={{margin: '0.5rem'}}
                                    >
                                        Send Link
                                    </Button>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                )
            },
            footer: ''
        },
    ]

    function getDescription(allocations) {
        let description = ''

        if (allocations) {
            for (let [product, values] of Object.entries(allocations)) {
                let shortened = '';
                if (product === 'life-insurance') {
                    shortened = 'LC';
                }
                if (product === 'income-protection') {
                    shortened = 'IP';
                }
                if (product === 'pensions') {
                    shortened = 'PEN';
                }
                if (product === 'mortgage-protection') {
                    shortened = 'MP';
                }
                if (product === 'health-insurance') {
                    shortened = 'HI';
                }
                if (parseInt(values.allocation) > 0) {
                    description += shortened + 'x' + values.allocation + ' ';
                }
            }
        }
        else {
            description = 'Topup'
        }

        return description
    }

    function openPaymentModal(payment) {
        setPayment(payment)
        setShowPaymentModal(true)
    }

    function closePaymentModal() {
        setShowPaymentModal(false)
        setPayment(null)
    }

    return (
        <Card>
            {!loading ? (
                <>
                    <CardHeader
                        subheader={
                            <>
                                <Box display='flex' style={{justifyContent: 'space-between'}}>
                                    <Box>
                                        <Box
                                            component={Typography}
                                            variant='h2'
                                            marginBottom='0!important'
                                        >
                                            <Box component='span'>Invoices</Box>
                                        </Box>
                                        <Box
                                            component='p'
                                            fontSize='.875rem'
                                            marginBottom='0'
                                            marginTop='0'
                                            lineHeight='1.7'
                                            fontWeight='300'
                                        >
                                            Invoices are generated on Friday evening for the current week
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Button
                                            component={Box}
                                            onClick={() => setShowAddPaymentModal(true)}
                                            color='primary'
                                            variant='contained'
                                            size='small'
                                        >
                                            Add Invoice
                                        </Button>
                                    </Box>
                                </Box>
                            </>
                        }
                        classes={{ root: classes.cardHeaderRoot }}
                    ></CardHeader>

                    <div className='table-container'>
                        <BootstrapTable 
                            keyField='brokerName' 
                            data={ payments } 
                            columns={ columns } 
                            filter={ filterFactory() } 
                            noDataIndication={<Box>No payments</Box>}
                        />
                    </div>

                    {showAddPaymentModal &&
                        <AddPaymentModal 
                            isOpen={showAddPaymentModal}
                            close={() => setShowAddPaymentModal(false)}
                            showSuccessAlert={(text) => props.showSuccessAlert(text)}
                            refreshPayments={() => props.refreshPayments()}
                            companies={props.companies}
                        />
                    }

                    {showPaymentModal &&
                        <PaymentModal 
                            isOpen={showPaymentModal}
                            close={() => closePaymentModal()}
                            payment={payment}
                            updatePayment={(payment) => props.updatePayment(payment)}
                            refreshPayments={() => props.refreshPayments()}
                            showSuccessAlert={(text) => props.showSuccessAlert(text)}
                            companies={props.companies}
                        />
                    }
                </>
            ) : (
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                            <Loader />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Card>
    );
}

export default PaymentsTable