import React from 'react'
import Chart from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'

import { chartOptions, parseOptions, chartExample6 } from 'config/constants/ChartVariables'

import componentStyles from 'assets/theme/components/cards/charts/card-affiliate-traffic.js'
import { getProductString } from 'config/helpers/Helpers'

const useStyles = makeStyles(componentStyles)

function ProductIncomePie(props) {
    const classes = useStyles()
    const theme = useTheme()
    if (window.Chart) {
        parseOptions(Chart, chartOptions())
    }

    let products = {}
    for (var payment of props.payments) {
        //console.log(payment.date + ': ' +  payment.subtotal)
        if (payment.allocation) {
            for (let product of Object.keys(payment.allocation)) {
                if (payment.allocation[product].allocation > 0) {
                    if (products.hasOwnProperty(getProductString(product))) {
                        products[getProductString(product)] += parseFloat((payment.allocation[product].allocation * payment.allocation[product].leadPrice).toFixed(2))
                    }
                    else {
                        products[getProductString(product)] = parseFloat((payment.allocation[product].allocation * payment.allocation[product].leadPrice).toFixed(2))
                    }
                }
            }
        }
    }
    console.log('formatted products: ', products)
    let sortable = []
    let total = 0
    for (var product in products) {
        sortable.push([product, products[product]])
        total += products[product]
    }
    sortable.sort(function(a, b) {
        return a[1] - b[1];
    })
    console.log('sorted: ', sortable)

    let updatedLabels = sortable.map((label) => {
        return label[0] + ' (' + ((label[1] / total) * 100).toFixed() + '%)'
    })
    console.log('updated labels: ', updatedLabels)

    const data = {
        labels: updatedLabels,
        datasets: [
            {
                //label: 'Annual Premiums (€)',
                data: sortable.map((item) => item[1]),
                //maxBarThickness: 10,
                backgroundColor: ['#e8f1fd', '#b9d5f8', '#8bb8f3', '#5d9cef', '#5d9cef', '#1566d1', '#104fa2', '#0c3974', '#072246', '#020b17'],
            },
        ]
    }
  
    return (
        <>
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    title={
                        <Box component='span' color={theme.palette.gray[600]}>
                            
                        </Box>
                    }
                    subheader='Income by Product'
                    classes={{ root: classes.cardHeaderRoot }}
                    titleTypographyProps={{
                        component: Box,
                        variant: 'h6',
                        letterSpacing: '2px',
                        marginBottom: '0!important',
                        classes: {
                            root: classes.textUppercase,
                        },
                    }}
                    subheaderTypographyProps={{
                        component: Box,
                        variant: 'h3',
                        marginBottom: '0!important',
                        color: 'initial',
                    }}
                ></CardHeader>
                
                <CardContent>
                    <Box position='relative' height='350px'>
                        <Pie
                            data={data}
                            options={{
                                legend: {
                                    display: true,
                                    position: "bottom",
                                    reverse: true
                                },
                            }}
                        />
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}

export default ProductIncomePie