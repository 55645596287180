import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'
import { NotificationManager } from 'react-notifications'
import FormLabel from '@material-ui/core/FormLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import Loader from 'components/general/Loader'
import config from 'config/api/Config'

import DetailsContext from 'config/context/DetailsContext'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

const paymentOptions = {
    defaultValues: {
        billingDetails: {
            address: {
                country: 'IE'
            }
        }
    }
}

export default function PaymentModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	const api = new BrokerAPI()
    const stripe = useStripe()
    const elements = useElements()
	const companyDetails = useContext(DetailsContext)
	const companyId = companyDetails.companyId

    console.log(props.clientSecret)

    const [saveCard, setSaveCard] = useState(true)
    const [errorMessage, setErrorMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async (event) => {
        event.preventDefault()
    
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return null
        }

        setIsLoading(true)
    
        const {error} = await stripe.confirmPayment({
            //Elements instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: config.stripeReturnUrl + '?payment_date=' + props.payment.date,
            },
        })
    
        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message)
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }

        setIsLoading(false)
    }
	
    return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Complete Payment
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>

            <form onSubmit={handleSubmit}>
                <DialogContent>
                    {props.clientSecret &&
                        <Box>
                            <PaymentElement 
                                options={paymentOptions}
                            />
                            {errorMessage && 
                                <p className='red-text' style={{marginTop: '1rem'}}>{errorMessage}</p>
                            }
                            {/*
                            <Grid container direction='row' alignItems='center' justifyContent='flex-start'>
                                <Box>
                                    <Checkbox
                                        color='primary' 
                                        checked={saveCard} 
                                        onChange={() => setSaveCard(!saveCard)}
                                    />
                                </Box>
                                <Box>
                                    <FormLabel style={{marginBottom: '0'}}>Save card for future payments</FormLabel>
                                </Box>
                            </Grid>
                            */}
                        </Box>
                    }
                </DialogContent>
                
                <DialogActions style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button
                        onClick={() => props.close()}
                        color='primary'
                    >
                        Cancel
                    </Button>
                    
                    <Button
                        type='submit'
                        color='primary'
                        //marginLeft='auto!important'
                        variant='contained'
                        disabled={isLoading || !stripe || !elements}
                    >
                        <span id='button-text'>
                            {isLoading ? <Loader height={10} /> : 'Pay now'}
                        </span>
                    </Button>
                </DialogActions>
            </form>
		</Dialog>
	)
}