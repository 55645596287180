import React, { useState, useEffect } from 'react'
import { Storage } from 'aws-amplify'

function Voicemail(props) {
    const vmKey = props.vmKey
    console.log('vmKey: ', vmKey)

    const [loading, setLoading] = useState(true)
    const [url, setUrl] = useState(null)

    useEffect(() => {
        getAudioFile()
    }, [])
    
    async function getAudioFile() {
        let config = props.product === 'pensions' ? {
            bucket: 'nrh-vms',
            customPrefix: {
                public: ''
            }
        } : {
            //download: true,
            customPrefix: {
                public: ''
            }
        }
        let vm = await Storage.get(vmKey, config)
        console.log('file: ', vm)
        console.log(typeof vm)
        setUrl(vm)
        setLoading(false)
    }
    
    return (
        <>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <audio controls>
                    <source src={url} type="audio/wav" />
                </audio> 
            )}
        </>
    )
}

export default Voicemail