import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import BootstrapTable from 'react-bootstrap-table-next'
import Grid from '@material-ui/core/Grid'
import Loader from 'components/general/Loader'
import EnergyAPI from 'config/api/EnergyAPI'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import LeadModal from './LeadModal'
import componentStyles from 'assets/theme/views/admin/react-bs-table.js'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

const useStyles = makeStyles(componentStyles)

function LeadsTable(props) {
    const classes = { ...useStyles() }
    const api = new EnergyAPI()

    const [lead, setLead] = useState(null)
    const [showLeadModal, setShowLeadModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            dataField: 'firstName',
            text: 'Name',
            //sort: true,
            filter: textFilter(),
            formatter: (cell, row) => {
                return (<Box className='hover-text primary-text' onClick={() => openLeadModal(row)}>{row.firstName + ' ' + (row.lastName ? row.lastName : '')}</Box>)
            },
            headerFormatter: (col, colIndex, components) => {
                return components.filterElement
            }
        },
        {
            dataField: 'product',
            text: 'Product',
            sort: true,
            formatter: (cell, row) => {
                return (<Box>{row.product}</Box>)
            },
        },
        {
            dataField: 'phone',
            text: 'Phone',
            sort: true,
            formatter: (cell, row) => {
                return (<Box>{row.phone}</Box>)
            },
        },
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
            formatter: (cell, row) => {
                return (<Box>{row.email}</Box>)
            },
        },
        {
            dataField: 'isValid',
            text: 'Passed Validation',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{row.isValid ? 'Yes' : 'No (' + row.invalidReason + ')'}</>
            }
        },
        {
            dataField: 'source',
            text: 'Source',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{row.source}</>
            }
        },
        {
            dataField: 'createdAt',
            text: 'Created',
            sort: true,
            formatter: (cell, row) => {
                //return <>{moment(row.createdAt).format('DD/MM/YYYY HH:mm')}</>
                //return <>{moment().diff(row.createdAt, 'days')} days ago</>
                return <>{moment(row.createdAt).fromNow()}</>
            }
        }
    ]

    if (props.title === 'Allocated') {
        let index = 2
        columns[index] = {
            dataField: 'company',
            text: 'Company',
            sort: true,
            formatter: (cell, row) => {
                return <>{row.companyId.toUpperCase() || '-'}</>
            }
        }
    }

    function openLeadModal(lead) {
        setLead(lead)
        setShowLeadModal(true)
    }

    function closeLeadModal() {
        setShowLeadModal(false)
        setLead(null)
    }

    return (
        <Card>
            {!loading ? (
                <>
                    <CardHeader
                        subheader={
                            <>
                                <Box display='flex' style={{justifyContent: 'space-between'}}>
                                    <Box>
                                        <Box
                                            component={Typography}
                                            variant='h2'
                                            marginBottom='0!important'
                                        >
                                            <Box component='span'>{props.title} Leads</Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </>
                        }
                        classes={{ root: classes.cardHeaderRoot }}
                    ></CardHeader>
                    
                    <div className='table-container'>
                        <BootstrapTable 
                            keyField='name' 
                            data={ props.leads } 
                            columns={ columns } 
                            filter={ filterFactory() } 
                            noDataIndication={<Box>No pending leads</Box>}
                        />
                    </div>

                    {/*showRequestModal &&
                        <ReturnRequestModal 
                            isOpen={showRequestModal}
                            close={() => closeRequestModal()}
                            request={request}
                            updateRequest={(request) => props.updateRequest(request)}
                            deleteRequest={(request) => props.deleteRequest(request)}
                            showSuccessAlert={(text) => props.showSuccessAlert(text)}
                        />
                    */}
                    {showLeadModal &&
                        <LeadModal 
                            isOpen={showLeadModal}
                            close={() => closeLeadModal()}
                            lead={lead}
                            refreshLeads={() => props.refreshLeads()}
                            showSuccessAlert={(text) => props.showSuccessAlert(text)}
                            companies={props.companies}
                            //details={null}
                            //brokers={props.brokers}
                            //claimLead={(lead) => props.claimLead(lead)}
                            updateLead={(lead) => props.updateLead(lead)}
                            //removeLead={(lead, product) => props.removeLead(lead, product)}
                            //product={product}
                            admin={props.admin}
                            site={props.site}
                        />
                    }
                </>
            ) : (
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                            <Loader />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Card>
    );
}

export default LeadsTable