import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import BootstrapTable from 'react-bootstrap-table-next'
import Grid from '@material-ui/core/Grid'
import Loader from 'components/general/Loader'
import filterFactory, { textFilter, selectFilter, numberFilter } from 'react-bootstrap-table2-filter'
import AddDeliveryModal from './AddDeliveryModal'
import DeliveryModal from './DeliveryModal'

import componentStyles from 'assets/theme/views/admin/react-bs-table.js'

const useStyles = makeStyles(componentStyles)

function DeliveriesTable(props) {
    const classes = { ...useStyles() }

    const deliveries = props.deliveries
    const [delivery, setDelivery] = useState(null)
    const [showDeliveryModal, setShowDeliveryModal] = useState(false)
    const [showAddDeliveryModal, setShowAddDeliveryModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            dataField: 'company',
            text: 'Company',
            //sort: true,
            //filter: textFilter(),
            formatter: (cell, row) => {
                return (<Box className={row.active === false ? 'inactive-delivery hover-text primary-text' : 'active-delivery hover-text primary-text'} onClick={() => openDeliveryModal(row)}>{row.companyId.toUpperCase()}</Box>)
            }
        },
        {
            dataField: 'weight',
            text: 'Weight',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>{row.weight.toFixed(2)}</Box>
            }
        },
        {
            dataField: 'leadPrice',
            text: 'Lead Price',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>€{row.leadPrice.toFixed(2)}</Box>
            }
        },
        {
            dataField: 'leadCount',
            text: 'Leads',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>{row.leadCount || 0}</Box>
            }
        },
        {
            dataField: 'revenue',
            text: 'Revenue',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>€{row.revenue ? row.revenue.toFixed(2) : '0.00'}</Box>
            }
        }
    ]

    if (props.site === 'ee') {
        columns.splice(1, 0, {
            dataField: 'product',
            text: 'Product',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>{row.product}</Box>
            }
        })
    }

    if (props.site === 'sf') {
        columns.splice(3, 0, {
            dataField: 'weeklyCap',
            text: 'Weekly Cap',
            sort: true,
            formatter: (cell, row) => {
                return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>{row.weeklyCap || '-'}</Box>
            }
        })
        columns.splice(5, 0, {
            dataField: 'eeLeadCount',
            text: 'EE Leads',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>{row.eeLeadCount || 0}</Box>
            }
        })
    }

    function openDeliveryModal(delivery) {
        setDelivery(delivery)
        setShowDeliveryModal(true)
    }

    function closeDeliveryModal() {
        setShowDeliveryModal(false)
        setDelivery(null)
    }

    function checkWeights() {
        let productWeights = {}

        for (let item of props.deliveries) {
            console.log(parseFloat(item.weight.toFixed(2)) * 100)
            if (productWeights.hasOwnProperty(item.product)) {
                productWeights[item.product] += parseFloat(item.weight.toFixed(2)) * 100
            }
            else {
                productWeights[item.product] = parseFloat(item.weight.toFixed(2)) * 100
            }
        }
        console.log('productWeights: ', productWeights)

        let valid = true
        //let problem = ''

        for (let product of Object.keys(productWeights)) {
            if (productWeights[product] !== 100) {
                valid = false
            } 
        }

        return valid
    }

    return (
        <Card>
            {!loading ? (
                <>
                    <CardHeader
                        subheader={
                            <>
                                <Box display='flex' style={{justifyContent: 'space-between'}}>
                                    <Box>
                                        <Box
                                            component={Typography}
                                            variant='h2'
                                            marginBottom='0!important'
                                        >
                                            <Box component='span'>{props.title}</Box>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Button
                                            component={Box}
                                            onClick={() => setShowAddDeliveryModal(true)}
                                            color='primary'
                                            variant='contained'
                                            size='small'
                                        >
                                            Add Delivery
                                        </Button>
                                    </Box>
                                </Box>
                            </>
                        }
                        classes={{ root: classes.cardHeaderRoot }}
                    ></CardHeader>
                    
                    <div className='table-container'>
                        <BootstrapTable 
                            keyField='company' 
                            data={ props.deliveries } 
                            columns={ columns } 
                            filter={ filterFactory() } 
                            noDataIndication={<Box>No deliveries</Box>}
                        />
                    </div>

                    {!checkWeights() &&
                        <Grid container style={{margin: '1rem'}}>
                            <Grid item xs={12} style={{textAlign: 'center'}}>
                                <Box class='warning-text'>Invalid weights. Values must add up to 1 for each product. Please correct this to avoid allocation errors.</Box>
                            </Grid>
                        </Grid>
                    }

                    {showAddDeliveryModal &&
                        <AddDeliveryModal 
                            isOpen={showAddDeliveryModal}
                            close={() => setShowAddDeliveryModal(false)}
                            showSuccessAlert={(text) => props.showSuccessAlert(text)}
                            refreshDeliveries={() => props.refreshDeliveries()}
                            companies={props.companies}
                            deliveries={props.deliveries}
                            site={props.site}
                        />
                    }

                    {showDeliveryModal &&
                        <DeliveryModal 
                            isOpen={showDeliveryModal}
                            close={() => closeDeliveryModal()}
                            delivery={delivery}
                            deliveries={props.deliveries}
                            updateDelivery={(delivery) => props.updateDelivery(delivery)}
                            showSuccessAlert={(text) => props.showSuccessAlert(text)}
                            companies={props.companies}
                            site={props.site}
                        />
                    }
                </>
            ) : (
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                            <Loader />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Card>
    );
}

export default DeliveriesTable