import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Button from '@material-ui/core/Button'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Loader from 'components/general/Loader'
import { defaultProducts } from 'config/constants/Constants'
import { getProductString } from 'config/helpers/Helpers'
import DetailsContext from 'config/context/DetailsContext'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

function LeadReturnForm(props) {
    const api = new BrokerAPI()
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId
    const productsAvailable = companyDetails.productsAvailable || defaultProducts

    const details = props.details

    const [leadOptions, setLeadOptions] = useState([])
    const [product, setProduct] = useState('')
    const [lead, setLead] = useState('')
    const [reason, setReason] = useState('')
    
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(false)

    async function submitEnquiry() {
        const leadReturn = {
            broker: details,
            lead: lead,
            reason: reason
        }
        console.log('lead return: ', leadReturn)

        try {
            let brokerId = lead.brokerId
            let allocated = await api.getAllocatedLeads(companyId, product)
            console.log('allocated leads: ', allocated)
            let allocation = companyDetails.allocations[product] ? parseInt(companyDetails.allocations[product][brokerId]) : 0
            console.log('broker allocation for week: ', allocation)

            await api.submitLeadReturn(companyId, companyDetails.name, leadReturn)
            openSuccessAlert('Your lead return ticket has been submitted and is now under review.')

            setProduct('')
            setLead('')
            setReason('')

            // let brokerReturned = allocated.returned[brokerId] ? parseInt(allocated.returned[brokerId]) : 0
            // console.log('broker returned: ', brokerReturned)

            // let returnedPercentage = allocation ? (brokerReturned/allocation) * 100 : 0
            // console.log('returned percentage: ', returnedPercentage)

            // if ((companyId !== 'al' && companyId !== 'ai' && companyId !== 'bq') && returnedPercentage > 15) {
            //     showReturnsExceededWarning()
            // }
            // else {
            //     //window.alert('Success')
            //     await api.submitLeadReturn(companyId, companyDetails.name, leadReturn)
            //     openSuccessAlert('Your lead return ticket has been submitted and is now under review.')

            //     setProduct('')
            //     setLead('')
            //     setReason('')
            // }
        } catch(e) {
            onError(e)
        }
    }

    async function updateProduct(product) {
        setProduct(product)
        setLead('')
        setReason('')
        setLoading(true)
        try {
            let brokerId = details.permissions === 'admin' ? null : details.brokerId
            let response = await api.getLeadsLimit(product, companyId, brokerId, false)
            console.log('leads for product: ', response)

            let leads = response.items

            // get start of week (sat 00:00)
            let weekFromDate = moment()
            if (product === 'pensions' || companyId === 'cib') {
                weekFromDate.set('weekday', 6).subtract(12, 'weeks') // 12 weeks of leads
            }
            else {

                if (companyId === 'mf') {
                    weekFromDate.set('weekday', 6).subtract(2, 'weeks') // 2 weeks of leads
                }
                else {
                    weekFromDate.set('weekday', 6).subtract(1, 'weeks') // 1 week of leads
                }
            }
            weekFromDate.set('hour', 0).set('minute', 0).set('second', 0)
            console.log('start week date: ', weekFromDate)
            console.log('epoch: ', weekFromDate.valueOf())

            // filter by stage and if previous return requested and if created this week
            if (product === 'pensions') {
                leads = leads.filter((lead) => (lead.stage === 'lost' && !lead.returnRequest && lead.createdAt > weekFromDate.valueOf() && lead.source !== 'Broker Add'))
            }
            else {
                leads = leads.filter((lead) => (lead.stage === 'lost' && !lead.returnRequest && !lead.isReplacement && lead.createdAt > weekFromDate.valueOf() && lead.source !== 'Broker Add'))
            }

            // get date added to lost category
            for (var lead of leads) {
                lead.activity.map((activity) => {
                    if (activity.category === 'stage-updated' && activity.label === 'lost') {
                        lead['lostAt'] = activity.timestamp
                    }
                })
            }
            console.log('leads: ', leads)

            // order by date added to lost category
            leads.sort((a, b) => {
                return b.lostAt - a.lostAt
            })

            // in stage lost and no previous return request - maybe add timeframe here too? last 2 weeks etc.
            setLeadOptions(leads)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function checkFormFields() {
        if (product && lead && reason.length > 25) {
            return false
        }
        else {
            return true
        }
    }

    function openSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    function showReturnsExceededWarning() {
        setAlert(
            <ReactBSAlert
                warning
                style={{ display: 'block', marginTop: '-100px' }}
                title='Return Quota Exceeded'
                onConfirm={() => setAlert(false)}
                //onCancel={() => setAlert(false)}
                //cancelBtnCssClass='btn-secondary'
                confirmBtnBsStyle='warning'
                //cancelBtnText='Cancel'
                confirmBtnText='Close'
                //showCancel
                btnSize=''
            >
                You have already returned more than 15% of your allocated leads for this week.
            </ReactBSAlert>
        )
    }


    return (
        <>
            {alert}
            <FormGroup>
                <FormLabel>Product</FormLabel>
                <FormControl variant='outlined' fullWidth>
                    <Select
                        multiple={false}
                        IconComponent={KeyboardArrowDown}
                        value={product}
                        onChange={(e) => updateProduct(e.target.value)}
                    >
                        {Object.keys(productsAvailable).map((product) => {
                            if (productsAvailable[product]) {
                                return (<MenuItem value={product}>{getProductString(product)}</MenuItem>)
                            }
                        })}
                    </Select>
                </FormControl>
            </FormGroup>

            {product &&
                <>
                    {!loading ? (
                        <FormGroup>
                            {product === 'pensions' ? (
                                <FormLabel>Lead (only leads in the LOST stage that were received in the last 12 weeks are returnable)</FormLabel>
                            ) : (
                                <FormLabel>Lead (only leads in the LOST stage that were received this week are returnable)</FormLabel>
                            )}
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={false}
                                    IconComponent={KeyboardArrowDown}
                                    value={lead}
                                    onChange={(e) => setLead(e.target.value)}
                                >
                                    {leadOptions.map((option, i) => {
                                        return (<MenuItem value={option} key={i}>{option.phone} ({option.firstName + ' ' + option.lastName})</MenuItem>)
                                    })}
                                </Select>
                            </FormControl>
                        </FormGroup>
                    ) : (
                        <Grid container justifyContent='flex-start'>
                            <Grid item xs={12}>
                                <Box display='flex' justifyContent='center' alignItems='center' style={{height: '5vh'}}>
                                    <Loader />
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </>
            }
            {product && lead &&
                <FormGroup>
                    <FormLabel>Reason (minimum 25 characters - this must be descriptive and outline valid return reason in order for the return to be accepted.)</FormLabel>
                    <OutlinedInput
                        fullWidth
                        multiline
                        rows='5'
                        type='text'
                        placeholder='I am requesting a return due to...'
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                    />
                </FormGroup>
            }
            <Button
                component={Box}
                color='primary'
                marginLeft='auto!important'
                variant='contained'
                onClick={() => submitEnquiry()}
                disabled={checkFormFields()}
            >
                Submit
            </Button>
        </>
    )
}

export default LeadReturnForm