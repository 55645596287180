import * as Sentry from '@sentry/browser'
import config from '../api/Config'
import { NotificationManager } from 'react-notifications'

const isLocal = process.env.NODE_ENV === 'development'
//const isLocal = false

export function initSentry() {
    if (isLocal) {
        return
    }
    else {
        Sentry.init({ dsn: config.SENTRY_DSN })
    }
}

export function logError(error, errorInfo = null) {
    if (isLocal) {
        return
    }
    else {
        Sentry.withScope((scope) => {
            errorInfo && scope.setExtras(errorInfo)
            Sentry.captureException(error)
        })
    }
}

export function onError(error) {
    let errorInfo = {}
    let message = error.toString()

    // Auth errors
    if (!(error instanceof Error) && error.message) {
        errorInfo = error
        message = error.message
        error = new Error(message)
    } 
    // API errors
    else if (error.config && error.config.url) {
        errorInfo.url = error.config.url;
    }

    logError(error, errorInfo)

    console.log('ERROR: ', message)
    console.log('error name: ', error.name)
    if (message === 'QuotaExceededError') {
        window.localStorage.clear()
    }
    NotificationManager.error('Something went wrong. Please try again in few moments.')
}

