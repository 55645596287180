import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Button from '@material-ui/core/Button'
import Loader from 'components/general/Loader'
import BrokerAPI from 'config/api/BrokerAPI'
import { onError } from 'config/lib/errorLib'
import { Auth } from 'aws-amplify'
import { NotificationManager } from 'react-notifications'

import DetailsContext from 'config/context/DetailsContext'

import componentStyles from 'assets/theme/components/cards/cards/card-button.js'

const useStyles = makeStyles(componentStyles)

function ChangePassword(props) {
    const api = new BrokerAPI()
    const companyDetails = useContext(DetailsContext)
    const companyId = companyDetails.companyId

    const classes = { ...useStyles() }

    const [invite, setInvite] = useState(null)
    const [newPassword, setNewPassword] = useState('')
  	const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getInvite()
    }, [])

    async function getInvite() {
        console.log('get invite')
        try {
            const info = await Auth.currentUserInfo()
            let email = info.attributes['email']
            const invite = await api.getInvite(email)
            console.log('invite: ', invite)

            setInvite(invite)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function changePassword() {
        try {
            let user = await Auth.currentAuthenticatedUser()
            console.log(user) 

            await Auth.changePassword(user, invite.pw, newPassword)
            //await Auth.changePassword(user, 'qWsz7805SffDmb', newPassword)

            const info = await Auth.currentUserInfo()
            let firstName = info.attributes['name'].split(' ')[0]
            let lastName = info.attributes['name'].split(' ')[1]
            let email = info.attributes['email']
            let phone = info.attributes['phone_number']
            let permissions = info.attributes['custom:permissions']
            let details = await api.addDetails(companyId, firstName, lastName, email, phone, permissions, invite.counties, invite.products)
            console.log('broker details: ', details)

            window.location.replace('/')
        } catch(e) {
            onError(e)
            //NotificationManager.error('Error changing password')
        }
    }

    function checkNewPassword() {
        if (newPassword.length > 0 && (newPassword === confirmNewPassword)) {
            return false
        }
        else {
            return true
        }
    }

    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <Grid container>
                        <Grid item xs={6} xl={6}>
                            <Card
                                classes={{
                                    root: classes.cardRoot,
                                }}
                            >
                                <CardHeader
                                    subheader={
                                        <Grid
                                            container
                                            component={Box}
                                            alignItems='center'
                                            justifyContent='space-between'
                                        >
                                            <Grid item xs='auto'>
                                                <Box
                                                    component={Typography}
                                                    variant='h3'
                                                    marginBottom='0!important'
                                                >
                                                    Change Password
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    }
                                    classes={{ root: classes.cardHeaderRoot }}
                                >
                                </CardHeader>
                                
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={12}>  
                                            <FormGroup>
                                                <FormLabel>New Password</FormLabel>
                                                <OutlinedInput
                                                    fullWidth
                                                    type='password'
                                                    placeholder='csgw@2a44'
                                                    value={newPassword}
                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>Confirm New Password</FormLabel>
                                                <OutlinedInput
                                                    fullWidth
                                                    type='password'
                                                    placeholder='csgw@2a44'
                                                    value={confirmNewPassword}
                                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                                />
                                            </FormGroup>

                                            <Box
                                                component='p'
                                                marginBottom='1rem'
                                                fontWeight='300'
                                                lineHeight='1.7'
                                                fontSize='1rem'
                                            >
                                                Password must contain at least 6 characters
                                            </Box>
                                            <Button
                                                //component={Box}
                                                onClick={() => changePassword()}
                                                color='primary'
                                                //marginLeft='auto!important'
                                                variant='contained'
                                                disabled={checkNewPassword()}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid> 
                ) : (
                    <Grid container justifyContent='flex-start'>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                <Loader />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
}

export default ChangePassword