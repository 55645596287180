import React from 'react'
import { ScaleLoader } from 'react-spinners'

function Loader(props) {
	return (
		<div className={props.className ? 'loader ' + props.className : 'loader'}>
            <ScaleLoader
              	color={'#5fb2ee'} 
              	loading={true} 
              	height={props.height ? props.height : 30}
            />
        </div>
    )
}

export default Loader