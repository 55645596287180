import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import BootstrapTable from 'react-bootstrap-table-next'
import Grid from '@material-ui/core/Grid'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import filterFactory, { textFilter, selectFilter, numberFilter } from 'react-bootstrap-table2-filter'
import AddCampaignModal from 'components/admin/AddCampaignModal'
import CampaignModal from 'components/admin/CampaignModal'
import componentStyles from 'assets/theme/views/admin/react-bs-table.js'

const useStyles = makeStyles(componentStyles)

function CampaignsTable(props) {
    const classes = { ...useStyles() }
    const api = new AdminAPI()

    const campaigns = props.campaigns
    const [campaign, setCampaign] = useState(null)
    const [showCampaignModal, setShowCampaignModal] = useState(false)
    const [showAddCampaignModal, setShowAddCampaignModal] = useState(false)
    const [loading, setLoading] = useState(false)

    let targetSum = 0
    let totalSum = 0
    let leadSum = 0
    for (let campaign of campaigns) {
        targetSum += parseFloat(campaign.target)
        totalSum += parseFloat(campaign.currentTotal.total)
        leadSum += parseInt(campaign.currentTotal.leads)
    }
    console.log('target sum: ', targetSum)
    console.log('total sum: ', totalSum)
    console.log('lead sum: ', leadSum)

    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            //sort: true,
            //filter: textFilter(),
            formatter: (cell, row) => {
                return (<Box className='hover-text primary-text' onClick={() => openCampaignModal(row)}>{row.name}</Box>)
            },
            footer: 'Total'
        },
        {
            dataField: 'target',
            text: 'Target',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>€{row.target ? parseFloat(row.target).toFixed(2) : 0}</>
            },
            footer: '€' + targetSum.toFixed(2)
        },
        {
            dataField: 'current',
            text: 'Current',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>€{row.currentTotal.total ? row.currentTotal.total.toFixed(2) : 0}</>
            },
            footer: '€' + totalSum.toFixed(2)
        },
        {
            dataField: 'leads',
            text: 'Leads',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{row.currentTotal.leads ? row.currentTotal.leads : 0}</>
            },
            footer: leadSum.toFixed()
        },
        {
            dataField: 'difference',
            text: '% of Target',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{parseFloat(row.target) > 0 ? ((row.currentTotal.total/parseFloat(row.target)) * 100).toFixed(2) + '%' : 'n/a'}</>
            },
            footer: ((totalSum/targetSum) * 100).toFixed(2) + '%'
        }
    ]

    function openCampaignModal(campaign) {
        setCampaign(campaign)
        setShowCampaignModal(true)
    }

    function closeCampaignModal() {
        setShowCampaignModal(false)
        setCampaign(null)
    }

    return (
        <Card>
            {!loading ? (
                <>
                    <CardHeader
                        subheader={
                            <>
                                <Box display='flex' style={{justifyContent: 'space-between'}}>
                                    <Box>
                                        <Box
                                            component={Typography}
                                            variant='h2'
                                            marginBottom='0!important'
                                        >
                                            <Box component='span'>Campaigns</Box>
                                        </Box>
                                        <Box
                                            component='p'
                                            fontSize='.875rem'
                                            marginBottom='0'
                                            marginTop='0'
                                            lineHeight='1.7'
                                            fontWeight='300'
                                        >
                                            All deliveries that require ad spend (excludes purely SEO deliveries)
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Button
                                            component={Box}
                                            onClick={() => setShowAddCampaignModal(true)}
                                            color='primary'
                                            variant='contained'
                                            size='small'
                                            //disabled
                                        >
                                            Add Campaign
                                        </Button>
                                    </Box>
                                </Box>
                            </>
                        }
                        classes={{ root: classes.cardHeaderRoot }}
                    ></CardHeader>

                    <div className='table-container'>
                        <BootstrapTable 
                            keyField='name' 
                            data={ campaigns } 
                            columns={ columns } 
                            filter={ filterFactory() } 
                            noDataIndication={<Box>No campaigns</Box>}
                        />
                    </div>

                    {showAddCampaignModal &&
                        <AddCampaignModal 
                            isOpen={showAddCampaignModal}
                            close={() => setShowAddCampaignModal(false)}
                            showSuccessAlert={(text) => props.showSuccessAlert(text)}
                            refreshCampaigns={() => props.refreshCampaigns()}
                        />
                    }

                    {showCampaignModal &&
                        <CampaignModal 
                            isOpen={showCampaignModal}
                            close={() => closeCampaignModal()}
                            campaign={campaign}
                            updateCampaign={(campaign) => props.updateCampaign(campaign)}
                            //showSuccessAlert={(text) => props.showSuccessAlert(text)}
                        />
                    }
                </>
            ) : (
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                            <Loader />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Card>
    );
}

export default CampaignsTable