import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import BootstrapTable from 'react-bootstrap-table-next'
import Grid from '@material-ui/core/Grid'
import Loader from 'components/general/Loader'
import AdminAPI from 'config/api/AdminAPI'
import { onError } from 'config/lib/errorLib'
import filterFactory, { textFilter, selectFilter, numberFilter } from 'react-bootstrap-table2-filter'
import AddBalanceDeliveryModal from 'components/admin/AddBalanceDeliveryModal'
import BalanceDeliveryModal from 'components/admin/BalanceDeliveryModal'
import TopupModal from 'components/admin/TopupModal'
import { getProductString } from 'config/helpers/Helpers'
import componentStyles from 'assets/theme/views/admin/react-bs-table.js'
import moment from 'moment-timezone'
moment().tz('Europe/Dublin').format()

const useStyles = makeStyles(componentStyles)

function BalanceDeliveriesTable(props) {
    const classes = { ...useStyles() }
    const api = new AdminAPI()

    const deliveries = props.deliveries
    const [delivery, setDelivery] = useState(null)
    const [showDeliveryModal, setShowDeliveryModal] = useState(false)
    const [showAddDeliveryModal, setShowAddDeliveryModal] = useState(false)
    const [showTopupModal, setShowTopupModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            dataField: 'company',
            text: 'Company',
            //sort: true,
            //filter: textFilter(),
            formatter: (cell, row) => {
                return (<Box className={row.active === false ? 'inactive-delivery hover-text primary-text' : 'active-delivery hover-text primary-text'} onClick={() => openDeliveryModal(row)}>{row.companyName} ({row.companyId.toUpperCase()})</Box>)
            },
            //footer: 'Total'
        },
        {
            dataField: 'product',
            text: 'Product',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>{getProductString(row.product)}</Box>
            }
        },
        {
            dataField: 'lastTopup',
            text: 'Last Topup',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                if (row.topupDate) {
                    return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>{moment(row.topupDate).format('DD/MM/YY')}</Box>
                }
                else {
                    return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>-</Box>
                }
            },
            //footer: ''
        },
        {
            dataField: 'balance',
            text: 'Balance',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <Box className={row.active === false ? 'inactive-delivery' : 'active-delivery'}>€{row.balance.toFixed(2)}</Box>
            },
            //footer: ''
        },
        {
            dataField: 'companyId',
            text: 'Action',
            sort: false,
            formatter: (cell, row) => {
                return (
                    <>
                        <Button 
                            color='secondary'
                            variant='contained'
                            size='small'
                            onClick={() => openTopupModal(row)}
                            style={{margin: '0.5rem'}}
                        >
                            Send Topup Link
                        </Button>
                    </>
                )
            },
        }
    ]

    function openDeliveryModal(delivery) {
        setDelivery(delivery)
        setShowDeliveryModal(true)
    }

    function closeDeliveryModal() {
        setShowDeliveryModal(false)
        setDelivery(null)
    }

    function openTopupModal(delivery) {
        setDelivery(delivery)
        setShowTopupModal(true)
    }

    function closeTopupModal() {
        setShowTopupModal(false)
        setDelivery(null)
    }

    return (
        <Card>
            {!loading ? (
                <>
                    <CardHeader
                        subheader={
                            <>
                                <Box display='flex' style={{justifyContent: 'space-between'}}>
                                    <Box>
                                        <Box
                                            component={Typography}
                                            variant='h2'
                                            marginBottom='0!important'
                                        >
                                            <Box component='span'>{props.title}</Box>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Button
                                            component={Box}
                                            onClick={() => setShowAddDeliveryModal(true)}
                                            color='primary'
                                            variant='contained'
                                            size='small'
                                        >
                                            Add Delivery
                                        </Button>
                                    </Box>
                                </Box>
                            </>
                        }
                        classes={{ root: classes.cardHeaderRoot }}
                    ></CardHeader>

                    <div className='table-container'>
                        <BootstrapTable 
                            keyField='company' 
                            data={ deliveries } 
                            columns={ columns } 
                            filter={ filterFactory() } 
                            noDataIndication={<Box>No balance deliveries</Box>}
                        />
                    </div>

                    {showAddDeliveryModal &&
                        <AddBalanceDeliveryModal 
                            isOpen={showAddDeliveryModal}
                            close={() => setShowAddDeliveryModal(false)}
                            showSuccessAlert={(text) => props.showSuccessAlert(text)}
                            refreshDeliveries={() => props.refreshDeliveries()}
                            companies={props.companies}
                        />
                    }

                    {showDeliveryModal &&
                        <BalanceDeliveryModal 
                            isOpen={showDeliveryModal}
                            close={() => closeDeliveryModal()}
                            delivery={delivery}
                            updateDelivery={(delivery) => props.updateDelivery(delivery)}
                            showSuccessAlert={(text) => props.showSuccessAlert(text)}
                            companies={props.companies}
                        />
                    }

                    {showTopupModal &&
                        <TopupModal 
                            isOpen={showTopupModal}
                            close={() => closeTopupModal()}
                            delivery={delivery}
                            updateDelivery={(delivery) => props.updateDelivery(delivery)}
                            showSuccessAlert={(text) => props.showSuccessAlert(text)}
                            companies={props.companies}
                        />
                    }
                </>
            ) : (
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                            <Loader />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Card>
    );
}

export default BalanceDeliveriesTable